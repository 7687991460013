.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    margin-top: 70px;
    
    &-content{
        max-width: 600px;
        width: 100%;

        .error-code{
            font-size: 120px;
            font-weight: 500;
            color: $color-light-red;
        }
        .error-message {
            font-size: 32px;
            color: $color-gray;
            margin-bottom: 20px;
        }
        
        .error-description {
            font-size: 18px;
            color: $color-darkLiver;
            margin-bottom: 30px;
        }
        
        &-button {
            display: inline-block;
            padding: 12px 24px;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
            color: $color-white;
            background-color: $color-primary-dark;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            
            &:hover {
                background-color: $color-darkLiver;
            }
        }
    }
  }