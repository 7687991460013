.popupModal__container.contact-us-form .popupModal__root {
  max-width: 1043px;
  max-height: 483px;
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  .popupModal__heading {
    margin: 8px 0 20px;

    .icon-close {
      color: $color-light-red;
      top: 24px;
      right: 24px;
    }
  }
  .popupModal__body {
    margin: auto;
    width: 100%;
    .contactus__container {
      display: flex;
      align-items: center;
      &__banner {
        min-width: 360px;
        min-height: 300px;
      }
      &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        &_head {
          font-size: 28px;
          font-weight: 600;
          line-height: 33.6px;
          color: $color-primary-dark;
        }
        &_body {
          display: flex;
          flex-direction: column;
          gap: 18px;

          .form_field {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width: 100%;
            &_flex {
              display: flex;
              align-items: center;
              gap: 12px;
              & p {
                margin: 0;
              }
            }
            label {
              font-size: 14px;
              font-weight: 400;
              line-height: 16.8px;
              color: $color-darkLiver;
              sup {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-light-red;
                vertical-align: -2px;
              }
            }
            &_userInfo {
              width: 44px;
              text-align: center;
              border-radius: 22px;
              border: 1px solid $color-primary;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $color-primary;
              &-pic {
                max-width: 44px;
                border-radius: 22px;
              }
            }
            &_userName {
              font-size: 17px;
              font-weight: 400;
              line-height: 20.4px;
              color: $color-gray;
            }
            &_description {
              border-radius: 2px;
              border: 1px solid $color-light-light-gray;
              width: 100%;
              min-height: 104px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              resize: none;
              color: $color-oldSilver;
              padding: 10px;
            }
            & .error-msg {
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              color: $color-light-red;
            }

            .custom-dropdownRenderer1 {
              padding: 0 10px;
              border: 1px solid var(--color-light-light-gray);
              border-radius: 4px;
              height: 42px;
              .react-dropdown-select {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-light-ghost-white;
                height: 42px;
                margin: 0 0 0px 0;
                padding: 13px 15px 13px 12px;
              }
              .react-dropdown-select-content {
                color: $color-oldSilver;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                span {
                  max-width: 255px;
                  white-space: nowrap;
                  display: inline-block;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              .react-dropdown-select-option {
                position: relative;
                display: inline;
                &::before {
                  position: absolute;
                  content: ",";
                  right: 0px;
                  bottom: 0;
                }
                &:nth-last-child(2)::before {
                  content: "";
                }
                .react-dropdown-select-option-remove {
                  display: none;
                }
              }
              .react-dropdown-select-dropdown {
                overflow: visible;
                border-radius: 6px;
                width: 100%;
                .dropdwonList-main {
                  padding: 12px;
                  .dropdwonList-head {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $color-gray;
                    margin: 0 0 12px 0;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $color-light-gray;
                  }
                  .dropdwonLists {
                    overflow: auto;
                    max-height: 200px;
                    padding: 0px 12px 12px 12px;
                    margin: 0 22px 0 10px;
                    &.single {
                      margin: 0;
                      padding: 0;
                    }
                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                    .dropdwonList-subhead,
                    .dropdwonLists-label {
                      margin: 0 0 16px 0;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-gray;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      column-gap: 8px;
                      &.disabled,
                      &:disabled {
                        pointer-events: none;
                        cursor: not-allowed;
                        opacity: 0.5;
                      }
                      &:last-child {
                        margin: 0 0 0 0;
                      }
                      &.sub-list {
                        margin-left: 8px;
                      }
                      label {
                        cursor: pointer;
                      }
                      .checkbox-img {
                        width: 16px;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
          .flex-disp {
            display: flex;
            flex-direction: row;
            margin: 0px 0 0;
            align-items: center;
            column-gap: 20px;
            justify-content: flex-end;
            .submit-btn {
              position: relative;
              border-radius: 24px;
              background: $color-primary-dark;
              padding: 6px 16px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-white;
              border: 1px solid transparent;
              cursor: pointer;

              &:hover {
                color: $color-white;
                background: #01101bd4;
                border: 1px solid $color-primary-dark;
              }
              &.loading {
                background: $color-primary-dark;
                padding-right: 40px;
                color: $color-white;
                min-width: 105px;
                max-width: 118px;
              }
              &.loading::before {
                content: "";
                position: absolute;
                border-radius: 100%;
                right: 6px;
                top: 50%;
                width: 0px;
                height: 0px;
                margin-top: -2px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left-color: #fff;
                border-top-color: #fff;
                animation: spin 0.6s infinite linear,
                  grow 0.3s forwards ease-out;
              }
              @keyframes spin {
                to {
                  transform: rotate(359deg);
                }
              }
              @keyframes grow {
                to {
                  width: 14px;
                  height: 14px;
                  margin-top: -8px;
                  right: 13px;
                }
              }
            }
            .cancel-btn {
              border-radius: 24px;
              border: 1px solid $color-oldSilver;
              padding: 6px 16px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              background: none;
              color: $color-oldSilver;
              cursor: pointer;
              &:hover {
                background: #dadcdd;
              }
            }
          }
        }
        &_success {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 32px;
          .icon-success-tick-large{
            font-size: 100px;
          }
          &_content {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            color: $color-primary-dark;
            & h1 {
              font-size: 28px;
              font-weight: 700;
              line-height: 33.6px;
              letter-spacing: 0.01em;
            }
            & h1 {
              font-size: 28px;
              font-weight: 700;
              line-height: 33.6px;
              letter-spacing: 0.01em;
              color: $color-primary-dark;
            }
            & p {
              font-size: 19px;
              font-weight: 400;
              line-height: 22.8px;
            }
          }
        }
      }
    }
    .custom-dropdownRenderer {
      &.member-list {
        border: 1px solid var(--color-light-light-gray);
        position: relative;
        border-radius: 6px;
        border: 1px solid $color-light-light-gray;
        background: $color-white;
        height: 42px;
        margin: 0 0 0px 0;
        padding: 13px 15px 13px 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .react-dropdown-select-content {
          color: $color-oldSilver;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
        .react-dropdown-select-dropdown-handle {
          width: 12px;
        }
        .react-dropdown-select-dropdown {
          overflow: visible;
          border-radius: 6px;
          width: 100%;
        }
        .react-dropdown-select-option {
          position: relative;
          display: inline;
          &::before {
            position: absolute;
            content: ",";
            right: 0px;
            bottom: 0;
          }
          &:nth-last-child(2)::before {
            content: "";
          }
          .react-dropdown-select-option-remove {
            display: none;
          }
        }
        .dropdwonList-main {
          padding: 12px 20px 20px 12px;
          .popup-header {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: var(--color-gray);
            margin: 0 0 12px 0;
            padding-bottom: 12px;
            border-bottom: 1px solid var(--color-light-gray);
          }
          .dropdwonLists {
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            max-height: 200px;
            padding: 0px;
            margin: 0px;
            .error-show {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              padding: 0;
              color: $color-light-red;
              text-align: center;
            }
            &::-webkit-scrollbar {
              width: 6px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 8px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 4px $color-light-light-gray;
              border-radius: 8px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 8px;
              -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
              background-color: $color-oldSilver;
            }
            .dropdwonLists-label {
              display: flex !important;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;
              margin: 0 0 10px;
              &.disabled,
              &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
              .lable-field {
                margin: 0px !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: left;
                color: $color-gray;
                text-decoration-skip-ink: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 2px;
                overflow: hidden;
                label {
                  overflow: hidden;
                  max-width: 190px;
                  text-overflow: ellipsis;
                }
                .mail-field {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16.8px;
                  text-align: left;
                  text-decoration-skip-ink: none;
                  color: $color-oldSilver;
                }
              }
              .list {
                &__checkbox {
                  padding: 0px;
                  img {
                    width: 16px;
                  }
                  span {
                    font-size: 16px;
                  }
                }
                &__image {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  column-gap: 0;
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  fill: $color-white;
                  border: 1px solid $color-primary;
                  overflow: hidden;
                  img {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                  }
                  .no-image {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 42px;
                    height: 42px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: center;
                    border-radius: 50%;
                    color: $color-white;
                  }
                  &__status {
                    position: absolute;
                    top: 22px;
                    left: 22px;
                  }
                }
                &__details {
                  display: inline-flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: baseline;
                  &__name {
                    margin: 0;
                    color: $color-gray;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                  &__role {
                    margin: 0;
                    color: #828282;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: normal;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
