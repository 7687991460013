.aboutus-contnet {
  padding: 20px 20px 80px;
  .aboutus-header {
    text-align: center;
    width: fit-content;
    border-bottom: 1px solid;
    margin: 20px auto 30px;
  }
  .aboutus-team {
    text-align: center;
    width: fit-content;
    margin: 50px auto 30px;
  }
  .heading-block-wrapper {
    .text-block-content-heading {
      font-weight: 900;
      text-transform: uppercase;
    }
    .aboutus-description .orion-logo {
      color: $color-primary-dark;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }
  }
  .banner-column-content {
    margin: 10px 0 25px;
    .banner-column-row {
      display: flex;
      flex-direction: row;
      column-gap: 80px;
      justify-content: flex-start;
      background-color: $color-light-gray;
      padding: 15px 50px 20px;
      .banner-column-col {
        width: 50%;
      }
      .experience-component {
        button {
          cursor: default;
          color: $color-primary-dark;
          border: 1px solid $color-primary-dark;
        }
      }
      .member-profile-list {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 25px;
        .member-profile-info {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          column-gap: 8px;
          margin: 0 0 10px;
          width: 200px;
          .member-profile-image {
            width: 100px;
            height: 100px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            fill: var(--color-white);
            border: 1px solid $color-primary;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .userNull-image {
              font-size: 25px;
              color: $color-white;
              font-weight: 600;
            }
          }
          .member-profile-details {
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;
            flex-wrap: nowrap;
            column-gap: 8px;
            .member-profile-name {
              margin: 8px 0 2px;
              font-size: 12px;
              font-weight: 500;
              display: inline-flex;
              flex-direction: row;
              column-gap: 6px;
              align-items: baseline;
              b {
                font-size: 13px;
                margin: 0;
                line-height: 100%;
                padding: 0;
                color: $color-primary-dark;
              }
            }
            .member-profile-position {
              margin: 0;
              font-size: 11px;
              display: inline-flex;
              flex-direction: row;
              column-gap: 6px;
              align-items: flex-start;
              b {
                font-size: 13px;
                margin: 0;
                line-height: 100%;
                padding: 0;
                color: $color-primary-dark;
              }
            }
          }
        }
      }
      .team-name {
        font-size: 23px;
        font-weight: 500;
        color: $color-primary-dark;
        line-height: 100%;
        padding: 0;
        margin: 0 0 8px;
      }
    }
  }
}
