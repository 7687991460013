.popupModal__container.module-iteration-detailedview .popupModal__root {
  min-width: 735px;
  max-width: 1200px;
  padding: 20px;
  border-radius: 5px 5px 5px 5px;
  .popupModal__heading {
    margin-bottom: 15px;
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      text-decoration-skip-ink: none;
      color: $color-primary-dark;
      margin: 0;
      width: 100%;
    }
    .icon-close {
      color: $color-light-red;
      top: 22px;
      right: 25px;
    }
  }
  .popupModal__body {
    .module-iteration-detailedview-container {
      display: flex;
      gap: 15px;
      flex-direction: column;
      &.iteration_module {
        min-height: 400px;
      }
      .requirement-container {
        font-weight: 400;
        font-size: 14px;
        color: $color-darkLiver;
        .title {
          margin: 8px 0px;
        }
        .requirement_render_box {
          width: 100%;
          min-height: 100px;
          border: 1px solid $color-light-light-gray-1;
          border-radius: 5px;
          padding: 8px 10px;
          max-height: 120px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 6px;
            height: 4px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 4px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
            background-color: $color-oldSilver;
          }
        }
      }
      .links_row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .link_column {
          display: inline-flex;
          gap: 12px;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: $color-primary-dark;
          .icon-redirect,
          .icon-copy {
            background: unset;
            border: unset;
            padding: 0;
          }
        }
      }

      .attachment-container {
        font-size: 14px;
        font-weight: 300;
        color: $color-darkLiver;
        .box-container {
          padding: 12px;
          display: flex;
          border: 1px solid $color-light-light-gray-1;
        }
        .files-container {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          box-sizing: border-box;
          flex-wrap: wrap;
          border: 1px solid $color-light-light-gray-1;
          border-radius: 5px;
          padding: 10px 10px;
          gap: 16px;
          .file-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
          }
          .icon-download_file {
            border: none;
            background: transparent;
            padding: 0;
          }
        }
      }
      .comment-container {
        .ticket_info_container_activity_contents {
          max-height: 300px;
          overflow: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 6px;
            height: 4px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            background-color: $color-oldSilver;
          }
        }
      }
    }

  }
}
