.products-details {
  padding: 0;
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 25px 15px;
    .edit-btn {
      display: block;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      color: $color-gray;
      background-color: $color-white;
      border: none;
      width: 79px;
      padding: 0;
    }
  }
  &-info {
    padding: 0px 25px 15px;
    .products-name {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-gray;
    }
    .products-info-table {
      &__head {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        padding: 6px 6px 6px 0px;
        &.subscripe {
          font-weight: 500;
        }
      }
      &__middle {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-gray;
        padding: 6px;
      }
      &__data {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-gray;
        padding: 6px;
        .premium {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.39px;
          color: $color-white;
          background-color: $color-light-green;
          padding: 3px 8px 4px;
          border-radius: 16px;
        }
        .basic {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.39px;
          color: $color-white;
          background-color: $color-purple-dark;
          padding: 3px 8px 4px;
          border-radius: 16px;
        }
        .medium {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.39px;
          color: $color-white;
          background-color: $color-orange-light;
          padding: 3px 8px 4px;
          border-radius: 16px;
        }
        .toolsonly {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.39px;
          color: $color-gray;
          background-color: $color-light-gray;
          padding: 3px 8px 4px;
          border-radius: 16px;
        }
      }
    }
    .product-description-head {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: $color-gray;
      margin: 8px 0 4px;
    }
    .product-description-text {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: $color-darkLiver;
      margin: 0 0 8px;
    }
    .key-highlights-box {
      .key-highlights-head {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: $color-gray;
        margin: 8px 0 4px;
      }
      .key-highlights-text {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: $color-darkLiver;
        margin: 0 0 8px;
      }
      .key-highlights-list {
        column-count: 2;
        column-gap: 40px;
        max-width: 600px;
        li {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: $color-darkLiver;
        }
      }
    }
  }
  &-body {
    padding: 25px 25px 15px;
    border-top: 1px solid $color-light-light-gray;
    height: 45vh;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
    .products-teamMembers-head {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-darkLiver;
    }
    .flex-body {
      display: flex;
      flex-direction: row;
      column-gap: 80px;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 25px 0 0;
      flex-wrap: wrap;
      .products-teamMembers-list {
        min-width: 355px;
        .depName {
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $color-darkLiver;
          border-bottom: 1px solid $color-silver;
          min-width: 163px;
          width: fit-content;
          padding: 0 0 8px;
        }
        .products-teamMembers-name-list {
          display: flex;
          flex-direction: row;
          margin: 0 0 15px;
          padding: 0;
          column-gap: 8px;
          li {
            list-style-type: none;
            p {
              margin: 0;
            }
            .avatars {
              width: 36px;
              height: 36px;
              border-radius: 24px;
              img {
                width: 100%;
                height: auto;
                border-radius: 24px;
              }
              .avatars-photo {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                font-size: 15px;
                font-weight: 500;
                line-height: 100%;
                color: $color-white;
                border-radius: 50%;
                letter-spacing: 1px;
              }
            }
            .teamMembers-info {
              display: inline-flex;
              flex-direction: column;
              row-gap: 2px;
              .teamMembers-displayName {
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
              }
              .teamMembers-roleName {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
              }
            }
          }
        }
      }
    }
  }
}
