.customDropdown_select {
  position: relative;
  background: $color-white;
  border: 1px solid $color-light-light-gray;
  border-radius: 6px;
  height: 42px;
  margin: 0;
  padding: 9px 15px 9px 15px;
  width: 320px;
  display: flex;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .dropdown-select-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex: 1 1 0%;
    column-gap: 6px;
    max-width: 315px;
    overflow: hidden;
    text-overflow: ellipsis;
    .listitem {
      background: transparent;
      color: $color-darkLiver;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      white-space: nowrap;
      display: inline;
      position: relative;
    }
    input {
      border: none;
      font-size: 12px;
      height: inherit;
      padding: inherit;
      width: 100%;
      &.search-label-input {
        border: none;
        font-size: 12px;
        height: inherit;
        padding: inherit;
        width: 100%;
      }
      &:focus-visible {
        outline-offset: 0px;
        outline: 0;
      }
      &.css-1q95dnp {
        width: calc(0ch + 5px);
      }
      &.hidden {
        visibility: hidden;
      }
    }
  }
  .icon-dropdown-handle {
    max-width: 50px;
    height: 100%;
    padding: 0;
    margin: 0;
    img {
      width: 16px;
      height: 100%;
      transform: rotate(180deg);
      vertical-align: baseline;
      &.active {
        transform: none;
      }
    }
    
  }
  .customDropDown {
    position: absolute;
    top: 48.9965px;
    left: 0px;
    border: 1px solid rgb(204, 204, 204);
    width: 320px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
    z-index: 100;
    .customDropDown_heading {
      border-bottom: 1px solid $color-light-gray;
      color: $color-gray;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 8px;
      padding-bottom: 12px;
    }
    .customDropDown_dropdwon_list {
      list-style-type: none;
      margin: 0;
      padding: 0px;
      max-height: 300px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $color-light-light-gray;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
        border-radius: 8px;
        background-color: $color-light-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
        background-color: $color-oldSilver;
      }
      li {
        list-style-type: none;
        margin: 0;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        line-height: 15px;
        font-weight: normal;
        gap: 10px;
        .list__checkbox {
          width: 16px;
          height: 16px;
          display: inline-flex;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .dropdown-details {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          .dropdown-list {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .customDropDown_no-record{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      list-style: none;
      padding: 0;
      margin: 0;
      color: $color-light-red;
    }
  }
}
