.react-dropdown-select {
    // border-radius: 6px;
    // border: 1px solid $color-light-light-gray;
    // background: $color-light-ghost-white;
    // height: 42px;
    // margin: 0 0 20px 0;
    // padding: 13px 15px 13px 12px;

    // .react-dropdown-select-content {
    //     color: $color-darkLiver;
    //     font-size: 13px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: normal;
    //     display: flex;
    //     flex-direction: row;
    //     max-width: 315px;
    //     overflow: hidden;
    //     flex-wrap: nowrap;
    //     text-overflow: ellipsis;
    //     justify-content: flex-start;

    //     .react-dropdown-select-option {
    //         background: transparent;
    //         color: $color-darkLiver;
    //         font-size: 13px;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: normal;
    //         white-space: nowrap;

    //         &:first-child {
    //             padding-left: 0px;
    //         }
    //     }
    // }

    &.search-filter {
        width: 200px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--color-light-light-gray);
        background: var(--color-white);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--color-gray);
        margin: 0;
        .react-dropdown-select-content {
            color: var(--color-darkLiver);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            flex-direction: row;
            max-width: 315px;
            overflow: hidden;
            flex-wrap: nowrap;
            text-overflow: ellipsis;
            justify-content: flex-start;
        }
        .react-dropdown-select-option {
            position: relative;
            display: inline;
            background: transparent;
            color: var(--color-darkLiver);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
            margin: 0;

            &::before {
                position: absolute;
                content: ",";
                right: 0px;
                bottom: 0;
            }

            &:nth-last-child(2)::before {
                content: "";
            }

            .react-dropdown-select-option-remove {
                display: none;
            }
        }

        .react-dropdown-select-dropdown {
            overflow: visible;
            border-radius: 6px;
            min-width: 150px;
            width: 100%;

            .dropdwonList-main {
                padding: 12px;

                .dropdwonList-head {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $color-gray;
                    margin: 0 0 12px 0;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $color-light-gray;
                }

                .dropdwonLists {
                    // overflow: auto;
                    max-height: 200px;
                    // padding: 0px 12px 12px 12px;
                    // margin: 0 22px 0 10px;
                    overflow: hidden;
                    overflow-y: auto;
                    .error-show {
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        padding: 0;
                        color: $color-light-red;
                        text-align: center;
                    }

                    &.single {
                        margin: 0;
                        padding: 0;
                    }

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 4px;
                        background-color: $color-light-light-gray;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                        border-radius: 10px;
                        background-color: $color-light-light-gray;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                        background-color: $color-oldSilver;
                    }

                    .dropdwonList-subhead,
                    .dropdwonLists-label {
                        margin: 0 0 16px 0;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-gray;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 8px;
                        &.disabled,
                        &:disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                            opacity: .5;
                          }
                
                        &:last-child {
                            margin: 0 0 0 0;
                        }

                        &.sub-list {
                            margin-left: 8px;
                        }

                        label {
                            cursor: pointer;
                        }

                        .checkbox-img {
                            width: 16px;
                            height: 100%;
                        }
                    }

                    .setTime-flex {
                        display: flex;
                        flex-direction: row;
                        column-gap: 16px;
                        width: 100%;
                        position: relative;

                        .setTime {
                            display: inline-flex;
                            flex-direction: row;
                            width: 150px;
                            column-gap: 10px;
                            border-radius: 12px;
                            background: $color-white;
                            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
                            margin: 0 0 8px 2px;
                            align-items: center;
                        }

                        .clock-icon {
                            display: block;
                            margin: 2px 0px 2px 8px;
                        }

                        input {
                            width: 62px;
                            padding: 10px;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin: 4px 0;
                            height: 35px;
                            border: none;
                        }

                        .time-button {
                            background-color: $color-light-light-gray;
                            color: $color-primary-dark;
                            width: 42px;
                            height: 42;
                            padding: 10px;
                            border-radius: 0 12px 12px 0;
                        }
                    }
                }
            }
        }
    }
 }