.group-input{
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;

    .input-container{
        .input-field{
            margin: 8px 0; 
            input{
                width: 250px;
                height: 30px;
            }      
        }
        
    }
}
.btn-submit{
    width: 120px;
    height: 40px;
    color: $color-white;
    background: $color-primary-dark;
    border: none;
    margin: 20px 0;
}
.ticket-container{
    margin: 20px;
}