.popupModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  background-color: rgba(0,0,0,0.2);
  overflow-y: hidden;
  font-weight: 400;
  &__container {
    height: 100%;
    outline: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }

  &.show .popupModal__container {
    animation-name: transformPop;
    animation-duration: 0.75s;
    animation-timing-function: ease-in-out;
  }

  &__root {
    margin: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: fit-content;
    border-radius: 5px 5px 0px 0px;
    padding: 12px;
    background-color: $color-white;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-gray;
    background-color: transparent;
    /*padding: 11px 17px 9px 24px;*/
    justify-content: space-between;
    border-radius: 5px 5px 0px 0px;

    & h2 {
      min-width: 300px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $color-gray;
      margin: auto 25px auto;
      text-align: center;
    }

    & .icon-close {
      color: $color-darkLiver;
      cursor: pointer;
      position: absolute;
      right: 12px;
      height: 15px;
      top: 12px;
      z-index: 150;
    }
  }

  &__body {
    /*overflow: auto;*/
    background-color: $color-white;
    z-index: 1;
    position: relative;
    min-height: 9vh;
    height: 100%;

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgb(167, 165, 165);
    }
  }
}

@keyframes transformPop {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media only screen and (max-width: 1280px) {
  .popupModal__root {
    max-width: 1200px;
    max-height: 550px;
  }
}
