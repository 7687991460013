.loader{
    position: absolute;
    bottom: 100px;
    right: 0;
    width: 200px;
    height: 60px;
}

.loader>span{
	font-size: 30px;
	font-family: "franklin gothic medium",sans-serif;
	display: inline-block;
	animation:flip 2.6s infinite linear;
	transform-origin:0 70%;
	transform-style:preserve-3d;
	-webkit-transform-style:preserve-3d;
}

@keyframes flip{
	35%{
		transform: rotateX(360deg);
	}
	100%{
		transform: rotatex(360deg);
	}
}


.loader>span {
	color: $color-primary-dark;
}

.loader>span:nth-child(2){
	animation-delay: 0.3s;
}

.loader>span:nth-child(3){
	animation-delay: 0.6s;
}

.loader>span:nth-child(4){
	animation-delay: 0.9s;
}

.loader>span:nth-child(5){
	animation-delay: 1.2s;
}

.loader>span:nth-child(6){
	animation-delay: 1.5s
}

.loader>span:nth-child(7){
	animation-delay: 1.8s
}