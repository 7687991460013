@use "variables" as *;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zd1wrl');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zd1wrl#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?zd1wrl') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?zd1wrl') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?zd1wrl##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-redirect {
  &:before {
    content: $icon-redirect; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;     
    color: #f9f9f9;
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-three-dots {
  &:before {
    content: $icon-three-dots;     
    color: #4f4f4f;
  }
}
.icon-drag-drop {
  &:before {
    content: $icon-drag-drop;     
    color: #4f4f4f;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-star-active {
  &:before {
    content: $icon-star-active;     
    color: #00adf0;
  }
}
.icon-time_empty .path1 {
  &:before {
    content: $icon-time_empty-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.0100;
  }
}
.icon-time_empty .path2 {
  &:before {
    content: $icon-time_empty-path2;  
    margin-left: -1em;  
    color: rgb(22, 22, 22);
  }
}
.icon-time_empty .path3 {
  &:before {
    content: $icon-time_empty-path3;  
    margin-left: -1em;  
    color: rgb(22, 22, 22);
  }
}
.icon-edit-horizontal-icons1 {
  &:before {
    content: $icon-edit-horizontal-icons1; 
  }
}
.icon-upload_file {
  &:before {
    content: $icon-upload_file; 
  }
}
.icon-description {
  &:before {
    content: $icon-description; 
  }
}
.icon-certificate-check .path1 {
  &:before {
    content: $icon-certificate-check-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.0100;
  }
}
.icon-certificate-check .path2 {
  &:before {
    content: $icon-certificate-check-path2;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-certificate-check .path3 {
  &:before {
    content: $icon-certificate-check-path3;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-certificate-check .path4 {
  &:before {
    content: $icon-certificate-check-path4;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-certificate-check .path5 {
  &:before {
    content: $icon-certificate-check-path5;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-certificate-check .path6 {
  &:before {
    content: $icon-certificate-check-path6;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-time .path1 {
  &:before {
    content: $icon-time-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.0100;
  }
}
.icon-time .path2 {
  &:before {
    content: $icon-time-path2;  
    margin-left: -1em;  
    color: rgb(22, 22, 22);
  }
}
.icon-time .path3 {
  &:before {
    content: $icon-time-path3;  
    margin-left: -1em;  
    color: rgb(22, 22, 22);
  }
}
.icon-timer .path1 {
  &:before {
    content: $icon-timer-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.0100;
  }
}
.icon-timer .path2 {
  &:before {
    content: $icon-timer-path2;  
    margin-left: -1.0625em;  
    color: rgb(235, 87, 87);
  }
}
.icon-timer .path3 {
  &:before {
    content: $icon-timer-path3;  
    margin-left: -1.0625em;  
    color: rgb(235, 87, 87);
  }
}
.icon-timer .path4 {
  &:before {
    content: $icon-timer-path4;  
    margin-left: -1.0625em;  
    color: rgb(235, 87, 87);
  }
}
.icon-list-box .path1 {
  &:before {
    content: $icon-list-box-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.0100;
  }
}
.icon-list-box .path2 {
  &:before {
    content: $icon-list-box-path2;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-list-box .path3 {
  &:before {
    content: $icon-list-box-path3;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-list-box .path4 {
  &:before {
    content: $icon-list-box-path4;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-list-box .path5 {
  &:before {
    content: $icon-list-box-path5;  
    margin-left: -1.0625em;  
    color: rgb(22, 22, 22);
  }
}
.icon-download_file {
  &:before {
    content: $icon-download_file;     
    color: #4f4f4f;
  }
}
.icon-check-list {
  &:before {
    content: $icon-check-list;     
    color: #4f4f4f;
  }
}
.icon-ordered-list {
  &:before {
    content: $icon-ordered-list;     
    color: #4f4f4f;
  }
}
.icon-bullet-list {
  &:before {
    content: $icon-bullet-list;     
    color: #4f4f4f;
  }
}
.icon-underline {
  &:before {
    content: $icon-underline;     
    color: #4f4f4f;
  }
}
.icon-strike {
  &:before {
    content: $icon-strike;     
    color: #4f4f4f;
  }
}
.icon-italic {
  &:before {
    content: $icon-italic;     
    color: #4f4f4f;
  }
}
.icon-bold {
  &:before {
    content: $icon-bold;     
    color: #4f4f4f;
  }
}
.icon-status-in-progress .path1 {
  &:before {
    content: $icon-status-in-progress-path1;  
    color: rgb(241, 185, 14);
  }
}
.icon-status-in-progress .path2 {
  &:before {
    content: $icon-status-in-progress-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-status-in-progress .path3 {
  &:before {
    content: $icon-status-in-progress-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-status-in-progress .path4 {
  &:before {
    content: $icon-status-in-progress-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-status-pause .path1 {
  &:before {
    content: $icon-status-pause-path1;  
    color: rgb(242, 153, 74);
  }
}
.icon-status-pause .path2 {
  &:before {
    content: $icon-status-pause-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-status-pause .path3 {
  &:before {
    content: $icon-status-pause-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-users {
  &:before {
    content: $icon-users;     
    color: #00adf0;
  }
}
.icon-access-pending {
  &:before {
    content: $icon-access-pending;     
    color: #eb5757;
  }
}
.icon-access-request {
  &:before {
    content: $icon-access-request;     
    color: #00adf0;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;     
    color: #eb5757;
  }
}
.icon-remainder {
  &:before {
    content: $icon-remainder;     
    color: #00adf0;
  }
}
.icon-kanban {
  &:before {
    content: $icon-kanban;     
    color: #00adf0;
  }
}
.icon-ss-calendar {
  &:before {
    content: $icon-ss-calendar;     
    color: #49454f;
  }
}
.icon-unsupported-file {
  &:before {
    content: $icon-unsupported-file;     
    color: #4f4f4f;
  }
}
.icon-archive-file {
  &:before {
    content: $icon-archive-file;     
    color: #4f4f4f;
  }
}
.icon-doc-file {
  &:before {
    content: $icon-doc-file;     
    color: #4f4f4f;
  }
}
.icon-image-file {
  &:before {
    content: $icon-image-file;     
    color: #4f4f4f;
  }
}
.icon-pdf-file {
  &:before {
    content: $icon-pdf-file;     
    color: #4f4f4f;
  }
}
.icon-xls-file {
  &:before {
    content: $icon-xls-file;     
    color: #4f4f4f;
  }
}
.icon-remove-cross .path1 {
  &:before {
    content: $icon-remove-cross-path1;  
    color: rgb(130, 130, 130);
  }
}
.icon-remove-cross .path2 {
  &:before {
    content: $icon-remove-cross-path2;  
    margin-left: -1.0908203125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-yet-to-launch {
  &:before {
    content: $icon-yet-to-launch; 
  }
}
.icon-ir-tools-only {
  &:before {
    content: $icon-ir-tools-only;     
    color: #27ae60;
  }
}
.icon-failure-cross1 .path1 {
  &:before {
    content: $icon-failure-cross1-path1;  
    color: rgb(233, 93, 93);
  }
}
.icon-failure-cross1 .path2 {
  &:before {
    content: $icon-failure-cross1-path2;  
    margin-left: -1.0556640625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ir-medium {
  &:before {
    content: $icon-ir-medium;     
    color: #51afe4;
  }
}
.icon-ir-plus {
  &:before {
    content: $icon-ir-plus;     
    color: #a5d843;
  }
}
.icon-ir-premium {
  &:before {
    content: $icon-ir-premium;     
    color: #ffba57;
  }
}
.icon-ir-basic {
  &:before {
    content: $icon-ir-basic;     
    color: #fa7b7b;
  }
}
.icon-success-tick1 .path1 {
  &:before {
    content: $icon-success-tick1-path1;  
    color: rgb(25, 142, 87);
  }
}
.icon-success-tick1 .path2 {
  &:before {
    content: $icon-success-tick1-path2;  
    margin-left: -1.0556640625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-unchecked {
  &:before {
    content: $icon-unchecked; 
  }
}
.icon-checked {
  &:before {
    content: $icon-checked; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-away {
  &:before {
    content: $icon-away;     
    color: #e0e0e0;
  }
}
.icon-busy {
  &:before {
    content: $icon-busy;     
    color: #eb5757;
  }
}
.icon-active {
  &:before {
    content: $icon-active;     
    color: #27ae60;
  }
}
.icon-wave .path1 {
  &:before {
    content: $icon-wave-path1;  
    color: rgb(0, 0, 0);
  }
}
.icon-wave .path2 {
  &:before {
    content: $icon-wave-path2;  
    margin-left: -1em;  
    color: rgb(237, 166, 0);
  }
}
.icon-wave .path3 {
  &:before {
    content: $icon-wave-path3;  
    margin-left: -1em;  
    color: rgb(237, 166, 0);
  }
}
.icon-wave .path4 {
  &:before {
    content: $icon-wave-path4;  
    margin-left: -1em;  
    color: rgb(176, 190, 197);
  }
}
.icon-wave .path5 {
  &:before {
    content: $icon-wave-path5;  
    margin-left: -1em;  
    color: rgb(144, 164, 174);
  }
}
.icon-wave .path6 {
  &:before {
    content: $icon-wave-path6;  
    margin-left: -1em;  
    color: rgb(176, 190, 197);
  }
}
.icon-wave .path7 {
  &:before {
    content: $icon-wave-path7;  
    margin-left: -1em;  
    color: rgb(144, 164, 174);
  }
}
.icon-euroland-icon {
  &:before {
    content: $icon-euroland-icon;     
    color: #fff;
  }
}
.icon-euroland-icon-primary .path1 {
  &:before {
    content: $icon-euroland-icon-primary-path1;  
    color: rgb(8, 43, 69);
  }
}
.icon-euroland-icon-primary .path2 {
  &:before {
    content: $icon-euroland-icon-primary-path2;  
    margin-left: -11.125em;  
    color: rgb(8, 43, 69);
  }
}
.icon-euroland-icon-primary .path3 {
  &:before {
    content: $icon-euroland-icon-primary-path3;  
    margin-left: -11.125em;  
    color: rgb(0, 173, 240);
  }
}
.icon-euroland-icon-primary .path4 {
  &:before {
    content: $icon-euroland-icon-primary-path4;  
    margin-left: -11.125em;  
    color: rgb(0, 173, 240);
  }
}
.icon-Chat_Circle {
  &:before {
    content: $icon-Chat_Circle;     
    color: #fff;
  }
}
.icon-Mail {
  &:before {
    content: $icon-Mail;     
    color: #fff;
  }
}
.icon-Phone {
  &:before {
    content: $icon-Phone;     
    color: #fff;
  }
}
.icon-Bell_Notification .path1 {
  &:before {
    content: $icon-Bell_Notification-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-Bell_Notification .path2 {
  &:before {
    content: $icon-Bell_Notification-path2;  
    margin-left: -0.9599609375em;  
    color: rgb(255, 255, 255);
  }
}
.icon-Bell_Notification .path3 {
  &:before {
    content: $icon-Bell_Notification-path3;  
    margin-left: -0.9599609375em;  
    color: rgb(235, 87, 87);
  }
}
.icon-Bell_Notification .path4 {
  &:before {
    content: $icon-Bell_Notification-path4;  
    margin-left: -0.9599609375em;  
    color: rgb(235, 87, 87);
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment;     
    color: #4f4f4f;
  }
}
.icon-about-us {
  &:before {
    content: $icon-about-us;     
    color: #fff;
  }
}
.icon-about-us-inactive {
  &:before {
    content: $icon-about-us-inactive; 
  }
}
.icon-agent-board-icon {
  &:before {
    content: $icon-agent-board-icon;     
    color: #00adf0;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-assigned-user .path1 {
  &:before {
    content: $icon-assigned-user-path1;  
    color: rgb(217, 217, 217);
  }
}
.icon-assigned-user .path2 {
  &:before {
    content: $icon-assigned-user-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-attachment-icon {
  &:before {
    content: $icon-attachment-icon; 
  }
}
.icon-blue-bell {
  &:before {
    content: $icon-blue-bell;     
    color: #4339f2;
  }
}
.icon-calendar-date-picker {
  &:before {
    content: $icon-calendar-date-picker;     
    color: #00adf0;
  }
}
.icon-calendar-blue {
  &:before {
    content: $icon-calendar-blue;     
    color: #00adf0;
  }
}
.icon-calendar-blue-slash {
  &:before {
    content: $icon-calendar-blue-slash;     
    color: #00adf0;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;     
    color: #49454f;
  }
}
.icon-card-assign {
  &:before {
    content: $icon-card-assign; 
  }
}
.icon-card-icon {
  &:before {
    content: $icon-card-icon; 
  }
}
.icon-caret-circle-left {
  &:before {
    content: $icon-caret-circle-left;     
    color: #828282;
  }
}
.icon-caret-circle-right {
  &:before {
    content: $icon-caret-circle-right;     
    color: #828282;
  }
}
.icon-clock-counter-clock-wise {
  &:before {
    content: $icon-clock-counter-clock-wise;     
    color: #4f4f4f;
  }
}
.icon-clock-icon {
  &:before {
    content: $icon-clock-icon;     
    color: #d9d9d9;
  }
}
.icon-close-icon-white {
  &:before {
    content: $icon-close-icon-white;     
    color: #fff;
  }
}
.icon-close-icon {
  &:before {
    content: $icon-close-icon; 
  }
}
.icon-comment-icon {
  &:before {
    content: $icon-comment-icon; 
  }
}
.icon-contact-us-banner .path1 {
  &:before {
    content: $icon-contact-us-banner-path1;  
    color: rgb(245, 245, 245);
  }
}
.icon-contact-us-banner .path2 {
  &:before {
    content: $icon-contact-us-banner-path2;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-contact-us-banner .path3 {
  &:before {
    content: $icon-contact-us-banner-path3;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-contact-us-banner .path4 {
  &:before {
    content: $icon-contact-us-banner-path4;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path5 {
  &:before {
    content: $icon-contact-us-banner-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path6 {
  &:before {
    content: $icon-contact-us-banner-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-contact-us-banner .path7 {
  &:before {
    content: $icon-contact-us-banner-path7;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path8 {
  &:before {
    content: $icon-contact-us-banner-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-contact-us-banner .path9 {
  &:before {
    content: $icon-contact-us-banner-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-contact-us-banner .path10 {
  &:before {
    content: $icon-contact-us-banner-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-contact-us-banner .path11 {
  &:before {
    content: $icon-contact-us-banner-path11;  
    margin-left: -1em;  
    color: rgb(69, 90, 100);
  }
}
.icon-contact-us-banner .path12 {
  &:before {
    content: $icon-contact-us-banner-path12;  
    margin-left: -1em;  
    color: rgb(55, 71, 79);
  }
}
.icon-contact-us-banner .path13 {
  &:before {
    content: $icon-contact-us-banner-path13;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path14 {
  &:before {
    content: $icon-contact-us-banner-path14;  
    margin-left: -1em;  
    color: rgb(55, 71, 79);
  }
}
.icon-contact-us-banner .path15 {
  &:before {
    content: $icon-contact-us-banner-path15;  
    margin-left: -1em;  
    color: rgb(55, 71, 79);
  }
}
.icon-contact-us-banner .path16 {
  &:before {
    content: $icon-contact-us-banner-path16;  
    margin-left: -1em;  
    color: rgb(69, 90, 100);
  }
}
.icon-contact-us-banner .path17 {
  &:before {
    content: $icon-contact-us-banner-path17;  
    margin-left: -1em;  
    color: rgb(55, 71, 79);
  }
}
.icon-contact-us-banner .path18 {
  &:before {
    content: $icon-contact-us-banner-path18;  
    margin-left: -1em;  
    color: rgb(69, 90, 100);
  }
}
.icon-contact-us-banner .path19 {
  &:before {
    content: $icon-contact-us-banner-path19;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path20 {
  &:before {
    content: $icon-contact-us-banner-path20;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path21 {
  &:before {
    content: $icon-contact-us-banner-path21;  
    margin-left: -1em;  
    color: rgb(69, 90, 100);
  }
}
.icon-contact-us-banner .path22 {
  &:before {
    content: $icon-contact-us-banner-path22;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path23 {
  &:before {
    content: $icon-contact-us-banner-path23;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path24 {
  &:before {
    content: $icon-contact-us-banner-path24;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path25 {
  &:before {
    content: $icon-contact-us-banner-path25;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path26 {
  &:before {
    content: $icon-contact-us-banner-path26;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path27 {
  &:before {
    content: $icon-contact-us-banner-path27;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path28 {
  &:before {
    content: $icon-contact-us-banner-path28;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path29 {
  &:before {
    content: $icon-contact-us-banner-path29;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path30 {
  &:before {
    content: $icon-contact-us-banner-path30;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path31 {
  &:before {
    content: $icon-contact-us-banner-path31;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path32 {
  &:before {
    content: $icon-contact-us-banner-path32;  
    margin-left: -1em;  
    color: rgb(148, 89, 89);
  }
}
.icon-contact-us-banner .path33 {
  &:before {
    content: $icon-contact-us-banner-path33;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path34 {
  &:before {
    content: $icon-contact-us-banner-path34;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path35 {
  &:before {
    content: $icon-contact-us-banner-path35;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path36 {
  &:before {
    content: $icon-contact-us-banner-path36;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path37 {
  &:before {
    content: $icon-contact-us-banner-path37;  
    margin-left: -1em;  
    color: rgb(148, 89, 89);
  }
}
.icon-contact-us-banner .path38 {
  &:before {
    content: $icon-contact-us-banner-path38;  
    margin-left: -1em;  
    color: rgb(148, 89, 89);
  }
}
.icon-contact-us-banner .path39 {
  &:before {
    content: $icon-contact-us-banner-path39;  
    margin-left: -1em;  
    color: rgb(112, 56, 56);
  }
}
.icon-contact-us-banner .path40 {
  &:before {
    content: $icon-contact-us-banner-path40;  
    margin-left: -1em;  
    color: rgb(148, 89, 89);
  }
}
.icon-contact-us-banner .path41 {
  &:before {
    content: $icon-contact-us-banner-path41;  
    margin-left: -1em;  
    color: rgb(112, 56, 56);
  }
}
.icon-contact-us-banner .path42 {
  &:before {
    content: $icon-contact-us-banner-path42;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path43 {
  &:before {
    content: $icon-contact-us-banner-path43;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path44 {
  &:before {
    content: $icon-contact-us-banner-path44;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path45 {
  &:before {
    content: $icon-contact-us-banner-path45;  
    margin-left: -1em;  
    color: rgb(112, 56, 56);
  }
}
.icon-contact-us-banner .path46 {
  &:before {
    content: $icon-contact-us-banner-path46;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-contact-us-banner .path47 {
  &:before {
    content: $icon-contact-us-banner-path47;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path48 {
  &:before {
    content: $icon-contact-us-banner-path48;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path49 {
  &:before {
    content: $icon-contact-us-banner-path49;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path50 {
  &:before {
    content: $icon-contact-us-banner-path50;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path51 {
  &:before {
    content: $icon-contact-us-banner-path51;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path52 {
  &:before {
    content: $icon-contact-us-banner-path52;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.3;
  }
}
.icon-contact-us-banner .path53 {
  &:before {
    content: $icon-contact-us-banner-path53;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path54 {
  &:before {
    content: $icon-contact-us-banner-path54;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path55 {
  &:before {
    content: $icon-contact-us-banner-path55;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path56 {
  &:before {
    content: $icon-contact-us-banner-path56;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path57 {
  &:before {
    content: $icon-contact-us-banner-path57;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path58 {
  &:before {
    content: $icon-contact-us-banner-path58;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path59 {
  &:before {
    content: $icon-contact-us-banner-path59;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.5;
  }
}
.icon-contact-us-banner .path60 {
  &:before {
    content: $icon-contact-us-banner-path60;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path61 {
  &:before {
    content: $icon-contact-us-banner-path61;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.7;
  }
}
.icon-contact-us-banner .path62 {
  &:before {
    content: $icon-contact-us-banner-path62;  
    margin-left: -1em;  
    color: rgb(148, 89, 89);
  }
}
.icon-contact-us-banner .path63 {
  &:before {
    content: $icon-contact-us-banner-path63;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path64 {
  &:before {
    content: $icon-contact-us-banner-path64;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path65 {
  &:before {
    content: $icon-contact-us-banner-path65;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path66 {
  &:before {
    content: $icon-contact-us-banner-path66;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path67 {
  &:before {
    content: $icon-contact-us-banner-path67;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.3;
  }
}
.icon-contact-us-banner .path68 {
  &:before {
    content: $icon-contact-us-banner-path68;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.6;
  }
}
.icon-contact-us-banner .path69 {
  &:before {
    content: $icon-contact-us-banner-path69;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path70 {
  &:before {
    content: $icon-contact-us-banner-path70;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path71 {
  &:before {
    content: $icon-contact-us-banner-path71;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path72 {
  &:before {
    content: $icon-contact-us-banner-path72;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path73 {
  &:before {
    content: $icon-contact-us-banner-path73;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path74 {
  &:before {
    content: $icon-contact-us-banner-path74;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.3;
  }
}
.icon-contact-us-banner .path75 {
  &:before {
    content: $icon-contact-us-banner-path75;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.6;
  }
}
.icon-contact-us-banner .path76 {
  &:before {
    content: $icon-contact-us-banner-path76;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path77 {
  &:before {
    content: $icon-contact-us-banner-path77;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path78 {
  &:before {
    content: $icon-contact-us-banner-path78;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path79 {
  &:before {
    content: $icon-contact-us-banner-path79;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path80 {
  &:before {
    content: $icon-contact-us-banner-path80;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path81 {
  &:before {
    content: $icon-contact-us-banner-path81;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path82 {
  &:before {
    content: $icon-contact-us-banner-path82;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path83 {
  &:before {
    content: $icon-contact-us-banner-path83;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path84 {
  &:before {
    content: $icon-contact-us-banner-path84;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path85 {
  &:before {
    content: $icon-contact-us-banner-path85;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path86 {
  &:before {
    content: $icon-contact-us-banner-path86;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path87 {
  &:before {
    content: $icon-contact-us-banner-path87;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.3;
  }
}
.icon-contact-us-banner .path88 {
  &:before {
    content: $icon-contact-us-banner-path88;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.6;
  }
}
.icon-contact-us-banner .path89 {
  &:before {
    content: $icon-contact-us-banner-path89;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path90 {
  &:before {
    content: $icon-contact-us-banner-path90;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path91 {
  &:before {
    content: $icon-contact-us-banner-path91;  
    margin-left: -1em;  
    color: rgb(111, 142, 166);
  }
}
.icon-contact-us-banner .path92 {
  &:before {
    content: $icon-contact-us-banner-path92;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path93 {
  &:before {
    content: $icon-contact-us-banner-path93;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path94 {
  &:before {
    content: $icon-contact-us-banner-path94;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.9;
  }
}
.icon-contact-us-banner .path95 {
  &:before {
    content: $icon-contact-us-banner-path95;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-contact-us-banner .path96 {
  &:before {
    content: $icon-contact-us-banner-path96;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-cross-mark-not-fill {
  &:before {
    content: $icon-cross-mark-not-fill;     
    color: #eb5757;
  }
}
.icon-cross-mark {
  &:before {
    content: $icon-cross-mark;     
    color: #bdbdbd;
  }
}
.icon-description-icon {
  &:before {
    content: $icon-description-icon; 
  }
}
.icon-due-date-calendar {
  &:before {
    content: $icon-due-date-calendar; 
  }
}
.icon-edit-box-icon {
  &:before {
    content: $icon-edit-box-icon;     
    color: #4f4f4f;
  }
}
.icon-email-icon {
  &:before {
    content: $icon-email-icon;     
    color: #bdbdbd;
  }
}
.icon-failure-cross .path1 {
  &:before {
    content: $icon-failure-cross-path1;  
    color: rgb(233, 93, 93);
  }
}
.icon-failure-cross .path2 {
  &:before {
    content: $icon-failure-cross-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-filter-icon {
  &:before {
    content: $icon-filter-icon; 
  }
}
.icon-filter-rounded .path1 {
  &:before {
    content: $icon-filter-rounded-path1;  
    color: rgb(8, 43, 69);  
    opacity: 0.2;
  }
}
.icon-filter-rounded .path2 {
  &:before {
    content: $icon-filter-rounded-path2;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.8;
  }
}
.icon-filter-funnel {
  &:before {
    content: $icon-filter-funnel; 
  }
}
.icon-financial-release .path1 {
  &:before {
    content: $icon-financial-release-path1;  
    color: rgb(8, 43, 69);
  }
}
.icon-financial-release .path2 {
  &:before {
    content: $icon-financial-release-path2;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-financial-release .path3 {
  &:before {
    content: $icon-financial-release-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-green-bell {
  &:before {
    content: $icon-green-bell;     
    color: #30e578;
  }
}
.icon-grid-view {
  &:before {
    content: $icon-grid-view;     
    color: #4f4f4f;
  }
}
.icon-info-icon {
  &:before {
    content: $icon-info-icon;     
    color: #13588b;
  }
}
.icon-location-icon {
  &:before {
    content: $icon-location-icon;     
    color: #00adf0;
  }
}
.icon-login-background .path1 {
  &:before {
    content: $icon-login-background-path1;  
    color: rgb(0, 173, 240);  
    opacity: 0.1;
  }
}
.icon-login-background .path2 {
  &:before {
    content: $icon-login-background-path2;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path1 {
  &:before {
    content: $icon-login-banner-path1;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path2 {
  &:before {
    content: $icon-login-banner-path2;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path3 {
  &:before {
    content: $icon-login-banner-path3;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path4 {
  &:before {
    content: $icon-login-banner-path4;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path5 {
  &:before {
    content: $icon-login-banner-path5;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path6 {
  &:before {
    content: $icon-login-banner-path6;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path7 {
  &:before {
    content: $icon-login-banner-path7;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path8 {
  &:before {
    content: $icon-login-banner-path8;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path9 {
  &:before {
    content: $icon-login-banner-path9;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path10 {
  &:before {
    content: $icon-login-banner-path10;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path11 {
  &:before {
    content: $icon-login-banner-path11;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path12 {
  &:before {
    content: $icon-login-banner-path12;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path13 {
  &:before {
    content: $icon-login-banner-path13;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path14 {
  &:before {
    content: $icon-login-banner-path14;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path15 {
  &:before {
    content: $icon-login-banner-path15;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path16 {
  &:before {
    content: $icon-login-banner-path16;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path17 {
  &:before {
    content: $icon-login-banner-path17;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path18 {
  &:before {
    content: $icon-login-banner-path18;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path19 {
  &:before {
    content: $icon-login-banner-path19;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path20 {
  &:before {
    content: $icon-login-banner-path20;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path21 {
  &:before {
    content: $icon-login-banner-path21;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-login-banner .path22 {
  &:before {
    content: $icon-login-banner-path22;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path23 {
  &:before {
    content: $icon-login-banner-path23;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path24 {
  &:before {
    content: $icon-login-banner-path24;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path25 {
  &:before {
    content: $icon-login-banner-path25;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path26 {
  &:before {
    content: $icon-login-banner-path26;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path27 {
  &:before {
    content: $icon-login-banner-path27;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path28 {
  &:before {
    content: $icon-login-banner-path28;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path29 {
  &:before {
    content: $icon-login-banner-path29;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path30 {
  &:before {
    content: $icon-login-banner-path30;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path31 {
  &:before {
    content: $icon-login-banner-path31;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path32 {
  &:before {
    content: $icon-login-banner-path32;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path33 {
  &:before {
    content: $icon-login-banner-path33;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path34 {
  &:before {
    content: $icon-login-banner-path34;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path35 {
  &:before {
    content: $icon-login-banner-path35;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-login-banner .path36 {
  &:before {
    content: $icon-login-banner-path36;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-login-banner .path37 {
  &:before {
    content: $icon-login-banner-path37;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-login-banner .path38 {
  &:before {
    content: $icon-login-banner-path38;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-login-banner .path39 {
  &:before {
    content: $icon-login-banner-path39;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-login-banner .path40 {
  &:before {
    content: $icon-login-banner-path40;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-login-banner .path41 {
  &:before {
    content: $icon-login-banner-path41;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-login-banner .path42 {
  &:before {
    content: $icon-login-banner-path42;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-login-banner .path43 {
  &:before {
    content: $icon-login-banner-path43;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path44 {
  &:before {
    content: $icon-login-banner-path44;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path45 {
  &:before {
    content: $icon-login-banner-path45;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path46 {
  &:before {
    content: $icon-login-banner-path46;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.4;
  }
}
.icon-login-banner .path47 {
  &:before {
    content: $icon-login-banner-path47;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path48 {
  &:before {
    content: $icon-login-banner-path48;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path49 {
  &:before {
    content: $icon-login-banner-path49;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.1;
  }
}
.icon-login-banner .path50 {
  &:before {
    content: $icon-login-banner-path50;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.1;
  }
}
.icon-login-banner .path51 {
  &:before {
    content: $icon-login-banner-path51;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.8;
  }
}
.icon-login-banner .path52 {
  &:before {
    content: $icon-login-banner-path52;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.8;
  }
}
.icon-login-banner .path53 {
  &:before {
    content: $icon-login-banner-path53;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.8;
  }
}
.icon-login-banner .path54 {
  &:before {
    content: $icon-login-banner-path54;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.8;
  }
}
.icon-login-banner .path55 {
  &:before {
    content: $icon-login-banner-path55;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.8;
  }
}
.icon-login-banner .path56 {
  &:before {
    content: $icon-login-banner-path56;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path57 {
  &:before {
    content: $icon-login-banner-path57;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path58 {
  &:before {
    content: $icon-login-banner-path58;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path59 {
  &:before {
    content: $icon-login-banner-path59;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path60 {
  &:before {
    content: $icon-login-banner-path60;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path61 {
  &:before {
    content: $icon-login-banner-path61;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path62 {
  &:before {
    content: $icon-login-banner-path62;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path63 {
  &:before {
    content: $icon-login-banner-path63;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.6;
  }
}
.icon-login-banner .path64 {
  &:before {
    content: $icon-login-banner-path64;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path65 {
  &:before {
    content: $icon-login-banner-path65;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path66 {
  &:before {
    content: $icon-login-banner-path66;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-login-banner .path67 {
  &:before {
    content: $icon-login-banner-path67;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path68 {
  &:before {
    content: $icon-login-banner-path68;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path69 {
  &:before {
    content: $icon-login-banner-path69;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path70 {
  &:before {
    content: $icon-login-banner-path70;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path71 {
  &:before {
    content: $icon-login-banner-path71;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path72 {
  &:before {
    content: $icon-login-banner-path72;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path73 {
  &:before {
    content: $icon-login-banner-path73;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path74 {
  &:before {
    content: $icon-login-banner-path74;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path75 {
  &:before {
    content: $icon-login-banner-path75;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.2;
  }
}
.icon-login-banner .path76 {
  &:before {
    content: $icon-login-banner-path76;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path77 {
  &:before {
    content: $icon-login-banner-path77;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.1;
  }
}
.icon-login-banner .path78 {
  &:before {
    content: $icon-login-banner-path78;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.5;
  }
}
.icon-login-banner .path79 {
  &:before {
    content: $icon-login-banner-path79;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.5;
  }
}
.icon-login-banner .path80 {
  &:before {
    content: $icon-login-banner-path80;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.5;
  }
}
.icon-login-banner .path81 {
  &:before {
    content: $icon-login-banner-path81;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.2;
  }
}
.icon-login-banner .path82 {
  &:before {
    content: $icon-login-banner-path82;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path83 {
  &:before {
    content: $icon-login-banner-path83;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path84 {
  &:before {
    content: $icon-login-banner-path84;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-login-banner .path85 {
  &:before {
    content: $icon-login-banner-path85;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);  
    opacity: 0.0500;
  }
}
.icon-login-banner .path86 {
  &:before {
    content: $icon-login-banner-path86;  
    margin-left: -1em;  
    color: rgb(1, 160, 223);
  }
}
.icon-login-banner .path87 {
  &:before {
    content: $icon-login-banner-path87;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path88 {
  &:before {
    content: $icon-login-banner-path88;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-login-banner .path89 {
  &:before {
    content: $icon-login-banner-path89;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path90 {
  &:before {
    content: $icon-login-banner-path90;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path91 {
  &:before {
    content: $icon-login-banner-path91;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path92 {
  &:before {
    content: $icon-login-banner-path92;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path93 {
  &:before {
    content: $icon-login-banner-path93;  
    margin-left: -1em;  
    color: rgb(181, 91, 82);
  }
}
.icon-login-banner .path94 {
  &:before {
    content: $icon-login-banner-path94;  
    margin-left: -1em;  
    color: rgb(181, 91, 82);
  }
}
.icon-login-banner .path95 {
  &:before {
    content: $icon-login-banner-path95;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-login-banner .path96 {
  &:before {
    content: $icon-login-banner-path96;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path97 {
  &:before {
    content: $icon-login-banner-path97;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-login-banner .path98 {
  &:before {
    content: $icon-login-banner-path98;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path99 {
  &:before {
    content: $icon-login-banner-path99;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-login-banner .path100 {
  &:before {
    content: $icon-login-banner-path100;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path101 {
  &:before {
    content: $icon-login-banner-path101;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path102 {
  &:before {
    content: $icon-login-banner-path102;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-login-banner .path103 {
  &:before {
    content: $icon-login-banner-path103;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-login-banner .path104 {
  &:before {
    content: $icon-login-banner-path104;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path105 {
  &:before {
    content: $icon-login-banner-path105;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.4;
  }
}
.icon-login-banner .path106 {
  &:before {
    content: $icon-login-banner-path106;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path107 {
  &:before {
    content: $icon-login-banner-path107;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);  
    opacity: 0.6;
  }
}
.icon-login-banner .path108 {
  &:before {
    content: $icon-login-banner-path108;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.2;
  }
}
.icon-login-banner .path109 {
  &:before {
    content: $icon-login-banner-path109;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.2;
  }
}
.icon-login-banner .path110 {
  &:before {
    content: $icon-login-banner-path110;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.2;
  }
}
.icon-login-banner .path111 {
  &:before {
    content: $icon-login-banner-path111;  
    margin-left: -1em;  
    color: rgb(224, 156, 149);
  }
}
.icon-login-banner .path112 {
  &:before {
    content: $icon-login-banner-path112;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path113 {
  &:before {
    content: $icon-login-banner-path113;  
    margin-left: -1em;  
    color: rgb(64, 123, 255);
  }
}
.icon-login-banner .path114 {
  &:before {
    content: $icon-login-banner-path114;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path115 {
  &:before {
    content: $icon-login-banner-path115;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.6;
  }
}
.icon-login-banner .path116 {
  &:before {
    content: $icon-login-banner-path116;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path117 {
  &:before {
    content: $icon-login-banner-path117;  
    margin-left: -1em;  
    color: rgb(64, 123, 255);
  }
}
.icon-login-banner .path118 {
  &:before {
    content: $icon-login-banner-path118;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path119 {
  &:before {
    content: $icon-login-banner-path119;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path120 {
  &:before {
    content: $icon-login-banner-path120;  
    margin-left: -1em;  
    color: rgb(122, 73, 68);  
    opacity: 0.2;
  }
}
.icon-login-banner .path121 {
  &:before {
    content: $icon-login-banner-path121;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-login-banner .path122 {
  &:before {
    content: $icon-login-banner-path122;  
    margin-left: -1em;  
    color: rgb(241, 171, 164);
  }
}
.icon-login-banner .path123 {
  &:before {
    content: $icon-login-banner-path123;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path124 {
  &:before {
    content: $icon-login-banner-path124;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-login-banner .path125 {
  &:before {
    content: $icon-login-banner-path125;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-login-banner .path126 {
  &:before {
    content: $icon-login-banner-path126;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path1 {
  &:before {
    content: $icon-no-notification-data-path1;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path2 {
  &:before {
    content: $icon-no-notification-data-path2;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path3 {
  &:before {
    content: $icon-no-notification-data-path3;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path4 {
  &:before {
    content: $icon-no-notification-data-path4;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path5 {
  &:before {
    content: $icon-no-notification-data-path5;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path6 {
  &:before {
    content: $icon-no-notification-data-path6;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path7 {
  &:before {
    content: $icon-no-notification-data-path7;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path8 {
  &:before {
    content: $icon-no-notification-data-path8;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path9 {
  &:before {
    content: $icon-no-notification-data-path9;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path10 {
  &:before {
    content: $icon-no-notification-data-path10;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path11 {
  &:before {
    content: $icon-no-notification-data-path11;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path12 {
  &:before {
    content: $icon-no-notification-data-path12;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path13 {
  &:before {
    content: $icon-no-notification-data-path13;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path14 {
  &:before {
    content: $icon-no-notification-data-path14;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path15 {
  &:before {
    content: $icon-no-notification-data-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path16 {
  &:before {
    content: $icon-no-notification-data-path16;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path17 {
  &:before {
    content: $icon-no-notification-data-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path18 {
  &:before {
    content: $icon-no-notification-data-path18;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path19 {
  &:before {
    content: $icon-no-notification-data-path19;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path20 {
  &:before {
    content: $icon-no-notification-data-path20;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path21 {
  &:before {
    content: $icon-no-notification-data-path21;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path22 {
  &:before {
    content: $icon-no-notification-data-path22;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path23 {
  &:before {
    content: $icon-no-notification-data-path23;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path24 {
  &:before {
    content: $icon-no-notification-data-path24;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path25 {
  &:before {
    content: $icon-no-notification-data-path25;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path26 {
  &:before {
    content: $icon-no-notification-data-path26;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path27 {
  &:before {
    content: $icon-no-notification-data-path27;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path28 {
  &:before {
    content: $icon-no-notification-data-path28;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path29 {
  &:before {
    content: $icon-no-notification-data-path29;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path30 {
  &:before {
    content: $icon-no-notification-data-path30;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path31 {
  &:before {
    content: $icon-no-notification-data-path31;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path32 {
  &:before {
    content: $icon-no-notification-data-path32;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path33 {
  &:before {
    content: $icon-no-notification-data-path33;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path34 {
  &:before {
    content: $icon-no-notification-data-path34;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path35 {
  &:before {
    content: $icon-no-notification-data-path35;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path36 {
  &:before {
    content: $icon-no-notification-data-path36;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path37 {
  &:before {
    content: $icon-no-notification-data-path37;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path38 {
  &:before {
    content: $icon-no-notification-data-path38;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path39 {
  &:before {
    content: $icon-no-notification-data-path39;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path40 {
  &:before {
    content: $icon-no-notification-data-path40;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path41 {
  &:before {
    content: $icon-no-notification-data-path41;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path42 {
  &:before {
    content: $icon-no-notification-data-path42;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path43 {
  &:before {
    content: $icon-no-notification-data-path43;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path44 {
  &:before {
    content: $icon-no-notification-data-path44;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path45 {
  &:before {
    content: $icon-no-notification-data-path45;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path46 {
  &:before {
    content: $icon-no-notification-data-path46;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path47 {
  &:before {
    content: $icon-no-notification-data-path47;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path48 {
  &:before {
    content: $icon-no-notification-data-path48;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path49 {
  &:before {
    content: $icon-no-notification-data-path49;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path50 {
  &:before {
    content: $icon-no-notification-data-path50;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path51 {
  &:before {
    content: $icon-no-notification-data-path51;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path52 {
  &:before {
    content: $icon-no-notification-data-path52;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path53 {
  &:before {
    content: $icon-no-notification-data-path53;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path54 {
  &:before {
    content: $icon-no-notification-data-path54;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path55 {
  &:before {
    content: $icon-no-notification-data-path55;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path56 {
  &:before {
    content: $icon-no-notification-data-path56;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.icon-no-notification-data .path57 {
  &:before {
    content: $icon-no-notification-data-path57;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path58 {
  &:before {
    content: $icon-no-notification-data-path58;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path59 {
  &:before {
    content: $icon-no-notification-data-path59;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path60 {
  &:before {
    content: $icon-no-notification-data-path60;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path61 {
  &:before {
    content: $icon-no-notification-data-path61;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path62 {
  &:before {
    content: $icon-no-notification-data-path62;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);  
    opacity: 0.6;
  }
}
.icon-no-notification-data .path63 {
  &:before {
    content: $icon-no-notification-data-path63;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path64 {
  &:before {
    content: $icon-no-notification-data-path64;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path65 {
  &:before {
    content: $icon-no-notification-data-path65;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path66 {
  &:before {
    content: $icon-no-notification-data-path66;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path67 {
  &:before {
    content: $icon-no-notification-data-path67;  
    margin-left: -1em;  
    color: rgb(240, 240, 240);
  }
}
.icon-no-notification-data .path68 {
  &:before {
    content: $icon-no-notification-data-path68;  
    margin-left: -1em;  
    color: rgb(224, 224, 224);
  }
}
.icon-no-notification-data .path69 {
  &:before {
    content: $icon-no-notification-data-path69;  
    margin-left: -1em;  
    color: rgb(245, 245, 245);
  }
}
.icon-no-notification-data .path70 {
  &:before {
    content: $icon-no-notification-data-path70;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path71 {
  &:before {
    content: $icon-no-notification-data-path71;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path72 {
  &:before {
    content: $icon-no-notification-data-path72;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path73 {
  &:before {
    content: $icon-no-notification-data-path73;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path74 {
  &:before {
    content: $icon-no-notification-data-path74;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.5;
  }
}
.icon-no-notification-data .path75 {
  &:before {
    content: $icon-no-notification-data-path75;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path76 {
  &:before {
    content: $icon-no-notification-data-path76;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path77 {
  &:before {
    content: $icon-no-notification-data-path77;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path78 {
  &:before {
    content: $icon-no-notification-data-path78;  
    margin-left: -1em;  
    color: rgb(250, 250, 250);
  }
}
.icon-no-notification-data .path79 {
  &:before {
    content: $icon-no-notification-data-path79;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path80 {
  &:before {
    content: $icon-no-notification-data-path80;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-no-notification-data .path81 {
  &:before {
    content: $icon-no-notification-data-path81;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path82 {
  &:before {
    content: $icon-no-notification-data-path82;  
    margin-left: -1em;  
    color: rgb(235, 235, 235);
  }
}
.icon-no-notification-data .path83 {
  &:before {
    content: $icon-no-notification-data-path83;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path84 {
  &:before {
    content: $icon-no-notification-data-path84;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path85 {
  &:before {
    content: $icon-no-notification-data-path85;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path86 {
  &:before {
    content: $icon-no-notification-data-path86;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path87 {
  &:before {
    content: $icon-no-notification-data-path87;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path88 {
  &:before {
    content: $icon-no-notification-data-path88;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path89 {
  &:before {
    content: $icon-no-notification-data-path89;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path90 {
  &:before {
    content: $icon-no-notification-data-path90;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path91 {
  &:before {
    content: $icon-no-notification-data-path91;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path92 {
  &:before {
    content: $icon-no-notification-data-path92;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path93 {
  &:before {
    content: $icon-no-notification-data-path93;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path94 {
  &:before {
    content: $icon-no-notification-data-path94;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path95 {
  &:before {
    content: $icon-no-notification-data-path95;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path96 {
  &:before {
    content: $icon-no-notification-data-path96;  
    margin-left: -1em;  
    color: rgb(255, 86, 82);
  }
}
.icon-no-notification-data .path97 {
  &:before {
    content: $icon-no-notification-data-path97;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path98 {
  &:before {
    content: $icon-no-notification-data-path98;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path99 {
  &:before {
    content: $icon-no-notification-data-path99;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path100 {
  &:before {
    content: $icon-no-notification-data-path100;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path101 {
  &:before {
    content: $icon-no-notification-data-path101;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.2;
  }
}
.icon-no-notification-data .path102 {
  &:before {
    content: $icon-no-notification-data-path102;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path103 {
  &:before {
    content: $icon-no-notification-data-path103;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path104 {
  &:before {
    content: $icon-no-notification-data-path104;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path105 {
  &:before {
    content: $icon-no-notification-data-path105;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path106 {
  &:before {
    content: $icon-no-notification-data-path106;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path107 {
  &:before {
    content: $icon-no-notification-data-path107;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path108 {
  &:before {
    content: $icon-no-notification-data-path108;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path109 {
  &:before {
    content: $icon-no-notification-data-path109;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path110 {
  &:before {
    content: $icon-no-notification-data-path110;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path111 {
  &:before {
    content: $icon-no-notification-data-path111;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path112 {
  &:before {
    content: $icon-no-notification-data-path112;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path113 {
  &:before {
    content: $icon-no-notification-data-path113;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path114 {
  &:before {
    content: $icon-no-notification-data-path114;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path115 {
  &:before {
    content: $icon-no-notification-data-path115;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.1;
  }
}
.icon-no-notification-data .path116 {
  &:before {
    content: $icon-no-notification-data-path116;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.3;
  }
}
.icon-no-notification-data .path117 {
  &:before {
    content: $icon-no-notification-data-path117;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path118 {
  &:before {
    content: $icon-no-notification-data-path118;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path119 {
  &:before {
    content: $icon-no-notification-data-path119;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.4;
  }
}
.icon-no-notification-data .path120 {
  &:before {
    content: $icon-no-notification-data-path120;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path121 {
  &:before {
    content: $icon-no-notification-data-path121;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path122 {
  &:before {
    content: $icon-no-notification-data-path122;  
    margin-left: -1em;  
    color: rgb(255, 181, 115);
  }
}
.icon-no-notification-data .path123 {
  &:before {
    content: $icon-no-notification-data-path123;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.2;
  }
}
.icon-no-notification-data .path124 {
  &:before {
    content: $icon-no-notification-data-path124;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path125 {
  &:before {
    content: $icon-no-notification-data-path125;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.1;
  }
}
.icon-no-notification-data .path126 {
  &:before {
    content: $icon-no-notification-data-path126;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path127 {
  &:before {
    content: $icon-no-notification-data-path127;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path128 {
  &:before {
    content: $icon-no-notification-data-path128;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path129 {
  &:before {
    content: $icon-no-notification-data-path129;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.4;
  }
}
.icon-no-notification-data .path130 {
  &:before {
    content: $icon-no-notification-data-path130;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path131 {
  &:before {
    content: $icon-no-notification-data-path131;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);  
    opacity: 0.4;
  }
}
.icon-no-notification-data .path132 {
  &:before {
    content: $icon-no-notification-data-path132;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-no-notification-data .path133 {
  &:before {
    content: $icon-no-notification-data-path133;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-no-notification-data .path134 {
  &:before {
    content: $icon-no-notification-data-path134;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-no-notification-data .path135 {
  &:before {
    content: $icon-no-notification-data-path135;  
    margin-left: -1em;  
    color: rgb(38, 50, 56);
  }
}
.icon-open-eye {
  &:before {
    content: $icon-open-eye;     
    color: #c5c5c5;
  }
}
.icon-open-eye-slash .path1 {
  &:before {
    content: $icon-open-eye-slash-path1;  
    color: rgb(197, 197, 197);
  }
}
.icon-open-eye-slash .path2 {
  &:before {
    content: $icon-open-eye-slash-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-open-eye-slash .path3 {
  &:before {
    content: $icon-open-eye-slash-path3;  
    margin-left: -1em;  
    color: rgb(196, 196, 196);
  }
}
.icon-orange-bell {
  &:before {
    content: $icon-orange-bell;     
    color: #fb9318;
  }
}
.icon-orion-logo .path1 {
  &:before {
    content: $icon-orion-logo-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-orion-logo .path2 {
  &:before {
    content: $icon-orion-logo-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-orion-logo .path3 {
  &:before {
    content: $icon-orion-logo-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-orion-logo .path4 {
  &:before {
    content: $icon-orion-logo-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-orion-logo .path5 {
  &:before {
    content: $icon-orion-logo-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path6 {
  &:before {
    content: $icon-orion-logo-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path7 {
  &:before {
    content: $icon-orion-logo-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path8 {
  &:before {
    content: $icon-orion-logo-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path9 {
  &:before {
    content: $icon-orion-logo-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path10 {
  &:before {
    content: $icon-orion-logo-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path11 {
  &:before {
    content: $icon-orion-logo-path11;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path12 {
  &:before {
    content: $icon-orion-logo-path12;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path13 {
  &:before {
    content: $icon-orion-logo-path13;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path14 {
  &:before {
    content: $icon-orion-logo-path14;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path15 {
  &:before {
    content: $icon-orion-logo-path15;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path16 {
  &:before {
    content: $icon-orion-logo-path16;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path17 {
  &:before {
    content: $icon-orion-logo-path17;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path18 {
  &:before {
    content: $icon-orion-logo-path18;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path19 {
  &:before {
    content: $icon-orion-logo-path19;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path20 {
  &:before {
    content: $icon-orion-logo-path20;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path21 {
  &:before {
    content: $icon-orion-logo-path21;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path22 {
  &:before {
    content: $icon-orion-logo-path22;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path23 {
  &:before {
    content: $icon-orion-logo-path23;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path24 {
  &:before {
    content: $icon-orion-logo-path24;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path25 {
  &:before {
    content: $icon-orion-logo-path25;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path26 {
  &:before {
    content: $icon-orion-logo-path26;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path27 {
  &:before {
    content: $icon-orion-logo-path27;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path28 {
  &:before {
    content: $icon-orion-logo-path28;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path29 {
  &:before {
    content: $icon-orion-logo-path29;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path30 {
  &:before {
    content: $icon-orion-logo-path30;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path31 {
  &:before {
    content: $icon-orion-logo-path31;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path32 {
  &:before {
    content: $icon-orion-logo-path32;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path33 {
  &:before {
    content: $icon-orion-logo-path33;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path34 {
  &:before {
    content: $icon-orion-logo-path34;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path35 {
  &:before {
    content: $icon-orion-logo-path35;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path36 {
  &:before {
    content: $icon-orion-logo-path36;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path37 {
  &:before {
    content: $icon-orion-logo-path37;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path38 {
  &:before {
    content: $icon-orion-logo-path38;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path39 {
  &:before {
    content: $icon-orion-logo-path39;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path40 {
  &:before {
    content: $icon-orion-logo-path40;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path41 {
  &:before {
    content: $icon-orion-logo-path41;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path42 {
  &:before {
    content: $icon-orion-logo-path42;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path43 {
  &:before {
    content: $icon-orion-logo-path43;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path44 {
  &:before {
    content: $icon-orion-logo-path44;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path45 {
  &:before {
    content: $icon-orion-logo-path45;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path46 {
  &:before {
    content: $icon-orion-logo-path46;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path47 {
  &:before {
    content: $icon-orion-logo-path47;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path48 {
  &:before {
    content: $icon-orion-logo-path48;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path49 {
  &:before {
    content: $icon-orion-logo-path49;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path50 {
  &:before {
    content: $icon-orion-logo-path50;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path51 {
  &:before {
    content: $icon-orion-logo-path51;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path52 {
  &:before {
    content: $icon-orion-logo-path52;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path53 {
  &:before {
    content: $icon-orion-logo-path53;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path54 {
  &:before {
    content: $icon-orion-logo-path54;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path55 {
  &:before {
    content: $icon-orion-logo-path55;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path56 {
  &:before {
    content: $icon-orion-logo-path56;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path57 {
  &:before {
    content: $icon-orion-logo-path57;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path58 {
  &:before {
    content: $icon-orion-logo-path58;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path59 {
  &:before {
    content: $icon-orion-logo-path59;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path60 {
  &:before {
    content: $icon-orion-logo-path60;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path61 {
  &:before {
    content: $icon-orion-logo-path61;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path62 {
  &:before {
    content: $icon-orion-logo-path62;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path63 {
  &:before {
    content: $icon-orion-logo-path63;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path64 {
  &:before {
    content: $icon-orion-logo-path64;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path65 {
  &:before {
    content: $icon-orion-logo-path65;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path66 {
  &:before {
    content: $icon-orion-logo-path66;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path67 {
  &:before {
    content: $icon-orion-logo-path67;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path68 {
  &:before {
    content: $icon-orion-logo-path68;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path69 {
  &:before {
    content: $icon-orion-logo-path69;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path70 {
  &:before {
    content: $icon-orion-logo-path70;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path71 {
  &:before {
    content: $icon-orion-logo-path71;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path72 {
  &:before {
    content: $icon-orion-logo-path72;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path73 {
  &:before {
    content: $icon-orion-logo-path73;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path74 {
  &:before {
    content: $icon-orion-logo-path74;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path75 {
  &:before {
    content: $icon-orion-logo-path75;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path76 {
  &:before {
    content: $icon-orion-logo-path76;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path77 {
  &:before {
    content: $icon-orion-logo-path77;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path78 {
  &:before {
    content: $icon-orion-logo-path78;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path79 {
  &:before {
    content: $icon-orion-logo-path79;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path80 {
  &:before {
    content: $icon-orion-logo-path80;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path81 {
  &:before {
    content: $icon-orion-logo-path81;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path82 {
  &:before {
    content: $icon-orion-logo-path82;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path83 {
  &:before {
    content: $icon-orion-logo-path83;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path84 {
  &:before {
    content: $icon-orion-logo-path84;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path85 {
  &:before {
    content: $icon-orion-logo-path85;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path86 {
  &:before {
    content: $icon-orion-logo-path86;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path87 {
  &:before {
    content: $icon-orion-logo-path87;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path88 {
  &:before {
    content: $icon-orion-logo-path88;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path89 {
  &:before {
    content: $icon-orion-logo-path89;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path90 {
  &:before {
    content: $icon-orion-logo-path90;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path91 {
  &:before {
    content: $icon-orion-logo-path91;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path92 {
  &:before {
    content: $icon-orion-logo-path92;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path93 {
  &:before {
    content: $icon-orion-logo-path93;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path94 {
  &:before {
    content: $icon-orion-logo-path94;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path95 {
  &:before {
    content: $icon-orion-logo-path95;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path96 {
  &:before {
    content: $icon-orion-logo-path96;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path97 {
  &:before {
    content: $icon-orion-logo-path97;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path98 {
  &:before {
    content: $icon-orion-logo-path98;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path99 {
  &:before {
    content: $icon-orion-logo-path99;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path100 {
  &:before {
    content: $icon-orion-logo-path100;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path101 {
  &:before {
    content: $icon-orion-logo-path101;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path102 {
  &:before {
    content: $icon-orion-logo-path102;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path103 {
  &:before {
    content: $icon-orion-logo-path103;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path104 {
  &:before {
    content: $icon-orion-logo-path104;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path105 {
  &:before {
    content: $icon-orion-logo-path105;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path106 {
  &:before {
    content: $icon-orion-logo-path106;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path107 {
  &:before {
    content: $icon-orion-logo-path107;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path108 {
  &:before {
    content: $icon-orion-logo-path108;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path109 {
  &:before {
    content: $icon-orion-logo-path109;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path110 {
  &:before {
    content: $icon-orion-logo-path110;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path111 {
  &:before {
    content: $icon-orion-logo-path111;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path112 {
  &:before {
    content: $icon-orion-logo-path112;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path113 {
  &:before {
    content: $icon-orion-logo-path113;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path114 {
  &:before {
    content: $icon-orion-logo-path114;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path115 {
  &:before {
    content: $icon-orion-logo-path115;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path116 {
  &:before {
    content: $icon-orion-logo-path116;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path117 {
  &:before {
    content: $icon-orion-logo-path117;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path118 {
  &:before {
    content: $icon-orion-logo-path118;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path119 {
  &:before {
    content: $icon-orion-logo-path119;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path120 {
  &:before {
    content: $icon-orion-logo-path120;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path121 {
  &:before {
    content: $icon-orion-logo-path121;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path122 {
  &:before {
    content: $icon-orion-logo-path122;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path123 {
  &:before {
    content: $icon-orion-logo-path123;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path124 {
  &:before {
    content: $icon-orion-logo-path124;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path125 {
  &:before {
    content: $icon-orion-logo-path125;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path126 {
  &:before {
    content: $icon-orion-logo-path126;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path127 {
  &:before {
    content: $icon-orion-logo-path127;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path128 {
  &:before {
    content: $icon-orion-logo-path128;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path129 {
  &:before {
    content: $icon-orion-logo-path129;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path130 {
  &:before {
    content: $icon-orion-logo-path130;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path131 {
  &:before {
    content: $icon-orion-logo-path131;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path132 {
  &:before {
    content: $icon-orion-logo-path132;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path133 {
  &:before {
    content: $icon-orion-logo-path133;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path134 {
  &:before {
    content: $icon-orion-logo-path134;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path135 {
  &:before {
    content: $icon-orion-logo-path135;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path136 {
  &:before {
    content: $icon-orion-logo-path136;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path137 {
  &:before {
    content: $icon-orion-logo-path137;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path138 {
  &:before {
    content: $icon-orion-logo-path138;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path139 {
  &:before {
    content: $icon-orion-logo-path139;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path140 {
  &:before {
    content: $icon-orion-logo-path140;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path141 {
  &:before {
    content: $icon-orion-logo-path141;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path142 {
  &:before {
    content: $icon-orion-logo-path142;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path143 {
  &:before {
    content: $icon-orion-logo-path143;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path144 {
  &:before {
    content: $icon-orion-logo-path144;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path145 {
  &:before {
    content: $icon-orion-logo-path145;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path146 {
  &:before {
    content: $icon-orion-logo-path146;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path147 {
  &:before {
    content: $icon-orion-logo-path147;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path148 {
  &:before {
    content: $icon-orion-logo-path148;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path149 {
  &:before {
    content: $icon-orion-logo-path149;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path150 {
  &:before {
    content: $icon-orion-logo-path150;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path151 {
  &:before {
    content: $icon-orion-logo-path151;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path152 {
  &:before {
    content: $icon-orion-logo-path152;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path153 {
  &:before {
    content: $icon-orion-logo-path153;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path154 {
  &:before {
    content: $icon-orion-logo-path154;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path155 {
  &:before {
    content: $icon-orion-logo-path155;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path156 {
  &:before {
    content: $icon-orion-logo-path156;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path157 {
  &:before {
    content: $icon-orion-logo-path157;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path158 {
  &:before {
    content: $icon-orion-logo-path158;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path159 {
  &:before {
    content: $icon-orion-logo-path159;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path160 {
  &:before {
    content: $icon-orion-logo-path160;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path161 {
  &:before {
    content: $icon-orion-logo-path161;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path162 {
  &:before {
    content: $icon-orion-logo-path162;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path163 {
  &:before {
    content: $icon-orion-logo-path163;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path164 {
  &:before {
    content: $icon-orion-logo-path164;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path165 {
  &:before {
    content: $icon-orion-logo-path165;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path166 {
  &:before {
    content: $icon-orion-logo-path166;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path167 {
  &:before {
    content: $icon-orion-logo-path167;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path168 {
  &:before {
    content: $icon-orion-logo-path168;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path169 {
  &:before {
    content: $icon-orion-logo-path169;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path170 {
  &:before {
    content: $icon-orion-logo-path170;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path171 {
  &:before {
    content: $icon-orion-logo-path171;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path172 {
  &:before {
    content: $icon-orion-logo-path172;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path173 {
  &:before {
    content: $icon-orion-logo-path173;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path174 {
  &:before {
    content: $icon-orion-logo-path174;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path175 {
  &:before {
    content: $icon-orion-logo-path175;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path176 {
  &:before {
    content: $icon-orion-logo-path176;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path177 {
  &:before {
    content: $icon-orion-logo-path177;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path178 {
  &:before {
    content: $icon-orion-logo-path178;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path179 {
  &:before {
    content: $icon-orion-logo-path179;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path180 {
  &:before {
    content: $icon-orion-logo-path180;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path181 {
  &:before {
    content: $icon-orion-logo-path181;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path182 {
  &:before {
    content: $icon-orion-logo-path182;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path183 {
  &:before {
    content: $icon-orion-logo-path183;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path184 {
  &:before {
    content: $icon-orion-logo-path184;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path185 {
  &:before {
    content: $icon-orion-logo-path185;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path186 {
  &:before {
    content: $icon-orion-logo-path186;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path187 {
  &:before {
    content: $icon-orion-logo-path187;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path188 {
  &:before {
    content: $icon-orion-logo-path188;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path189 {
  &:before {
    content: $icon-orion-logo-path189;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path190 {
  &:before {
    content: $icon-orion-logo-path190;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path191 {
  &:before {
    content: $icon-orion-logo-path191;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path192 {
  &:before {
    content: $icon-orion-logo-path192;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path193 {
  &:before {
    content: $icon-orion-logo-path193;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path194 {
  &:before {
    content: $icon-orion-logo-path194;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path195 {
  &:before {
    content: $icon-orion-logo-path195;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path196 {
  &:before {
    content: $icon-orion-logo-path196;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path197 {
  &:before {
    content: $icon-orion-logo-path197;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path198 {
  &:before {
    content: $icon-orion-logo-path198;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path199 {
  &:before {
    content: $icon-orion-logo-path199;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path200 {
  &:before {
    content: $icon-orion-logo-path200;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path201 {
  &:before {
    content: $icon-orion-logo-path201;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path202 {
  &:before {
    content: $icon-orion-logo-path202;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path203 {
  &:before {
    content: $icon-orion-logo-path203;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path204 {
  &:before {
    content: $icon-orion-logo-path204;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path205 {
  &:before {
    content: $icon-orion-logo-path205;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path206 {
  &:before {
    content: $icon-orion-logo-path206;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path207 {
  &:before {
    content: $icon-orion-logo-path207;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path208 {
  &:before {
    content: $icon-orion-logo-path208;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path209 {
  &:before {
    content: $icon-orion-logo-path209;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path210 {
  &:before {
    content: $icon-orion-logo-path210;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path211 {
  &:before {
    content: $icon-orion-logo-path211;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path212 {
  &:before {
    content: $icon-orion-logo-path212;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path213 {
  &:before {
    content: $icon-orion-logo-path213;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path214 {
  &:before {
    content: $icon-orion-logo-path214;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path215 {
  &:before {
    content: $icon-orion-logo-path215;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path216 {
  &:before {
    content: $icon-orion-logo-path216;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path217 {
  &:before {
    content: $icon-orion-logo-path217;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path218 {
  &:before {
    content: $icon-orion-logo-path218;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path219 {
  &:before {
    content: $icon-orion-logo-path219;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path220 {
  &:before {
    content: $icon-orion-logo-path220;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path221 {
  &:before {
    content: $icon-orion-logo-path221;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path222 {
  &:before {
    content: $icon-orion-logo-path222;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path223 {
  &:before {
    content: $icon-orion-logo-path223;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path224 {
  &:before {
    content: $icon-orion-logo-path224;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path225 {
  &:before {
    content: $icon-orion-logo-path225;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path226 {
  &:before {
    content: $icon-orion-logo-path226;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path227 {
  &:before {
    content: $icon-orion-logo-path227;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path228 {
  &:before {
    content: $icon-orion-logo-path228;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path229 {
  &:before {
    content: $icon-orion-logo-path229;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path230 {
  &:before {
    content: $icon-orion-logo-path230;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path231 {
  &:before {
    content: $icon-orion-logo-path231;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path232 {
  &:before {
    content: $icon-orion-logo-path232;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path233 {
  &:before {
    content: $icon-orion-logo-path233;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path234 {
  &:before {
    content: $icon-orion-logo-path234;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path235 {
  &:before {
    content: $icon-orion-logo-path235;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path236 {
  &:before {
    content: $icon-orion-logo-path236;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path237 {
  &:before {
    content: $icon-orion-logo-path237;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path238 {
  &:before {
    content: $icon-orion-logo-path238;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path239 {
  &:before {
    content: $icon-orion-logo-path239;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path240 {
  &:before {
    content: $icon-orion-logo-path240;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path241 {
  &:before {
    content: $icon-orion-logo-path241;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path242 {
  &:before {
    content: $icon-orion-logo-path242;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path243 {
  &:before {
    content: $icon-orion-logo-path243;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path244 {
  &:before {
    content: $icon-orion-logo-path244;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path245 {
  &:before {
    content: $icon-orion-logo-path245;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path246 {
  &:before {
    content: $icon-orion-logo-path246;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path247 {
  &:before {
    content: $icon-orion-logo-path247;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path248 {
  &:before {
    content: $icon-orion-logo-path248;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path249 {
  &:before {
    content: $icon-orion-logo-path249;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path250 {
  &:before {
    content: $icon-orion-logo-path250;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path251 {
  &:before {
    content: $icon-orion-logo-path251;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path252 {
  &:before {
    content: $icon-orion-logo-path252;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path253 {
  &:before {
    content: $icon-orion-logo-path253;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path254 {
  &:before {
    content: $icon-orion-logo-path254;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path255 {
  &:before {
    content: $icon-orion-logo-path255;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path256 {
  &:before {
    content: $icon-orion-logo-path256;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path257 {
  &:before {
    content: $icon-orion-logo-path257;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path258 {
  &:before {
    content: $icon-orion-logo-path258;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path259 {
  &:before {
    content: $icon-orion-logo-path259;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path260 {
  &:before {
    content: $icon-orion-logo-path260;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path261 {
  &:before {
    content: $icon-orion-logo-path261;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path262 {
  &:before {
    content: $icon-orion-logo-path262;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path263 {
  &:before {
    content: $icon-orion-logo-path263;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path264 {
  &:before {
    content: $icon-orion-logo-path264;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path265 {
  &:before {
    content: $icon-orion-logo-path265;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path266 {
  &:before {
    content: $icon-orion-logo-path266;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path267 {
  &:before {
    content: $icon-orion-logo-path267;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path268 {
  &:before {
    content: $icon-orion-logo-path268;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path269 {
  &:before {
    content: $icon-orion-logo-path269;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path270 {
  &:before {
    content: $icon-orion-logo-path270;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path271 {
  &:before {
    content: $icon-orion-logo-path271;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path272 {
  &:before {
    content: $icon-orion-logo-path272;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path273 {
  &:before {
    content: $icon-orion-logo-path273;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path274 {
  &:before {
    content: $icon-orion-logo-path274;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path275 {
  &:before {
    content: $icon-orion-logo-path275;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path276 {
  &:before {
    content: $icon-orion-logo-path276;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path277 {
  &:before {
    content: $icon-orion-logo-path277;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path278 {
  &:before {
    content: $icon-orion-logo-path278;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path279 {
  &:before {
    content: $icon-orion-logo-path279;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path280 {
  &:before {
    content: $icon-orion-logo-path280;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path281 {
  &:before {
    content: $icon-orion-logo-path281;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path282 {
  &:before {
    content: $icon-orion-logo-path282;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path283 {
  &:before {
    content: $icon-orion-logo-path283;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path284 {
  &:before {
    content: $icon-orion-logo-path284;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path285 {
  &:before {
    content: $icon-orion-logo-path285;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path286 {
  &:before {
    content: $icon-orion-logo-path286;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path287 {
  &:before {
    content: $icon-orion-logo-path287;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path288 {
  &:before {
    content: $icon-orion-logo-path288;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path289 {
  &:before {
    content: $icon-orion-logo-path289;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path290 {
  &:before {
    content: $icon-orion-logo-path290;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path291 {
  &:before {
    content: $icon-orion-logo-path291;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path292 {
  &:before {
    content: $icon-orion-logo-path292;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path293 {
  &:before {
    content: $icon-orion-logo-path293;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path294 {
  &:before {
    content: $icon-orion-logo-path294;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path295 {
  &:before {
    content: $icon-orion-logo-path295;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path296 {
  &:before {
    content: $icon-orion-logo-path296;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path297 {
  &:before {
    content: $icon-orion-logo-path297;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path298 {
  &:before {
    content: $icon-orion-logo-path298;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path299 {
  &:before {
    content: $icon-orion-logo-path299;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path300 {
  &:before {
    content: $icon-orion-logo-path300;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path301 {
  &:before {
    content: $icon-orion-logo-path301;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path302 {
  &:before {
    content: $icon-orion-logo-path302;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path303 {
  &:before {
    content: $icon-orion-logo-path303;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path304 {
  &:before {
    content: $icon-orion-logo-path304;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path305 {
  &:before {
    content: $icon-orion-logo-path305;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path306 {
  &:before {
    content: $icon-orion-logo-path306;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path307 {
  &:before {
    content: $icon-orion-logo-path307;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path308 {
  &:before {
    content: $icon-orion-logo-path308;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path309 {
  &:before {
    content: $icon-orion-logo-path309;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path310 {
  &:before {
    content: $icon-orion-logo-path310;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path311 {
  &:before {
    content: $icon-orion-logo-path311;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path312 {
  &:before {
    content: $icon-orion-logo-path312;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path313 {
  &:before {
    content: $icon-orion-logo-path313;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path314 {
  &:before {
    content: $icon-orion-logo-path314;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path315 {
  &:before {
    content: $icon-orion-logo-path315;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path316 {
  &:before {
    content: $icon-orion-logo-path316;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path317 {
  &:before {
    content: $icon-orion-logo-path317;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path318 {
  &:before {
    content: $icon-orion-logo-path318;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path319 {
  &:before {
    content: $icon-orion-logo-path319;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path320 {
  &:before {
    content: $icon-orion-logo-path320;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path321 {
  &:before {
    content: $icon-orion-logo-path321;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path322 {
  &:before {
    content: $icon-orion-logo-path322;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path323 {
  &:before {
    content: $icon-orion-logo-path323;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path324 {
  &:before {
    content: $icon-orion-logo-path324;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path325 {
  &:before {
    content: $icon-orion-logo-path325;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path326 {
  &:before {
    content: $icon-orion-logo-path326;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path327 {
  &:before {
    content: $icon-orion-logo-path327;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path328 {
  &:before {
    content: $icon-orion-logo-path328;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path329 {
  &:before {
    content: $icon-orion-logo-path329;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path330 {
  &:before {
    content: $icon-orion-logo-path330;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path331 {
  &:before {
    content: $icon-orion-logo-path331;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path332 {
  &:before {
    content: $icon-orion-logo-path332;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path333 {
  &:before {
    content: $icon-orion-logo-path333;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path334 {
  &:before {
    content: $icon-orion-logo-path334;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path335 {
  &:before {
    content: $icon-orion-logo-path335;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path336 {
  &:before {
    content: $icon-orion-logo-path336;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path337 {
  &:before {
    content: $icon-orion-logo-path337;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path338 {
  &:before {
    content: $icon-orion-logo-path338;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path339 {
  &:before {
    content: $icon-orion-logo-path339;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path340 {
  &:before {
    content: $icon-orion-logo-path340;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path341 {
  &:before {
    content: $icon-orion-logo-path341;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path342 {
  &:before {
    content: $icon-orion-logo-path342;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path343 {
  &:before {
    content: $icon-orion-logo-path343;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path344 {
  &:before {
    content: $icon-orion-logo-path344;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path345 {
  &:before {
    content: $icon-orion-logo-path345;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path346 {
  &:before {
    content: $icon-orion-logo-path346;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path347 {
  &:before {
    content: $icon-orion-logo-path347;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path348 {
  &:before {
    content: $icon-orion-logo-path348;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path349 {
  &:before {
    content: $icon-orion-logo-path349;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path350 {
  &:before {
    content: $icon-orion-logo-path350;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path351 {
  &:before {
    content: $icon-orion-logo-path351;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path352 {
  &:before {
    content: $icon-orion-logo-path352;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path353 {
  &:before {
    content: $icon-orion-logo-path353;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path354 {
  &:before {
    content: $icon-orion-logo-path354;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path355 {
  &:before {
    content: $icon-orion-logo-path355;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path356 {
  &:before {
    content: $icon-orion-logo-path356;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path357 {
  &:before {
    content: $icon-orion-logo-path357;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path358 {
  &:before {
    content: $icon-orion-logo-path358;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path359 {
  &:before {
    content: $icon-orion-logo-path359;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path360 {
  &:before {
    content: $icon-orion-logo-path360;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path361 {
  &:before {
    content: $icon-orion-logo-path361;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path362 {
  &:before {
    content: $icon-orion-logo-path362;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path363 {
  &:before {
    content: $icon-orion-logo-path363;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path364 {
  &:before {
    content: $icon-orion-logo-path364;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path365 {
  &:before {
    content: $icon-orion-logo-path365;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path366 {
  &:before {
    content: $icon-orion-logo-path366;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path367 {
  &:before {
    content: $icon-orion-logo-path367;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path368 {
  &:before {
    content: $icon-orion-logo-path368;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path369 {
  &:before {
    content: $icon-orion-logo-path369;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path370 {
  &:before {
    content: $icon-orion-logo-path370;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path371 {
  &:before {
    content: $icon-orion-logo-path371;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path372 {
  &:before {
    content: $icon-orion-logo-path372;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path373 {
  &:before {
    content: $icon-orion-logo-path373;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path374 {
  &:before {
    content: $icon-orion-logo-path374;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path375 {
  &:before {
    content: $icon-orion-logo-path375;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path376 {
  &:before {
    content: $icon-orion-logo-path376;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path377 {
  &:before {
    content: $icon-orion-logo-path377;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path378 {
  &:before {
    content: $icon-orion-logo-path378;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path379 {
  &:before {
    content: $icon-orion-logo-path379;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path380 {
  &:before {
    content: $icon-orion-logo-path380;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path381 {
  &:before {
    content: $icon-orion-logo-path381;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path382 {
  &:before {
    content: $icon-orion-logo-path382;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path383 {
  &:before {
    content: $icon-orion-logo-path383;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path384 {
  &:before {
    content: $icon-orion-logo-path384;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path385 {
  &:before {
    content: $icon-orion-logo-path385;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path386 {
  &:before {
    content: $icon-orion-logo-path386;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path387 {
  &:before {
    content: $icon-orion-logo-path387;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path388 {
  &:before {
    content: $icon-orion-logo-path388;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path389 {
  &:before {
    content: $icon-orion-logo-path389;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path390 {
  &:before {
    content: $icon-orion-logo-path390;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path391 {
  &:before {
    content: $icon-orion-logo-path391;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path392 {
  &:before {
    content: $icon-orion-logo-path392;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path393 {
  &:before {
    content: $icon-orion-logo-path393;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path394 {
  &:before {
    content: $icon-orion-logo-path394;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path395 {
  &:before {
    content: $icon-orion-logo-path395;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path396 {
  &:before {
    content: $icon-orion-logo-path396;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path397 {
  &:before {
    content: $icon-orion-logo-path397;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path398 {
  &:before {
    content: $icon-orion-logo-path398;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path399 {
  &:before {
    content: $icon-orion-logo-path399;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path400 {
  &:before {
    content: $icon-orion-logo-path400;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path401 {
  &:before {
    content: $icon-orion-logo-path401;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path402 {
  &:before {
    content: $icon-orion-logo-path402;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path403 {
  &:before {
    content: $icon-orion-logo-path403;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path404 {
  &:before {
    content: $icon-orion-logo-path404;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path405 {
  &:before {
    content: $icon-orion-logo-path405;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path406 {
  &:before {
    content: $icon-orion-logo-path406;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path407 {
  &:before {
    content: $icon-orion-logo-path407;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path408 {
  &:before {
    content: $icon-orion-logo-path408;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path409 {
  &:before {
    content: $icon-orion-logo-path409;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path410 {
  &:before {
    content: $icon-orion-logo-path410;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path411 {
  &:before {
    content: $icon-orion-logo-path411;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path412 {
  &:before {
    content: $icon-orion-logo-path412;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path413 {
  &:before {
    content: $icon-orion-logo-path413;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path414 {
  &:before {
    content: $icon-orion-logo-path414;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path415 {
  &:before {
    content: $icon-orion-logo-path415;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path416 {
  &:before {
    content: $icon-orion-logo-path416;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path417 {
  &:before {
    content: $icon-orion-logo-path417;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path418 {
  &:before {
    content: $icon-orion-logo-path418;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path419 {
  &:before {
    content: $icon-orion-logo-path419;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path420 {
  &:before {
    content: $icon-orion-logo-path420;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path421 {
  &:before {
    content: $icon-orion-logo-path421;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path422 {
  &:before {
    content: $icon-orion-logo-path422;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path423 {
  &:before {
    content: $icon-orion-logo-path423;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path424 {
  &:before {
    content: $icon-orion-logo-path424;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path425 {
  &:before {
    content: $icon-orion-logo-path425;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path426 {
  &:before {
    content: $icon-orion-logo-path426;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path427 {
  &:before {
    content: $icon-orion-logo-path427;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path428 {
  &:before {
    content: $icon-orion-logo-path428;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path429 {
  &:before {
    content: $icon-orion-logo-path429;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path430 {
  &:before {
    content: $icon-orion-logo-path430;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path431 {
  &:before {
    content: $icon-orion-logo-path431;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path432 {
  &:before {
    content: $icon-orion-logo-path432;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path433 {
  &:before {
    content: $icon-orion-logo-path433;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path434 {
  &:before {
    content: $icon-orion-logo-path434;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path435 {
  &:before {
    content: $icon-orion-logo-path435;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path436 {
  &:before {
    content: $icon-orion-logo-path436;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path437 {
  &:before {
    content: $icon-orion-logo-path437;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path438 {
  &:before {
    content: $icon-orion-logo-path438;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path439 {
  &:before {
    content: $icon-orion-logo-path439;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path440 {
  &:before {
    content: $icon-orion-logo-path440;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path441 {
  &:before {
    content: $icon-orion-logo-path441;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path442 {
  &:before {
    content: $icon-orion-logo-path442;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path443 {
  &:before {
    content: $icon-orion-logo-path443;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path444 {
  &:before {
    content: $icon-orion-logo-path444;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path445 {
  &:before {
    content: $icon-orion-logo-path445;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path446 {
  &:before {
    content: $icon-orion-logo-path446;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path447 {
  &:before {
    content: $icon-orion-logo-path447;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path448 {
  &:before {
    content: $icon-orion-logo-path448;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path449 {
  &:before {
    content: $icon-orion-logo-path449;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path450 {
  &:before {
    content: $icon-orion-logo-path450;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path451 {
  &:before {
    content: $icon-orion-logo-path451;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path452 {
  &:before {
    content: $icon-orion-logo-path452;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path453 {
  &:before {
    content: $icon-orion-logo-path453;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path454 {
  &:before {
    content: $icon-orion-logo-path454;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path455 {
  &:before {
    content: $icon-orion-logo-path455;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path456 {
  &:before {
    content: $icon-orion-logo-path456;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path457 {
  &:before {
    content: $icon-orion-logo-path457;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path458 {
  &:before {
    content: $icon-orion-logo-path458;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path459 {
  &:before {
    content: $icon-orion-logo-path459;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path460 {
  &:before {
    content: $icon-orion-logo-path460;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path461 {
  &:before {
    content: $icon-orion-logo-path461;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path462 {
  &:before {
    content: $icon-orion-logo-path462;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path463 {
  &:before {
    content: $icon-orion-logo-path463;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path464 {
  &:before {
    content: $icon-orion-logo-path464;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path465 {
  &:before {
    content: $icon-orion-logo-path465;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path466 {
  &:before {
    content: $icon-orion-logo-path466;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path467 {
  &:before {
    content: $icon-orion-logo-path467;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path468 {
  &:before {
    content: $icon-orion-logo-path468;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path469 {
  &:before {
    content: $icon-orion-logo-path469;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path470 {
  &:before {
    content: $icon-orion-logo-path470;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path471 {
  &:before {
    content: $icon-orion-logo-path471;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path472 {
  &:before {
    content: $icon-orion-logo-path472;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path473 {
  &:before {
    content: $icon-orion-logo-path473;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path474 {
  &:before {
    content: $icon-orion-logo-path474;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path475 {
  &:before {
    content: $icon-orion-logo-path475;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path476 {
  &:before {
    content: $icon-orion-logo-path476;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path477 {
  &:before {
    content: $icon-orion-logo-path477;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path478 {
  &:before {
    content: $icon-orion-logo-path478;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path479 {
  &:before {
    content: $icon-orion-logo-path479;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path480 {
  &:before {
    content: $icon-orion-logo-path480;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path481 {
  &:before {
    content: $icon-orion-logo-path481;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path482 {
  &:before {
    content: $icon-orion-logo-path482;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path483 {
  &:before {
    content: $icon-orion-logo-path483;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path484 {
  &:before {
    content: $icon-orion-logo-path484;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path485 {
  &:before {
    content: $icon-orion-logo-path485;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path486 {
  &:before {
    content: $icon-orion-logo-path486;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path487 {
  &:before {
    content: $icon-orion-logo-path487;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path488 {
  &:before {
    content: $icon-orion-logo-path488;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path489 {
  &:before {
    content: $icon-orion-logo-path489;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path490 {
  &:before {
    content: $icon-orion-logo-path490;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path491 {
  &:before {
    content: $icon-orion-logo-path491;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path492 {
  &:before {
    content: $icon-orion-logo-path492;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path493 {
  &:before {
    content: $icon-orion-logo-path493;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path494 {
  &:before {
    content: $icon-orion-logo-path494;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path495 {
  &:before {
    content: $icon-orion-logo-path495;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path496 {
  &:before {
    content: $icon-orion-logo-path496;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path497 {
  &:before {
    content: $icon-orion-logo-path497;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path498 {
  &:before {
    content: $icon-orion-logo-path498;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path499 {
  &:before {
    content: $icon-orion-logo-path499;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path500 {
  &:before {
    content: $icon-orion-logo-path500;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path501 {
  &:before {
    content: $icon-orion-logo-path501;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path502 {
  &:before {
    content: $icon-orion-logo-path502;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path503 {
  &:before {
    content: $icon-orion-logo-path503;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path504 {
  &:before {
    content: $icon-orion-logo-path504;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path505 {
  &:before {
    content: $icon-orion-logo-path505;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path506 {
  &:before {
    content: $icon-orion-logo-path506;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path507 {
  &:before {
    content: $icon-orion-logo-path507;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path508 {
  &:before {
    content: $icon-orion-logo-path508;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path509 {
  &:before {
    content: $icon-orion-logo-path509;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path510 {
  &:before {
    content: $icon-orion-logo-path510;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path511 {
  &:before {
    content: $icon-orion-logo-path511;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path512 {
  &:before {
    content: $icon-orion-logo-path512;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path513 {
  &:before {
    content: $icon-orion-logo-path513;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path514 {
  &:before {
    content: $icon-orion-logo-path514;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path515 {
  &:before {
    content: $icon-orion-logo-path515;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path516 {
  &:before {
    content: $icon-orion-logo-path516;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path517 {
  &:before {
    content: $icon-orion-logo-path517;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path518 {
  &:before {
    content: $icon-orion-logo-path518;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path519 {
  &:before {
    content: $icon-orion-logo-path519;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path520 {
  &:before {
    content: $icon-orion-logo-path520;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path521 {
  &:before {
    content: $icon-orion-logo-path521;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path522 {
  &:before {
    content: $icon-orion-logo-path522;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path523 {
  &:before {
    content: $icon-orion-logo-path523;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path524 {
  &:before {
    content: $icon-orion-logo-path524;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path525 {
  &:before {
    content: $icon-orion-logo-path525;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path526 {
  &:before {
    content: $icon-orion-logo-path526;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path527 {
  &:before {
    content: $icon-orion-logo-path527;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path528 {
  &:before {
    content: $icon-orion-logo-path528;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path529 {
  &:before {
    content: $icon-orion-logo-path529;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path530 {
  &:before {
    content: $icon-orion-logo-path530;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path531 {
  &:before {
    content: $icon-orion-logo-path531;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path532 {
  &:before {
    content: $icon-orion-logo-path532;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path533 {
  &:before {
    content: $icon-orion-logo-path533;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path534 {
  &:before {
    content: $icon-orion-logo-path534;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path535 {
  &:before {
    content: $icon-orion-logo-path535;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path536 {
  &:before {
    content: $icon-orion-logo-path536;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path537 {
  &:before {
    content: $icon-orion-logo-path537;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path538 {
  &:before {
    content: $icon-orion-logo-path538;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path539 {
  &:before {
    content: $icon-orion-logo-path539;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path540 {
  &:before {
    content: $icon-orion-logo-path540;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path541 {
  &:before {
    content: $icon-orion-logo-path541;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path542 {
  &:before {
    content: $icon-orion-logo-path542;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path543 {
  &:before {
    content: $icon-orion-logo-path543;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path544 {
  &:before {
    content: $icon-orion-logo-path544;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path545 {
  &:before {
    content: $icon-orion-logo-path545;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path546 {
  &:before {
    content: $icon-orion-logo-path546;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path547 {
  &:before {
    content: $icon-orion-logo-path547;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path548 {
  &:before {
    content: $icon-orion-logo-path548;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path549 {
  &:before {
    content: $icon-orion-logo-path549;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path550 {
  &:before {
    content: $icon-orion-logo-path550;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path551 {
  &:before {
    content: $icon-orion-logo-path551;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path552 {
  &:before {
    content: $icon-orion-logo-path552;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path553 {
  &:before {
    content: $icon-orion-logo-path553;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path554 {
  &:before {
    content: $icon-orion-logo-path554;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path555 {
  &:before {
    content: $icon-orion-logo-path555;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path556 {
  &:before {
    content: $icon-orion-logo-path556;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path557 {
  &:before {
    content: $icon-orion-logo-path557;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path558 {
  &:before {
    content: $icon-orion-logo-path558;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path559 {
  &:before {
    content: $icon-orion-logo-path559;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path560 {
  &:before {
    content: $icon-orion-logo-path560;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path561 {
  &:before {
    content: $icon-orion-logo-path561;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path562 {
  &:before {
    content: $icon-orion-logo-path562;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path563 {
  &:before {
    content: $icon-orion-logo-path563;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path564 {
  &:before {
    content: $icon-orion-logo-path564;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path565 {
  &:before {
    content: $icon-orion-logo-path565;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path566 {
  &:before {
    content: $icon-orion-logo-path566;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path567 {
  &:before {
    content: $icon-orion-logo-path567;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path568 {
  &:before {
    content: $icon-orion-logo-path568;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path569 {
  &:before {
    content: $icon-orion-logo-path569;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path570 {
  &:before {
    content: $icon-orion-logo-path570;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path571 {
  &:before {
    content: $icon-orion-logo-path571;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path572 {
  &:before {
    content: $icon-orion-logo-path572;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path573 {
  &:before {
    content: $icon-orion-logo-path573;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path574 {
  &:before {
    content: $icon-orion-logo-path574;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path575 {
  &:before {
    content: $icon-orion-logo-path575;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path576 {
  &:before {
    content: $icon-orion-logo-path576;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path577 {
  &:before {
    content: $icon-orion-logo-path577;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path578 {
  &:before {
    content: $icon-orion-logo-path578;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path579 {
  &:before {
    content: $icon-orion-logo-path579;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path580 {
  &:before {
    content: $icon-orion-logo-path580;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path581 {
  &:before {
    content: $icon-orion-logo-path581;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path582 {
  &:before {
    content: $icon-orion-logo-path582;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path583 {
  &:before {
    content: $icon-orion-logo-path583;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path584 {
  &:before {
    content: $icon-orion-logo-path584;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path585 {
  &:before {
    content: $icon-orion-logo-path585;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path586 {
  &:before {
    content: $icon-orion-logo-path586;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path587 {
  &:before {
    content: $icon-orion-logo-path587;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path588 {
  &:before {
    content: $icon-orion-logo-path588;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path589 {
  &:before {
    content: $icon-orion-logo-path589;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path590 {
  &:before {
    content: $icon-orion-logo-path590;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path591 {
  &:before {
    content: $icon-orion-logo-path591;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path592 {
  &:before {
    content: $icon-orion-logo-path592;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path593 {
  &:before {
    content: $icon-orion-logo-path593;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path594 {
  &:before {
    content: $icon-orion-logo-path594;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path595 {
  &:before {
    content: $icon-orion-logo-path595;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path596 {
  &:before {
    content: $icon-orion-logo-path596;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path597 {
  &:before {
    content: $icon-orion-logo-path597;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path598 {
  &:before {
    content: $icon-orion-logo-path598;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path599 {
  &:before {
    content: $icon-orion-logo-path599;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path600 {
  &:before {
    content: $icon-orion-logo-path600;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path601 {
  &:before {
    content: $icon-orion-logo-path601;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path602 {
  &:before {
    content: $icon-orion-logo-path602;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path603 {
  &:before {
    content: $icon-orion-logo-path603;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path604 {
  &:before {
    content: $icon-orion-logo-path604;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path605 {
  &:before {
    content: $icon-orion-logo-path605;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path606 {
  &:before {
    content: $icon-orion-logo-path606;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path607 {
  &:before {
    content: $icon-orion-logo-path607;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path608 {
  &:before {
    content: $icon-orion-logo-path608;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path609 {
  &:before {
    content: $icon-orion-logo-path609;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path610 {
  &:before {
    content: $icon-orion-logo-path610;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path611 {
  &:before {
    content: $icon-orion-logo-path611;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path612 {
  &:before {
    content: $icon-orion-logo-path612;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path613 {
  &:before {
    content: $icon-orion-logo-path613;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path614 {
  &:before {
    content: $icon-orion-logo-path614;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path615 {
  &:before {
    content: $icon-orion-logo-path615;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path616 {
  &:before {
    content: $icon-orion-logo-path616;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path617 {
  &:before {
    content: $icon-orion-logo-path617;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path618 {
  &:before {
    content: $icon-orion-logo-path618;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path619 {
  &:before {
    content: $icon-orion-logo-path619;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path620 {
  &:before {
    content: $icon-orion-logo-path620;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path621 {
  &:before {
    content: $icon-orion-logo-path621;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path622 {
  &:before {
    content: $icon-orion-logo-path622;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path623 {
  &:before {
    content: $icon-orion-logo-path623;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path624 {
  &:before {
    content: $icon-orion-logo-path624;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path625 {
  &:before {
    content: $icon-orion-logo-path625;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path626 {
  &:before {
    content: $icon-orion-logo-path626;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path627 {
  &:before {
    content: $icon-orion-logo-path627;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path628 {
  &:before {
    content: $icon-orion-logo-path628;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path629 {
  &:before {
    content: $icon-orion-logo-path629;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path630 {
  &:before {
    content: $icon-orion-logo-path630;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path631 {
  &:before {
    content: $icon-orion-logo-path631;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path632 {
  &:before {
    content: $icon-orion-logo-path632;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path633 {
  &:before {
    content: $icon-orion-logo-path633;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path634 {
  &:before {
    content: $icon-orion-logo-path634;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path635 {
  &:before {
    content: $icon-orion-logo-path635;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path636 {
  &:before {
    content: $icon-orion-logo-path636;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path637 {
  &:before {
    content: $icon-orion-logo-path637;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path638 {
  &:before {
    content: $icon-orion-logo-path638;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path639 {
  &:before {
    content: $icon-orion-logo-path639;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path640 {
  &:before {
    content: $icon-orion-logo-path640;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path641 {
  &:before {
    content: $icon-orion-logo-path641;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path642 {
  &:before {
    content: $icon-orion-logo-path642;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path643 {
  &:before {
    content: $icon-orion-logo-path643;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path644 {
  &:before {
    content: $icon-orion-logo-path644;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path645 {
  &:before {
    content: $icon-orion-logo-path645;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path646 {
  &:before {
    content: $icon-orion-logo-path646;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path647 {
  &:before {
    content: $icon-orion-logo-path647;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path648 {
  &:before {
    content: $icon-orion-logo-path648;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path649 {
  &:before {
    content: $icon-orion-logo-path649;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path650 {
  &:before {
    content: $icon-orion-logo-path650;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path651 {
  &:before {
    content: $icon-orion-logo-path651;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path652 {
  &:before {
    content: $icon-orion-logo-path652;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path653 {
  &:before {
    content: $icon-orion-logo-path653;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path654 {
  &:before {
    content: $icon-orion-logo-path654;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path655 {
  &:before {
    content: $icon-orion-logo-path655;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path656 {
  &:before {
    content: $icon-orion-logo-path656;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path657 {
  &:before {
    content: $icon-orion-logo-path657;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path658 {
  &:before {
    content: $icon-orion-logo-path658;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path659 {
  &:before {
    content: $icon-orion-logo-path659;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path660 {
  &:before {
    content: $icon-orion-logo-path660;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path661 {
  &:before {
    content: $icon-orion-logo-path661;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path662 {
  &:before {
    content: $icon-orion-logo-path662;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path663 {
  &:before {
    content: $icon-orion-logo-path663;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path664 {
  &:before {
    content: $icon-orion-logo-path664;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path665 {
  &:before {
    content: $icon-orion-logo-path665;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path666 {
  &:before {
    content: $icon-orion-logo-path666;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path667 {
  &:before {
    content: $icon-orion-logo-path667;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path668 {
  &:before {
    content: $icon-orion-logo-path668;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path669 {
  &:before {
    content: $icon-orion-logo-path669;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path670 {
  &:before {
    content: $icon-orion-logo-path670;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path671 {
  &:before {
    content: $icon-orion-logo-path671;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path672 {
  &:before {
    content: $icon-orion-logo-path672;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path673 {
  &:before {
    content: $icon-orion-logo-path673;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path674 {
  &:before {
    content: $icon-orion-logo-path674;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path675 {
  &:before {
    content: $icon-orion-logo-path675;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path676 {
  &:before {
    content: $icon-orion-logo-path676;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path677 {
  &:before {
    content: $icon-orion-logo-path677;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path678 {
  &:before {
    content: $icon-orion-logo-path678;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path679 {
  &:before {
    content: $icon-orion-logo-path679;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path680 {
  &:before {
    content: $icon-orion-logo-path680;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path681 {
  &:before {
    content: $icon-orion-logo-path681;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path682 {
  &:before {
    content: $icon-orion-logo-path682;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path683 {
  &:before {
    content: $icon-orion-logo-path683;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path684 {
  &:before {
    content: $icon-orion-logo-path684;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path685 {
  &:before {
    content: $icon-orion-logo-path685;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path686 {
  &:before {
    content: $icon-orion-logo-path686;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path687 {
  &:before {
    content: $icon-orion-logo-path687;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path688 {
  &:before {
    content: $icon-orion-logo-path688;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path689 {
  &:before {
    content: $icon-orion-logo-path689;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);  
    opacity: 0.3;
  }
}
.icon-orion-logo .path690 {
  &:before {
    content: $icon-orion-logo-path690;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path691 {
  &:before {
    content: $icon-orion-logo-path691;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path692 {
  &:before {
    content: $icon-orion-logo-path692;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path693 {
  &:before {
    content: $icon-orion-logo-path693;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path694 {
  &:before {
    content: $icon-orion-logo-path694;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path695 {
  &:before {
    content: $icon-orion-logo-path695;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path696 {
  &:before {
    content: $icon-orion-logo-path696;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path697 {
  &:before {
    content: $icon-orion-logo-path697;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path698 {
  &:before {
    content: $icon-orion-logo-path698;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path699 {
  &:before {
    content: $icon-orion-logo-path699;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path700 {
  &:before {
    content: $icon-orion-logo-path700;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path701 {
  &:before {
    content: $icon-orion-logo-path701;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path702 {
  &:before {
    content: $icon-orion-logo-path702;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path703 {
  &:before {
    content: $icon-orion-logo-path703;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path704 {
  &:before {
    content: $icon-orion-logo-path704;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path705 {
  &:before {
    content: $icon-orion-logo-path705;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path706 {
  &:before {
    content: $icon-orion-logo-path706;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path707 {
  &:before {
    content: $icon-orion-logo-path707;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path708 {
  &:before {
    content: $icon-orion-logo-path708;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path709 {
  &:before {
    content: $icon-orion-logo-path709;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path710 {
  &:before {
    content: $icon-orion-logo-path710;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path711 {
  &:before {
    content: $icon-orion-logo-path711;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path712 {
  &:before {
    content: $icon-orion-logo-path712;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path713 {
  &:before {
    content: $icon-orion-logo-path713;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path714 {
  &:before {
    content: $icon-orion-logo-path714;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path715 {
  &:before {
    content: $icon-orion-logo-path715;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path716 {
  &:before {
    content: $icon-orion-logo-path716;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path717 {
  &:before {
    content: $icon-orion-logo-path717;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path718 {
  &:before {
    content: $icon-orion-logo-path718;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path719 {
  &:before {
    content: $icon-orion-logo-path719;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path720 {
  &:before {
    content: $icon-orion-logo-path720;  
    margin-left: -1em;  
    color: rgb(197, 197, 197);
  }
}
.icon-orion-logo .path721 {
  &:before {
    content: $icon-orion-logo-path721;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path722 {
  &:before {
    content: $icon-orion-logo-path722;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path723 {
  &:before {
    content: $icon-orion-logo-path723;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path724 {
  &:before {
    content: $icon-orion-logo-path724;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path725 {
  &:before {
    content: $icon-orion-logo-path725;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path726 {
  &:before {
    content: $icon-orion-logo-path726;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path727 {
  &:before {
    content: $icon-orion-logo-path727;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path728 {
  &:before {
    content: $icon-orion-logo-path728;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path729 {
  &:before {
    content: $icon-orion-logo-path729;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path730 {
  &:before {
    content: $icon-orion-logo-path730;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path731 {
  &:before {
    content: $icon-orion-logo-path731;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path732 {
  &:before {
    content: $icon-orion-logo-path732;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path733 {
  &:before {
    content: $icon-orion-logo-path733;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path734 {
  &:before {
    content: $icon-orion-logo-path734;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path735 {
  &:before {
    content: $icon-orion-logo-path735;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path736 {
  &:before {
    content: $icon-orion-logo-path736;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path737 {
  &:before {
    content: $icon-orion-logo-path737;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path738 {
  &:before {
    content: $icon-orion-logo-path738;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path739 {
  &:before {
    content: $icon-orion-logo-path739;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path740 {
  &:before {
    content: $icon-orion-logo-path740;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path741 {
  &:before {
    content: $icon-orion-logo-path741;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path742 {
  &:before {
    content: $icon-orion-logo-path742;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-orion-logo .path743 {
  &:before {
    content: $icon-orion-logo-path743;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-pencil-simple-line {
  &:before {
    content: $icon-pencil-simple-line; 
  }
}
.icon-phone-icon {
  &:before {
    content: $icon-phone-icon;     
    color: #bdbdbd;
  }
}
.icon-printer-icon {
  &:before {
    content: $icon-printer-icon;     
    color: #bdbdbd;
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked; 
  }
}
.icon-radio-uncheck {
  &:before {
    content: $icon-radio-uncheck;     
    color: #4f4f4f;
  }
}
.icon-search-white {
  &:before {
    content: $icon-search-white;     
    color: #fff;
  }
}
.icon-source-link {
  &:before {
    content: $icon-source-link;     
    color: #00adf0;
  }
}
.icon-success-tick .path1 {
  &:before {
    content: $icon-success-tick-path1;  
    color: rgb(25, 142, 87);
  }
}
.icon-success-tick .path2 {
  &:before {
    content: $icon-success-tick-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-success-tick-large {
  &:before {
    content: $icon-success-tick-large;     
    color: #00adf0;
  }
}
.icon-success-tick-white {
  &:before {
    content: $icon-success-tick-white;     
    color: #fff;
  }
}
.icon-switch-active .path1 {
  &:before {
    content: $icon-switch-active-path1;  
    color: rgb(224, 224, 224);
  }
}
.icon-switch-active .path2 {
  &:before {
    content: $icon-switch-active-path2;  
    margin-left: -1em;  
    color: rgb(8, 43, 69);
  }
}
.icon-switch-active .path3 {
  &:before {
    content: $icon-switch-active-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-switch-inactive .path1 {
  &:before {
    content: $icon-switch-inactive-path1;  
    color: rgb(224, 224, 224);
  }
}
.icon-switch-inactive .path2 {
  &:before {
    content: $icon-switch-inactive-path2;  
    margin-left: -1em;  
    color: rgb(130, 130, 130);
  }
}
.icon-switch-inactive .path3 {
  &:before {
    content: $icon-switch-inactive-path3;  
    margin-left: -1em;  
    color: rgb(51, 51, 51);
  }
}
.icon-total-orders .path1 {
  &:before {
    content: $icon-total-orders-path1;  
    color: rgb(200, 230, 201);
  }
}
.icon-total-orders .path2 {
  &:before {
    content: $icon-total-orders-path2;  
    margin-left: -1em;  
    color: rgb(56, 142, 60);
  }
}
.icon-total-tools .path1 {
  &:before {
    content: $icon-total-tools-path1;  
    color: rgb(223, 244, 255);
  }
}
.icon-total-tools .path2 {
  &:before {
    content: $icon-total-tools-path2;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-total-tools .path3 {
  &:before {
    content: $icon-total-tools-path3;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-total-tools .path4 {
  &:before {
    content: $icon-total-tools-path4;  
    margin-left: -1em;  
    color: rgb(0, 173, 240);
  }
}
.icon-upload-simple {
  &:before {
    content: $icon-upload-simple;     
    color: #00adf0;
  }
}
.icon-upsell {
  &:before {
    content: $icon-upsell;     
    color: #00adf0;
  }
}
.icon-upsell-icon {
  &:before {
    content: $icon-upsell-icon; 
  }
}
.icon-user-switch {
  &:before {
    content: $icon-user-switch;     
    color: #4f4f4f;
  }
}
.icon-user-request {
  &:before {
    content: $icon-user-request;     
    color: #00adf0;
  }
}
.icon-user-request-approval-pending {
  &:before {
    content: $icon-user-request-approval-pending;     
    color: #eb5757;
  }
}
.icon-users1 {
  &:before {
    content: $icon-users1;     
    color: #00adf0;
  }
}
.icon-workspace-switch {
  &:before {
    content: $icon-workspace-switch; 
  }
}
.icon-x-circle {
  &:before {
    content: $icon-x-circle;     
    color: #ff5c00;
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-dots-horizontal-triple {
  &:before {
    content: $icon-dots-horizontal-triple; 
  }
}
.icon-chevron-thin-down {
  &:before {
    content: $icon-chevron-thin-down; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right; 
  }
}
.icon-calendar1 {
  &:before {
    content: $icon-calendar1; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-up-arrow {
  &:before {
    content: $icon-up-arrow; 
  }
}
.icon-down-arrow {
  &:before {
    content: $icon-down-arrow; 
  }
}

