.spinner{
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  div{
    display: inline-block;
    width: 50px;
    height: 50px;
    // border: 3px solid rgba(195, 195, 195, 0.6);
    border: 5px solid rgba(0, 173, 240, 0.3);
    border-radius: 50%;
    // border-top-color: #636767;
    border-top-color: #00ADF0;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
.products__body {
  .spinner{
    position: absolute;
    top: 150px;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
  } 
}