.products {
  padding: 0 25px;
  &__header {
    padding: 31px 0 25px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1366px) {
      padding: 20px 0 20px;
    }
    &-right {
      display: inline-flex;
      gap: 15px;
      justify-content: flex-end;
      .search-ticket {
        // margin: 0;
        width: 304px;
        .search-input {
          max-height: 42px;
        }
      }
      button {
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        min-width: 129px;
        max-height: 42px;
        border-radius: 26px;
        padding: 12px;
        border: none;
      }
      .add-tool-btn {
        background: $color-primary-dark;
        color: $color-white;
      }
      .open-subscription-btn {
        background: $color-white;
        color: $color-primary-dark;
        border: 1px solid $color-light-light-gray;
      }
    }
  }
  &__filter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-left {
      display: inline-flex;
      gap: 14px;
      .search-product {
        position: relative;
        .search-input {
          background: var(--color-white);
          border: 1px solid var(--color-light-light-gray);
          border-radius: 8px;
          color: var(--color-gray);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 12px;
          width: 304px;
          @media (max-width: 1366px){
            width: auto
          }
        }
        .search-icon {
          position: absolute;
          right: 12px;
          top: 13px;
          cursor: pointer;
        }
      }
      .react-dropdown-select.search-filter {
        width: 162px;
        .react-dropdown-select-dropdown {
          width: 162px;
          @media (max-width: 1366px){width: auto}
        }
        @media (max-width: 1366px){width: auto}
      }
      &-reset {
        height: 42px;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        color: var(--color-primary-180, rgba(8, 43, 69, 0.8));
        display: flex;
        gap: 8px;
        button {
          border-radius: 28px;
          width: 120px;
          padding: 10px;
          background: $color-white;
          border: 1px solid $color-light-light-gray;
          color: $color-oldSilver;
          @media (max-width: 1366px){width: 80px;}
        }
        button[type="submit"] {
          background: $color-light-light-gray;
          color: $color-primary-dark;
        }
        button[type="submit"].active {
          background: $color-primary-dark;
          color: $color-white;
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      .change-view-btn {
        display: inline-flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #344054;
        background: unset;
        border: none;
        margin: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__body {
    padding: 34px 0;
    &.loading-table {
      opacity: 0.5;
    }
    .table-container {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      margin: 0;
      &::-webkit-scrollbar {
        width: 0;
        height: 6px;
        background-color: $color-light-light-gray;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: $color-light-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(167, 165, 165);
      }
    }
    /** GRID VIEW */
    &__grid {
      &-status {
        display: flex;
        justify-content: right;
        &-right {
          display: flex;
          gap: 12px;
          button {
            border: unset;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            column-gap: 10px;
            background: transparent;
            display: inline-flex;
            gap: 6px;
            align-items: center;
            color: $color-darkLiver;
            cursor: default;
            .live {
              width: 14px;
              height: 14px;
              background-color: $color-light-green;
            }
            .yet-to-launch {
              width: 14px;
              height: 14px;
              background-color: $color-orange;
            }
          }
        }
      }

      &-content {
        display: flex;
        gap: 16px;
        padding-top: 14px;
        padding-bottom: 20px;
        flex-flow: wrap;

        &-widget {
          // display: flex;
          // width: 355px;
          width: 24%;
          padding: 12px;
          // align-items: flex-start;
          // gap: 12px;
          border-radius: 8px;
          border: 2px solid $color-light-gray;
          background: $color-white;
          cursor: pointer;

          .status--indicator {
            display: flex;
            flex-direction: row-reverse;
            span {
              width: 30px;
              height: 5px;
              border-radius: 10px;
            }
            .live {
              background-color: $color-light-green;
            }
            .yet-to-launch {
              background-color: $color-orange;
            }
          }

          &-info {
            color: $color-gray;
            display: flex;
            gap: 20px;
            flex-direction: column;
            .product-detail {
              display: flex;
              flex-direction: column;
              gap: 8px;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              .title {
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
              }
            }
            .product-member {
              display: flex;
              justify-content: space-between;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              align-items: end;
              .members-head {
                margin: 0 0 4px;
                width: fit-content;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                /*border-bottom: 1px solid $color-light-light-gray;*/
                padding: 0;
              }
              .owner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                .owner-flex {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  column-gap: 6px;
                  p {
                    margin: 0;
                  }
                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
                  }
                  .primary-owner {
                    display: inline-flex;
                    height: 24px;
                    position: relative;
                    width: 24px;
                    border-radius: 50%;
                    fill: var(--color-white);
                    /*margin-left: -5px;*/
                    .no-img {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      width: 24px;
                      height: 24px;
                      font-size: 9px;
                      font-weight: 500;
                      line-height: 100%;
                      color: $color-white;
                      border-radius: 50%;
                    }
                  }
                  .primary-owner-name {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90px;
                    color: $color-gray;
                  }
                }
              }
            }
            .team_members {
              .table-container {
                border: unset !important;
                .avatars {
                  display: flex;
                  list-style-type: none;
                  margin: auto;
                  padding: 0px 7px 0px 0px;
                  .avatars__item {
                    display: inline-flex;
                    height: 24px;
                    position: relative;
                    width: 24px;
                    border-radius: 50%;
                    fill: var(--color-white);
                    margin-left: -5px;
                    &:first-child {
                      margin-left: 0;
                    }
                  }
                  .avatars__img,
                  span.avatars__initials {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    font-size: 9px;
                    font-weight: 500;
                    line-height: 100%;
                    color: $color-white;
                    border-radius: 50%;
                  }
                  span.avatars__others {
                    background-color: #d9d9d9;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 100%;
                    color: $color-darkLiver;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .no-record {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 100px;
    }
  }
}

/** ADD/EDIT TOOLS - POPUP DESIGN **/
.popupModal__container.add-tools {
  .popupModal__root {
    padding: 24px;
    width: 755px;
    border-radius: 5px;
    min-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
    .popupModal__heading {
      .icon-close {
        top: 24px;
        right: 24px;
      }
      h2 {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-black;
        text-align: justify;
        margin: 0px 0px 15px;
      }
    }
    .popupModal__body {
      min-height: auto;
      .addtool-popup {
        &--flex {
          display: flex;
          flex-direction: row;
          column-gap: 25px;
          margin: 0px 0px 0px;
          &-col {
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            label {
              display: block;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              margin: 0 0 6px;
              sup {
                color: $color-light-red;
                vertical-align: middle;
              }
            }
            .inner-flex-col {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .toggle-switch {
                width: fit-content;
                label {
                  margin: 0;
                }
                .toggle-switch-label {
                  width: 40px;
                  height: 20px;
                  .switch::before {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
              .toggle-switch-label input[type=checkbox]:checked + .switch::before {
                top: 2px;
                transform: translateX(22px);
              }
            }
            .dependeny-inner-flex {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 2px 0 0 0;
              button {
                background: $color-white;
                padding: 0;
                border: none;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                column-gap: 8px;
                img {
                  width: 15px;
                  vertical-align: middle;
                }
              }
            }
            .error-msg {
              margin: 4px 0 8px 0;
              padding: 0;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              color: $color-light-red;
              height: 12px;
              display: block;
            }
            .toolname {
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              background: $color-white;
              height: 42px;
              padding: 13px 12px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              &:disabled {
                background: $color-light-ghost-white;
              }
            }
          }
        }
        .react-dropdown-select {
          border-radius: 6px;
          border: 1px solid $color-light-light-gray;
          background: $color-light-ghost-white;
          height: 42px;
          margin: 0 0 0px 0;
          padding: 13px 15px 13px 12px;
          width: 100%;
          .react-dropdown-select-content {
            color: $color-darkLiver;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            flex-direction: row;
            max-width: 283px;
            overflow: hidden;
            flex-wrap: nowrap;
            text-overflow: ellipsis;
            justify-content: flex-start;
            .react-dropdown-select-option {
              background: transparent;
              color: $color-darkLiver;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              white-space: nowrap;
              margin: 0;
              position: relative;
              display: inline;
              &::before {
                position: absolute;
                content: ",";
                right: 0px;
                bottom: 0;
              }
              &:nth-last-child(2)::before {
                content: "";
              }
              .react-dropdown-select-option-remove {
                display: none;
              }
              &:first-child {
                padding-left: 0px;
              }
            }
          }
          .react-dropdown-select-dropdown-handle svg {
            vertical-align: -4px;
            width: 21px;
            height: 21px;
          }
          .react-dropdown-select-dropdown {
            overflow: visible;
            border-radius: 6px;
            .dropdwonList-main {
              padding: 20px 12px 12px;
              .dropdwonList-head {
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
                margin: 0 0 12px 0;
                padding-bottom: 12px;
                border-bottom: 1px solid $color-light-gray;
              }
              .dropdwonLists {
                overflow: auto;
                max-height: 200px;
                padding: 0px 12px 0px 12px;
                margin: 0 8px 10px 0px;
                &.single {
                  margin: 0;
                  padding: 0;
                }
                &::-webkit-scrollbar {
                  width: 8px;
                  height: 4px;
                  background-color: $color-light-light-gray;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                  border-radius: 10px;
                  background-color: $color-light-light-gray;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                  background-color: $color-oldSilver;
                }
                .error-show {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0;
                  padding: 0;
                  color: $color-light-red;
                  text-align: center;
                }
                .dropdwonList-subhead,
                .dropdwonLists-label {
                  margin: 0 0 16px 0;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-gray;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  column-gap: 8px;
                  &.disabled,
                  &:disabled {
                    pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.5;
                  }
                  label {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-gray;
                  }
                  &:last-child {
                    margin: 0 0 0 0;
                  }
                  &.sub-list {
                    margin-left: 8px;
                  }
                  .lable-field {
                    display: inline-flex;
                    align-items: flex-start;
                    flex-direction: column;
                    row-gap: 0px;
                    padding: 0;
                    margin: 0 0 0 0px;
                    label {
                      cursor: pointer;
                    }
                    .mail-field {
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-darkLiver;
                      overflow: hidden;
                      max-width: 250px;
                      text-overflow: ellipsis;
                    }
                  }
                  .checkbox-img {
                    width: 16px;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .keyhighlight-head,
      .tool-description-head {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        margin: 0 0 6px;
        sup {
          color: $color-light-red;
          vertical-align: middle;
        }
      }
      .keyhighlight-list,
      .tool-description {
        width: 100%;
        resize: none;
        height: 140px;
        border-radius: 6px;
        border: 1px solid $color-light-light-gray;
        background: $color-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 8px;
        color: $color-darkLiver;
        &::-webkit-scrollbar {
          width: 6px;
          height: 4px;
          background-color: $color-light-light-gray;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
          border-radius: 10px;
          background-color: $color-light-light-gray;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
          background-color: $color-oldSilver;
        }
      }
      .keyhighlight-error-msg,
      .tool-description-error-msg {
        margin: 0px 0 4px 0;
        padding: 0;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        color: var(--color-light-red);
        height: 12px;
        display: block;
      }
      .mandatory-head {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        text-align: right;
        margin: 8px 0 0px;
        sup {
          color: $color-light-red;
          vertical-align: middle;
        }
      }
      .flex-btn {
        float: right;
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        margin: 30px 0 0;
        .cancel-btn {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 35.202px;
          color: $color-oldSilver;
          border: 1px solid $color-oldSilver;
          background: $color-white;
          height: 31px;
          width: 71px;
        }
        .submit-btn,
        .add-btn {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 35.202px;
          color: $color-white;
          background: $color-primary-dark;
          height: 31px;
          min-width: 71px;
          position: relative;
          padding: 0px 16px;
          border: none;
        }
        .submit-btn.loading,
        .add-btn.loading {
          background: $color-primary-dark;
          padding-right: 40px;
        }
        .submit-btn.loading::after,
        .add-btn.loading:after {
          content: "";
          position: absolute;
          border-radius: 100%;
          right: 6px;
          top: 50%;
          width: 0px;
          height: 0px;
          margin-top: -2px;
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-left-color: #fff;
          border-top-color: #fff;
          animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }
        @keyframes spin {
          to {
            transform: rotate(359deg);
          }
        }
        @keyframes grow {
          to {
            width: 14px;
            height: 14px;
            margin-top: -8px;
            right: 13px;
          }
        }
      }
    }
  }
}

.notool-added {
  background: $color-light-ghost-white;
  margin: auto;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .messgae-box {
    width: 500px;
    height: 300px;
    padding: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px dashed $color-light-light-gray;
    background: $color-white;
    flex-direction: column;
    row-gap: 25px;
    .inner-content {
      margin: auto;
      padding: 0;
      .inner-head {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin: 0 0 8px;
        color: $color-gray;
      }
      .inner-text {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;
        color: $color-oldSilver;
      }
    }
  }
  button {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    min-width: 100px;
    max-height: 42px;
    border-radius: 26px;
    padding: 12px;
    border: none;
  }
  .add-tool-btn {
    background: $color-primary-dark;
    color: $color-white;
  }
}

/** DELETE TOOL **/
.popupModal__container.delete-tool {
  .popupModal__root {
    width: 360px;
    border-radius: 6px;
    padding: 16px;
    .popupModal__heading h2 {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
      min-width: 100%;
      margin: 0;
      color: $color-primary-dark;
      & .heading b {
        color: $color-primary;
      }
    }
    .icon-close {
      font-size: 12px;
    }
    .archive-message {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 12px 0 18px;
      color: $color-darkLiver;
    }
    .flex-disp {
      display: flex;
      flex-direction: row;
      margin: 0px 0 0;
      align-items: center;
      column-gap: 20px;
      justify-content: flex-end;
      .tool-delete {
        border-radius: 24px;
        background: $color-primary-dark;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        border: 1px solid transparent;
        max-width: 122px;
        height: 30px;
        &:hover {
          color: $color-white;
          background: #01101bd4;
          border: 1px solid $color-primary-dark;
        }
      }
      .archive-cancel {
        border-radius: 24px;
        border: 1px solid $color-oldSilver;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        color: $color-oldSilver;
        width: 75px;
        height: 30px;
        &:hover {
          background: #dadcdd;
        }
      }
    }
  }
}
