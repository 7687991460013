.showToolTip {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .update-status {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    &:disabled {
      opacity: 0.5;
      color: inherit;
    }
  }
  .popup-content {
    overflow: visible;
    position: absolute;
    right: 0;
    top: 39px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 107px;
    max-width: 300px;
    min-height: 35px;
    border-radius: 12px;
    background: $color-white;
    box-shadow: -1px 3px 10px 6px rgba(27, 31, 53, 0.12);
    z-index: 1100;
    .arrow-before {
      position: absolute;
      top: 50%;
      left: -14px;
      transform: translateY(-50%);
      border-width: 7px;
      border-style: solid;
      border-color: transparent rgba(27, 31, 53, 0.12) transparent transparent;
    }
    .arrow-after {
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: transparent $color-white transparent transparent;
    }
    .showPopup {
      z-index: 1500;
      width: 100%;
      position: relative;
      overflow: hidden;
      .tip-entry-main {
        margin: 0;
        padding: 2px 2px 0;
        list-style-type: none;
        width: 100%;
        border-radius: 12px;
        .tip-entry {
          padding: 8px 12px 8px 12px;
          border-bottom: 1px solid $color-light-light-gray;
          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
          }
          &.active,
          &:hover {
            background: $color-light-ghost-white;
          }
          &:first-child {
            border-radius: 12px 12px 0 0;
          }
          &:last-child {
            border: none;
            border-radius: 0 0 12px 12px;
          }
        }
      }
      .tip-entry {
        padding: 8px 12px;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        padding: 0;
        width: 100%;
        list-style-type: none;
        cursor: pointer;
        text-align: left;
      }
    }
  }
  &.isCustomField {
    .popup-content {
      padding: 18px;
      width: 407px;
      max-width: 407px;
      color: initial;
      @media (max-width: 1366px) and (max-height: 600px) {
        top: -15px;
      }
      .custom-popup-content {
        height: 100%;
        .search-label {
          .search-label-input {
            padding: 7px 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            background-color: $color-white;
            height: 40px;
            border: 1px solid $color-light-light-gray;
            width: 100%;
            &::placeholder {
              color: $color-light-light-gray-2;
            }
            &.active {
              background-color: $color-light-light-gray-1;
            }
          }
          label {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: $color-light-light-gray-2;
            display: block;
            margin: 8px 0 0;
          }
          .header-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: $color-darkLiver;
            margin: 18px 0 10px 0;
          }
        }
        .main-header {
          font-size: 20px;
          font-weight: 700;
          line-height: 24.2px;
          text-align: center;
          position: relative;
          margin: 0 0 15px;
          span {
            display: block;
            width: 15px;
            font-size: 16px;
            position: absolute;
            right: 2px;
            top: 5px;
            cursor: pointer;
          }
        }
        .customList {
          overflow-y: auto;
          margin: 8px 0 0;
          &::-webkit-scrollbar {
            width: 6px;
            height: 2px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 8px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          .header-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: $color-darkLiver;
            margin: 18px 0 6px 0;
          }
          .customList-names {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              list-style-type: none;
              padding: 5px 4px;
              margin: 0;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              column-gap: 10px;
              font-size: 15px;
              font-weight: 400;
              line-height: 18.15px;
              text-align: left;
              cursor: pointer;
              img {
                width: 16px;
              }
              .color-box {
                display: block;
                width: 100%;
                padding: 2px 8px;
              }
              &.show_less_labels,
              &.show_more_labels {
                color: $color-primary;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: right;
              }
            }
            &.participants {
              max-height: 150px;
              overflow-y: scroll;
              border: 1px solid $color-primary;
              padding: 4px 10px 4px 4px;
              margin: 0 10px 0 0;
              border-radius: 8px;
              &::-webkit-scrollbar {
                width: 6px;
                height: 2px;
                background-color: $color-light-light-gray;
                border-radius: 10px;
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                border-radius: 8px;
                background-color: $color-light-light-gray;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                background-color: $color-oldSilver;
              }
              li.user-selection {
                margin: 0 0 0 0;
                padding: 8px 5px 0;
                display: flex;
                flex-direction: row;
                column-gap: 12px;
                align-items: center;
                .list__checkbox {
                  width: 16px;
                  height: 16px;
                  display: inline-flex;
                  cursor: pointer;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .data_section_list_image {
                  width: 40px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  column-gap: 0;
                  height: 42px;
                  overflow: hidden;
                  border-radius: 50%;
                  fill: $color-white;
                  border: 1px solid $color-primary;
                  .userNull-image {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 100%;
                    text-align: center;
                    color: $color-white;
                  }
                }
                .participants-details {
                  display: inline-flex;
                  flex-direction: column;
                  row-gap: 4px;
                  flex-wrap: nowrap;
                  width: 70%;
                  .participants-name {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: left;
                    color: $color-gray;
                    margin: 0;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        .buttons {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          column-gap: 8px;
          margin: 18px 0 0;
          width: 100%;
        }
        .save-label-btn {
          border: none;
          font-size: 13px;
          font-weight: 400;
          line-height: 100%;
          text-align: center;
          padding: 8px 16px;
          height: 35px;
          background: $color-primary-dark;
          box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
          color: $color-white;
          border-radius: 18px;
        }
        .clear-cancel-btn {
          border: 1px solid $color-silver;
          font-size: 13px;
          font-weight: 400;
          line-height: 100%;
          text-align: center;
          padding: 8px 16px;
          height: 35px;
          background: $color-white;
          box-shadow: 1px 2px 7px 0px $color-darkLiver;
          color: $color-darkLiver;
          border-radius: 18px;
        }
      }
    }
  }
}
