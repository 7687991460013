.ticket-file-upload {
  margin: 0 0 18px 0;
  &_heading{
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    color: $color-gray;
  }
 
  .fileUpload{
    border: 1px solid $color-light-light-gray;
    border-radius: 4px;
    min-height: 212px;
    padding: 15px 15px 11px 15px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    &.uploadOption {
      min-height: 120px;
    }
    &_datas{
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      color: $color-darkLiver;
      min-height: 112px;
      display: flex;
      flex-direction: column;
      padding: 0px 12px;
      .file-info{
        &-container {
          display: flex;
          padding: 12px 0;
          align-items: center;
          justify-content: space-between;
          .name{
            display: inline-flex;
            align-items: center;
            gap: 12px;
            span:first-child{
              font-size: 32px;
            }
          }
          .flex-data {
            display: inline-flex;
            flex-direction: row;
            column-gap: 20px;
            justify-content: flex-start;
            align-items: center;
            .icon-close {
              color: $color-light-red;
              cursor: pointer;
            }
            .icon-download_file {
              font-size: 25px;
              cursor: pointer;
            }
          }
          p{
            margin: 0;
          }
          img{
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
      // div{
      //   margin: 0;
      //   padding: 12px 0;
      //   display: inline-flex;
      //   align-items: center;
      //   gap: 20px;
      //   span{
      //     font-size: 32px;
      //   }
      //   p{
      //     margin: 0;
      //   }
      // }
    }
    &_input {
      display: block;
      text-align: center;
      margin: auto;
      background-color: $color-blue-4;
      border-radius: 4px;
      width: 100%;
      height: 38px;
      position: relative;
      border: unset;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    &_button {
      display: block;
      margin: 50px auto 0;
      text-align: center;
      border-radius: 25px;
      min-width: 250px;
      height: 45px;
      // background-color: $color-blue-3;
      border: none;
      font-weight: 600;
      line-height: 100%;
      color: $color-white;
      @media only screen and (max-width: 650px) {
        & {
          min-width: 170px;
          margin: 20px auto 0;
        }
      }
      // &:disabled {
      //   background-color: $color-light-gray-2;
      // }
      // &.success {
      //   background-color: $color-light-green;
      // }
    }
    &_filename {
      font-size: 15px;
      font-weight: 500;
      line-height: 100%;
      height: 16px;
      color: $color-dark-green;
      margin: 10px auto;
      & .filetype-error {
        color: $color-red;
        padding: 10px 0 0 0px;
        display: inline-block;
      }
    }
  }
}

.documents{
  .ticket-file-upload{
    .fileUpload{
      padding: unset;
      &_datas{
        padding: unset;        
        .file-info:last-child{
          .file-info-container{
            border-bottom: unset;
          }
        }
      }
    }
  }
}