.notification {
  position: relative;
  &__popup {
    position: absolute;
    top: 48px;
    right: -24px;
    width: 500px;
    min-height: 55px;
    max-height: 784px;
    border: 1px solid $color-light-light-gray;
    background: $color-white;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(30, 25, 25, 0.08),
      0px 2px 4px 0px rgba(30, 25, 25, 0.08),
      0px 4px 8px 0px rgba(30, 25, 25, 0.08),
      0px 8px 16px 0px rgba(30, 25, 25, 0.08),
      0px 8px 32px 0px rgba(30, 25, 25, 0.08);
    // padding: 8px 0 4px;
    display: inline-flex;
    flex-direction: column;
    z-index: 900;
    align-items: flex-start;
    overflow: visible;
    // &:after,
    // &:before {
    //   bottom: 100%;
    //   left: 15%;
    //   border: solid transparent;
    //   content: " ";
    //   height: 0;
    //   width: 0;
    //   position: absolute;
    //   pointer-events: none;
    // }
    // &:after {
    //   border-color: rgba(255, 255, 255, 0);
    //   border-bottom-color: #ffffff;
    //   border-width: 8px;
    //   margin-left: -8px;
    // }
    // &:before {
    //   border-color: rgba(165, 175, 175, 0);
    //   border-bottom-color: transparent;
    //   border-width: 2px;
    //   margin-left: -10px;
    // }
    &--header {
      display: flex;
      padding: 10px 16px;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: $color-primary-dark;
      width: 100%;
      border-bottom: 1px solid #dfdcd8;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .closed-round-icon {
        display: block;
      }
    }
    &-clear {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      margin: 0;
      padding: 0px;
      &-btn {
        display: block;
        width: 160px;
        border: none;
        background: none;
        border-radius: 0;
        color: $color-darkLiver;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 18.2px;
        text-align: right;
        margin: 0;
        padding: 8px;
        &:hover {
          color: $color-blue;
          transition: color 2s;
        }
      }
    }
    &--list {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      color: $color-primary-dark;
      margin: 0;
      overflow: hidden;
      overflow-y: auto;
      max-height: 330px;
      width: 100%;
      padding: 8px;
      @media only screen and (min-height: 800px) {
        max-height: 450px;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        background-color: rgb(167, 165, 165);
      }
      .unread {
        background: $color-primary-light-4;
        position: relative;
        border: 1px solid transparent;
        &::before {
          contain: content;
          content: " ";
          position: absolute;
          left: 8px;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 25px;
          background-color: $color-blue-2;
        }
        &:hover {
          border: 1px solid $color-primary;
        }
      }
      .notification_container {
        display: flex;
        column-gap: 8px;
        padding: 15px 13px 13px;
        margin: 0 0 8px;
        border-radius: 4px;
        cursor: pointer;
        // align-items: center;

        .no-img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $color-light-light-gray;
        }
        &_image {
          position: relative;
          width: 32px;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          span{
            font-size: 32px;
          }
        }
        &_details {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
          &-top--row {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          &-header {
            font-size: 14px;
            font-weight: 600;
            line-height: 19.6px;
            text-align: left;
            color: $color-primary-dark;
            margin: 0;
            padding: 0;
            display: inline-flex;
            gap: 10px;
            align-items: baseline;
            justify-content: flex-start;
            flex-wrap: wrap;
            .board-name {
              font-size: 14px;
              font-weight: 400;
              line-height: 16.8px;
              text-align: left;
              text-decoration-skip-ink: none;
              border-radius: 20px;
              color: $color-white;
              display: inline-block;
              width: fit-content;
              padding: 2px 8px;
              margin: 0 0 0 0px;
              background-color: $color-primary-light-5;
              text-transform: capitalize;
              &.periodic-updates {
                background-color: $color-light-green;
              }
            }
          }
          &_html {
            margin: 0;
            color: $color-oldSilver;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            &_msg {
              margin: 0;
              color: #1e1919;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 2;
              max-height: 80px;
              min-height: 25px;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              p {
                margin: 0;
                font-size: 12px;
                span {
                  font-size: 12px;
                }
              }
            }
            a,
            span,
            p {
              color: $color-oldSilver;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              margin: 0;
              padding: 0;
            }
            span {
              display: inline;
            }
          }
          &_msg {
            margin: 0;
            color: $color-oldSilver;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
          &_updatedOn {
            margin: 0;
            padding: 0px;
            color: rgba(82, 74, 62, 0.82);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            width: 100px;
            text-align: right;
          }
        }
      }
      .notification_container:not(:last-child) {
        width: 100%;
      }
    }
    &--more {
      display: flex;
      padding: 10px;
      align-items: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: var(--color-darkLiver);
      width: 100%;
      justify-content: end;
      button {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        width: 100%;
        color: $color-gray;
        text-align: center;
        padding: 4px 25px;
        background-color: $color-light-light-gray;
        border: unset;
        height: 40px;
        border-radius: 8px;
        &:hover {
          background-color: $color-darkLiver;
          color: $color-white;
          transition: background-color 0.74s, color 0.74s;
        }
        .notification__count {
          color: $color-primary;
          font-size: 14px;
          font-weight: 400;
          line-height: 18.2px;
          display: inline;
        }
      }
    }
  }
}
