.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #009DD9; /* Set your desired background color */
  background-image: url('../../assets/images/login-background.svg'); /* Set your image URL */
  background-size: cover; /* Make the image cover the entire container */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-position: center center; /* Center the image horizontally and vertically */

  .login-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 1000px;
    background-color: $color-primary-dark;
    box-shadow: 0 0 40px rgba(0,0,0,0.16);
    overflow: hidden;
    margin: 0 auto;
    border-radius: 12px;

    &-banner {
      display: flex;
      align-items: flex-end;
      max-width: 1200px;
      min-height: 100%;
      background-color: $color-white;
      img {
        display: block;
        width: 100%;
      }
    }

    &-container{
      flex: 1 0 100%;
      max-width: 480px;
      width: 100%;
      padding: 0 60px;
      background-color: $color-primary-dark;
      text-align: center;

      &-title {
        color: $color-white;
        font-size: 44px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.44px;
        margin-bottom: 20px;
      }
      &-subtitle {
        color: $color-white;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.44px;
        margin-bottom: 25px;
      }

      &-button {        
        margin-top: 60px;
        &-common{
          background-color: $color-primary;
          color: $color-white;
          height: 50px;
          letter-spacing: 1px;
          border-radius: 4px;
          width: 100%;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
          margin-bottom: 18px;
        }
        &-agency {
          background-color: transparent;
          border: 1px solid $color-white;
          margin-bottom: 60px;
        }
      }

      

      a{
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: $color-white;
        text-decoration: none;
      }

      &-signupbtn{
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $color-white;
        span{
          color: $color-primary;
          cursor: pointer;
        }
      }

      &-signup-success{
        display: flex;
        flex-direction: column;
        gap: 150px;
        margin: 100px 0 0;
        &-content{          
          display: flex;
          flex-direction: column;
          gap: 24px;
          &-head{
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.44px;
            color: $color-white;
            margin: 0;
          }
          .icon-success-tick{
            font-size: 62px;
            .path1::before {
              color: $color-white;
            }
            .path2::before {
              color: $color-primary-dark;
            }
          }
          &-msg{
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.44px;
            color: $color-white;
            margin: 0;
          }
        }
        &-footer{
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: $color-white;
          display: inline-flex;
          flex-direction: column;
          gap: 10px;
          a{
            color: $color-primary;
          }
        }
      }
    }

    &-signup{
      display: flex;
      flex-direction: column;
      gap: 5px;
      .form-row {
        display: flex;
        flex-direction: row;
        column-gap: 25px;
        // margin: 20px 0 0px;
        .form-col {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          row-gap: 6px;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0;
          position: relative;
          width: 100%;
          .form-col-inline-flex {
            display: inline-flex;
            flex-direction: row;
            column-gap: 6px;
            position: relative;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            label {
              display: block;
            }
            .checkedIcon {
              width: 16px;
              height: 16px;
            }
          }
          .input-label {
            font-size: 14px;
            font-weight: 400;
            line-height: 14.4px;
            color: $color-white;
            sup {
              vertical-align: sub;
              color: $color-light-red;
              font-size: 14px;
            }
            &.error-msg {
              color: $color-light-red;
              font-size: 12px;
              margin: 0px;
              padding: 0px;
            }
          }
          .input-type {
            width: 100%;
            height: 38px;
            font-size: 14px;
            padding: 15px;
            border-radius: 6px;
            color: $color-oldSilver;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: none;
            &::placeholder {
              color: $color-light-gray-1;
              font-size: 14px;
            }
          }
          .custom-agencyDropdown{
            background: $color-white;
            border-radius: 6px;
            width: 362px;
            height: 38px;
            padding: 15px;
            & .react-dropdown-select-dropdown {
              margin: 0;
              overflow: auto;
              border-radius: 6px;
              width: 100%;
              top: 40px;
              &::-webkit-scrollbar {
                width: 6px;
                height: 8px;
                background-color: #f5f5f5;
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: rgb(167, 165, 165);
              }
              .react-dropdown-select-item {
                padding: 12.563px;
                background: $color-white;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                &:hover {
                  background: $color-light-gray;
                }
                &:first-child {
                  border-bottom: 1px solid $color-light-light-gray;
                  background: none;
                  color: $color-gray;
                  cursor: default;
                  ins {
                    display: none;
                  }
                }
    
                &:last-child {
                  border-top: 1px solid $color-light-light-gray;
                  background: $color-light-red-1;
                  border-bottom: none;
                }
              }
              .react-dropdown-select-item-selected {
                background: $color-light-light-gray;
                color: $color-gray;
              }
              .dropdwonList-main {
                padding: 0 12px;
                background-color: $color-white;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                .dropdwonList-head {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  color: $color-gray;
                  margin: 0 0 12px 0;
                  padding-bottom: 12px;
                  border-bottom: 1px solid $color-light-gray;
                }
                .dropdwonLists {
                  overflow: auto;
                  max-height: 180px;
                  padding: 0px 12px 0px 12px;
                  // margin: 0 8px 10px 0px;
                  position: relative;
                  &.single {
                    margin: 0;
                    padding: 0;
                  }
                  &::-webkit-scrollbar {
                    width: 8px;
                    height: 4px;
                    background-color: $color-light-light-gray;
                    border-radius: 10px;
                  }
                  &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                    border-radius: 10px;
                    background-color: $color-light-light-gray;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                    background-color: $color-oldSilver;
                  }
                  .dropdwonList-subhead,
                  .dropdwonLists-label {
                    margin: 0 0 16px 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-gray;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    column-gap: 8px;
                    padding: 10px 0;
                    margin: 0;
                    border-bottom: 1px solid #ccc;
                    &.disabled,
                    &:disabled {
                      pointer-events: none;
                      cursor: not-allowed;
                      opacity: .5;
                    }
                    &:last-child {
                      margin: 0 0 0 0;
                      border: none;
                    }
                    &.sub-list {
                      margin-left: 8px;
                    }
                    .lable-field {
                      display: inline-flex;
                      align-items: flex-start;
                      flex-direction: column;
                      row-gap: 0px;
                      padding: 0;
                      margin: 0 0 0 0px;
                      label {
                        cursor: pointer;
                      }
                    }
                    label {
                      cursor: pointer;
                    }
                  }
                  .error-show {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    padding: 10px;
                    color: $color-light-red;
                    text-align: center;
                  }
                }
              }
            }
            .react-dropdown-select-content {
              background: transparent;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-gray;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              flex-wrap: nowrap;
              text-overflow: ellipsis;
              justify-content: flex-start;
              .react-dropdown-select-option {
                background: $color-white;
                color: $color-gray;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                white-space: nowrap;
                margin: 0;
              }
              input{
                margin: unset
              }
            }
          }
          .termsCondition{
            // & input{
            //   transform: scale(1.1);
            // }
            background: transparent;
            padding: 0;
            border: none;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-white;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            column-gap: 8px;
          }
          .error-msg{
            padding: 0;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            color: $color-light-red;
            height: 12px;
            display: block;
          }
          .form-flex{
            display: flex;
            align-items: center;
            width: 100%;
            .dial-code{
              padding: 10px;
              border-right: 1px solid $color-light-light-gray;
              border-radius: 4px 0 0 4px;
              font-size: 14px;
              color: $color-oldSilver;
              white-space: nowrap;
              background: $color-white;
              height: 38px;
              display: block;
              min-width: 30px;
            }
            .input-type{
              border-radius: unset;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
          .suggestion-list {
            list-style: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 77%;
            left: 0;
            z-index: 1;
            width: 100%;
            background-color: $color-white;
            border: 1px solid $color-light-light-gray;
            border-radius: 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: var(--color-oldSilver);
            max-height: 200px;
            overflow: auto;
            overflow-x: hidden;
            li {
              padding: 8px;
              cursor: pointer;
              border-bottom: 1px solid $color-light-light-gray;
              text-align: left;
              &.agency-not-found {
                color: $color-light-red;
              }
            }
            li:hover {
              background-color: #f0f0f0;
            }

            &::-webkit-scrollbar {
              width: 8px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
          }
          
        }
      }
      
      .mt-10{
        margin-top: 10px;
      }
    }
  }
}



@media screen and (max-width: 1023px) {
  .login-page {
    .login-form {
      flex-direction: column;
      box-shadow: none;
      &-banner {
        max-width: 100%;
        min-height: auto;
      }
      &-container{
        max-width: 100%;
      }
    }
  }
}