.create-first-board {
  width: fit-content;
  max-width: 591px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  p {
    color: $color-darkLiver;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.creater-name {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 12px;
      color: $color-gray;
    }
  }

  .board-create-btn {
    border-radius: 12px;
    background: $color-primary-dark;
    max-width: 192px;
    height: 42px;
    color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    font-weight: 400;
    justify-content: space-between;
    padding: 13px 12px;
    border: none;
    & .create-name {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    & .create-symbol {
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.popupModal__container.create-first-board {
  text-align: left;
  max-width: 321px;
  min-width: 351px;
  height: 100vh;

    .popupModal__root {
      width: 100%;
      min-height: 345px;
      padding: 20px;
      border-radius: 5px 5px 5px 5px;
      margin-left: calc(100% - 160px);
      overflow: visible;
      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: $color-light-light-gray;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
        border-radius: 10px;
        background-color: $color-light-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
        background-color: $color-oldSilver;
      }
      .popupModal__heading {
        margin-bottom: 18px;
        border-bottom: 1px solid $color-light-light-gray;
        padding-bottom: 12px;
        h2 {
          text-align: left;
          margin-left: 0;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $color-primary-dark;
        }
        .icon-close {
          right: 22px;
          top: 16px;
        }
      }
      .popupModal__body {
        overflow: auto;
        .board-head {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-darkLiver;
          margin: 0 0 8px 0;
        }
        .error-msg {
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          color: $color-light-red;
          height: 12px;
          display: block;
          margin: 2px 0 8px 0;
        }
        .board-head-input {
          border-radius: 4px;
          border: 1px solid $color-light-light-gray;
          padding: 8px;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: $color-gray;
          width: 100%;
          height: 35px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: $color-oldSilver;
            opacity: 0.5;
          }
        }
        .descText {
          border-radius: 5.867px;
          border: 1.467px solid $color-light-light-gray;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          color: $color-gray;
          width: 100%;
          min-height: 158px;
          margin: 0 0 15px;
          resize: none;
          padding: 9px;
          &::placeholder {
            color: $color-oldSilver;
            font-style: italic;
          }
        }
        .flex-disp {
          display: flex;
          flex-direction: row;
          margin: 0px 0 0;
          align-items: center;
          column-gap: 20px;
          justify-content: flex-end;
          .board-save {
            border-radius: 24px;
            background: $color-primary-dark;
            padding: 6px 16px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-white;
            border: 1px solid transparent;
            max-width: 122px;
            height: 35px;
            &:hover {
              color: $color-white;
              background: #01101bd4;
              border: 1px solid $color-primary-dark;
            }
          }
          .board-cancel {
            border-radius: 24px;
            border: 1px solid $color-oldSilver;
            padding: 6px 16px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            color: $color-oldSilver;
            width: 75px;
            height: 35px;
            &:hover {
              background: #dadcdd;
            }
          }
        }
      }
    }
  
}

.popper {
  z-index: 10000;
}
.react-dropdown-select.create-edit-DropdownSelect {
  width: 100%;
  min-height: 42px;
  // margin: 0 0 15px 0;
  border-radius: 5.867px;
  padding: 9px;
  & .react-dropdown-select-dropdown {
    margin: 0;
    border-radius: 6px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
    .react-dropdown-select-item {
      padding: 12.563px;
      border-radius: 6px 6px 0px 0px;
      border-bottom: 1px solid $color-light-light-gray;
      background: $color-white;
      &:hover {
        background: $color-light-gray;
      }
    }
    .react-dropdown-select-item-selected {
      background: $color-light-gray;
      color: $color-gray;
    }
  }
  & .react-dropdown-select-content {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    input {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
      &::placeholder {
        color: $color-gray;
        opacity: 0.5;
      }
    }
  }
  & .react-dropdown-select-dropdown-handle {
    display: inline-flex;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}

.popupModal__container.create-first-board.edit-board {
  max-width: 321px;
  min-height: 190px;
  .popupModal__root {
    min-height: 190px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
  }
  .popupModal__root .popupModal__heading .icon-close {
    right: 22px;
    top: 16px;
    font-size: 14px;
  }
}
.create-edit-board {
  padding: 0 0 8px;
}
