.popupModal.show.fullBleed {
  .popupModal__container.addUpdateRequirement {
    margin-top: 60px;
    .popupModal__root {
      width: 100%;
      height: 100%;
      margin: 0 0;
      padding: 18px 24px;
      @media only screen and (max-width: 1280px) {
        & {
          padding: 18px 34px 18px;
        }
      }
      .popupModal__heading .icon-close {
        /* display: none;*/
      }
      .popupModal__body {
        // overflow-y: auto;
        padding: 0px 0px !important;
        margin: auto auto 61px;
        width: 100%;
        max-width: 1920px;
        position: relative;
        .flex-row {
          padding: 0 30px 10px;
          margin: 0 0 10px 0;
        }

        .flex-formData-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          column-gap: 20px;
          padding: 24px 0px;
          background: $color-white;
          &.btn-border {
            border-top: 1px solid $color-light-gray;
          }
          .salesDataCommon {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
            p {
              margin: 0;
              color: $color-light-red;
              font-size: 11px;
              font-weight: 500;
            }
          }
          .cancelSalesDataCommon {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
          }
          .cancel-btn {
            display: block;
            width: 100px;
            height: 38px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            border: none;
            line-height: normal;
            color: $color-white;
            border-radius: 35.202px;
            border: 1px solid $color-light-light-gray;
            color: $color-darkLiver;
            padding: 8px;
            text-align: center;
            cursor: pointer;
            box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
          }
          .save-btn {
            display: block;
            width: 100px;
            height: 38px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-white;
            border-radius: 35.202px;
            border: 1px solid $color-light-light-gray;
            background-color: $color-white;
            // color: $color-darkLiver;
            padding: 8px;
            text-align: center;
            transform: scale(1);
            transition: transform 0.74s;
            background: $color-primary-dark;
            box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
            border: none;
            &:hover {
              color: $color-white;
              background: $color-primary-dark;
              transform: scale(1.05);
              transition: transform 0.74s;
            }
            &:disabled {
              opacity: 0.5;
            }
            &.loading {
              background: $color-primary-dark;
              padding-right: 35px;
            }
            &.loading:after {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
          }
          .cancelSalesDataCommon {
            .cancel-btn:hover {
              background-color: #f0f0f0;
            }
          }
          .submit-btn {
            display: block;
            min-width: 85px;
            text-align: center;
            height: 38px;
            font-size: 15px;
            font-style: normal;
            padding: 2px 16px;
            font-weight: 400;
            border: none;
            line-height: normal;
            color: $color-white;
            border-radius: 35.202px;
            background: $color-primary-dark;
            position: relative;
            transform: scale(1);
            transition: transform 0.74s;
            box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
            &:hover {
              transform: scale(1.05);
              transition: transform 0.74s;
            }
            &:disabled {
              opacity: 0.5;
            }
            &.loading {
              background: $color-primary-dark;
              padding-right: 35px;
            }
            &.loading:after {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
          }
          @keyframes spin {
            to {
              transform: rotate(359deg);
            }
          }
          @keyframes grow {
            to {
              width: 14px;
              height: 14px;
              margin-top: -8px;
              right: 13px;
            }
          }
        }
        .buttons-flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          column-gap: 20px;
          padding: 0px;
          margin: 0 0 10px;
          .flex-formData-btn {
            padding: 0;
            .next-btn,
            .previous-btn {
              color: $color-oldSilver;
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              background-color: $color-white;
              border: none;
              &:disabled {
                opacity: 0.5;
              }
            }
          }
          .flex-formData-btn:first-child {
            column-gap: 5px;
            position: relative;
            .showPopupTip {
              position: absolute;
              top: -50px;
              .tip-entry {
                color: $color-light-red;
              }
            }
          }
        }
        .ticket-info {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          column-gap: 24px;
          padding: 0 30px 10px;
          justify-content: space-between;
          .flex-col {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            row-gap: 8px;
            .ticket-title {
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: $color-gray;
              margin: 0;
            }
            .order-info {
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              margin: 0;
              span {
                color: $color-gray;
                font-weight: 500;
              }
            }
            .due-date-head {
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              text-align: right;
              margin: 0;
            }
            .due-date {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-gray;
              text-align: right;
              margin: 0;
            }
          }
        }
        .tool-selection-popup {
          // position: absolute;
          // top: 50px;
          // right: 0;
          background: $color-white;
          z-index: 10;
          width: 316px;
          // max-height: 100vh;
          height: fit-content;
          overflow: visible;
          border-radius: 10px;
          border: 1px solid $color-light-light-gray;
          padding: 0 0 5px;
          .tool-selection-popup-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 17px;
            font-weight: 600;
            line-height: 21px;
            background: $color-light-ghost-white;
            padding: 14px 24px;
            border-radius: 10px 10px 0 0;
            color: $color-primary-dark;
            border-bottom: 1px solid $color-light-light-gray;
            margin: 0;
            .tool-selection-popup-count {
              background-color: $color-primary;
              color: $color-white;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              border-radius: 50px;
              padding: 3px 2px;
              display: inline-flex;
              align-items: center;
              justify-content: space-around;
              min-width: 21px;
            }
          }
          .tool-selection-popup-list {
            overflow-y: scroll;
            background: $color-white;
            max-height: 65vh;
            list-style-type: none;
            padding: 0 0 0 0;
            margin: 0;
            &::-webkit-scrollbar {
              width: 4px;
              height: 6px;
              background-color: $color-light-light-gray;
              border-radius: 20px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 4px $color-light-light-gray;
              border-radius: 20px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 20px;
              -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
              background-color: $color-oldSilver;
            }
            li {
              font-size: 15px;
              font-weight: 400;
              line-height: 18px;
              color: $color-gray;
              list-style-type: none;
              border-bottom: 1px solid $color-light-light-gray;
              .tool-selection-popup-btn {
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: baseline;
                justify-content: flex-start;
                border: none;
                width: 100%;
                background: transparent;
                text-align: left;
                padding: 12px 12px;
                position: relative;
                color: $color-gray;
                .tickeCheck-icon {
                  min-width: 19px;
                }
                &:hover {
                  background-color: $color-light-ghost-white;
                }
                .icon-chevron-thin-down {
                  position: absolute;
                  right: 12px;
                }
                &.active {
                  background-color: $color-light-ghost-white;
                  .icon-chevron-thin-down {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    &::before {
                      vertical-align: 2px;
                    }
                  }
                }
              }
              &:last-child {
                border-radius: 0 0 10px 10px;
                border-bottom: none;
                .tool-selection-popup-btn {
                  border-radius: 0 0 10px 10px;
                }
              }
            }
          }
        }
        .collapseCard {
          border-radius: 4px;
          padding: 0px 0px 0px;
          margin: 24px 0px;
          overflow: visible;
          &.sales-datas {
            margin: 0px;
            .flex-inputs {
              .input-field {
                margin: 0 0 30px;

                .toggle-switch {
                  width: fit-content;
                  padding: 8px 0;
                  label {
                    margin: 0;
                  }
                  .toggle-switch-label {
                    width: 44px;
                    height: 24px;
                    .switch::before {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
                .toggle-switch-label
                  input[type="checkbox"]:checked
                  + .switch::before {
                  top: 2px;
                  transform: translateX(22px);
                }
              }
            }
          }
          &.customer-timeline {
            border-top: none;
          }
          &:last-child {
            border-bottom: 1px solid $color-platinum;
          }
          &__heading {
            padding: 10px 24px 10px;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: $color-gray;
            background-color: $color-light-ghost-white;
            .icon-chevron-thin-down {
              display: inline-block;
              position: absolute;
              right: 12px;
              &::before {
                font-size: 12px;
                vertical-align: -2px;
                font-weight: 700;
              }
            }
            &.active {
              background-color: $color-light-ghost-white;
              color: initial;
              .icon-chevron-thin-down {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                &::before {
                  vertical-align: 4px;
                }
              }
            }
          }
          .collapseCard__body {
            background: transparent;
            display: none;
            &.show {
              display: block;
              background: $color-white;
              border-top: 1px solid $color-platinum;
              border-radius: 0px 0px 5px 5px;
              padding: 8px 24px 18px;
              margin: 0 0;
              position: relative;
              .customer-details-contet {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0 0 12px 0;
                .customer-details-contet--col1,
                .customer-details-contet--col2 {
                  margin: 0;
                  padding: 0;
                  width: 50%;
                  .customer-details-table {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    width: 100%;
                    .customer-field {
                      width: 40%;
                      max-width: 300px;
                      padding: 9px;
                      vertical-align: top;
                      color: $color-darkLiver;
                    }
                    .custom-field-dividend {
                      padding: 9px 4px;
                      text-align: center;
                      width: 20px;
                      vertical-align: top;
                    }
                    .customer-field-value {
                      font-weight: 500;
                      padding: 9px;
                      vertical-align: top;
                      color: $color-gray;
                    }
                  }
                }
              }
            }
          }
          .datafield-head {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            color: $color-darkLiver;
            line-height: normal;
            margin: 0 0 8px;
          }
          .flex-inputs {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 24px;
            justify-content: flex-start;
            width: 100%;
            &.disabled,
            &:disabled {
              pointer-events: none;
              cursor: not-allowed;
              .react-dropdown-select {
                pointer-events: none;
                cursor: not-allowed;
              }
            }

            .input-field {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
              row-gap: 6px;
              justify-content: flex-start;
              align-items: flex-start;
              min-width: 362px;
              margin: 0;
              @media only screen and (max-width: 1280px) {
                & {
                  min-width: 340px;
                }
              }
              .fs-opf-eye-dropper:before {
                content: "\e925";
                color: #014a87;
              }

              position: relative;
              .flex-col {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: baseline;
                margin: 0;
                width: 100%;
                line-height: 100%;
                padding: 0;
                .head-field {
                  display: inline-flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-darkLiver;
                  border: none;
                  sup {
                    color: $color-light-red;
                    top: 2px;
                  }
                }
                .remove-field {
                  background-color: $color-white;
                  border: none;
                  padding: 0;
                  margin: 0;
                  line-height: 100%;
                }
              }
              // .currency-dropdownRenderer  {
              //   min-width: 362px;
              //   border-radius: 6px;
              //   .react-dropdown-select-dropdown {
              //     width: 100%;
              //   }
              // }
              .currency-dropdownRenderer {
                min-width: 362px;
                max-width: 362px;
                border-radius: 6px;
                height: 42px;
                padding: 13px 15px 13px 12px;
                .react-dropdown-select-option {
                  position: relative;
                  &::before {
                    position: absolute;
                    content: ",";
                    right: 0px;
                    bottom: 0;
                  }
                  &:nth-last-child(2)::before {
                    content: "";
                  }
                  .react-dropdown-select-option-remove {
                    display: none;
                  }
                }
                .react-dropdown-select-dropdown-handle svg {
                  vertical-align: -4px;
                  width: 21px;
                  height: 21px;
                }
                .react-dropdown-select-dropdown {
                  overflow: visible;
                  border-radius: 6px;
                  width: 100%;
                  top: 47px;
                  background-color: $color-white;
                  border: none;
                  box-shadow: none;
                  z-index: 1;
                  .dropdwonList-main {
                    padding: 12px 12px;
                    width: 100%;
                    z-index: 100;
                    background-color: $color-white;
                    border-radius: 6px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    border: 1px solid #ccc;
                    .dropdwonList-head {
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      color: $color-gray;
                      margin: 0 0 12px 0;
                      padding-bottom: 12px;
                      border-bottom: 1px solid $color-light-gray;
                    }
                    .dropdwonLists {
                      overflow: auto;
                      max-height: 200px;
                      padding: 0px 12px 0px 12px;
                      // margin: 0 8px 10px 0px;
                      position: relative;
                      &.single {
                        margin: 0;
                        padding: 0;
                      }
                      &::-webkit-scrollbar {
                        width: 8px;
                        height: 4px;
                        background-color: $color-light-light-gray;
                        border-radius: 10px;
                      }
                      &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px
                          $color-light-light-gray;
                        border-radius: 10px;
                        background-color: $color-light-light-gray;
                      }
                      &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                        background-color: $color-oldSilver;
                      }
                      .create-new-entry {
                        border: none;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        .create-new-entrybtn {
                          border: none;
                          width: 47px;
                          height: 23px;
                          white-space: nowrap;
                          background-color: $color-light-ghost-white;
                          border: 1px solid $color-light-light-gray;
                          padding: 6px 6px;
                          border-radius: 4px;
                          font-size: 11px;
                          line-height: 100%;
                          font-weight: 500;
                          &:hover {
                            background-color: $color-light-light-gray;
                          }
                        }
                      }
                      .error-show {
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        padding: 0;
                        color: $color-light-red;
                        text-align: center;
                      }
                      .dropdwonList-subhead,
                      .dropdwonLists-label {
                        padding: 8px 4px;
                        margin: 0;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-gray;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        column-gap: 8px;
                        &:hover {
                          background-color: $color-light-ghost-white;
                        }
                        &.disabled,
                        &:disabled {
                          pointer-events: none;
                          cursor: not-allowed;
                          opacity: 0.5;
                        }
                        &:last-child {
                          margin: 0 0 0 0;
                        }
                        &.sub-list {
                          margin-left: 8px;
                        }

                        .lable-field {
                          display: inline-flex;
                          align-items: flex-start;
                          flex-direction: column;
                          row-gap: 0px;
                          padding: 0;
                          margin: 0 0 0 0px;
                          label {
                            cursor: pointer;
                          }
                          .mail-field {
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: $color-darkLiver;
                            overflow: hidden;
                            max-width: 250px;
                            text-overflow: ellipsis;
                          }
                        }
                        .checkbox-img {
                          width: 16px;
                          height: 100%;
                        }
                      }
                    }
                  }
                }
                .react-dropdown-select-content {
                  background: $color-white;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-gray;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  flex-wrap: nowrap;
                  text-overflow: ellipsis;
                  justify-content: flex-start;
                  .react-dropdown-select-option {
                    background: transparent;
                    color: $color-gray;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    margin: 0;
                  }
                }
                &.fontFamilyList {
                  .react-dropdown-select-dropdown {
                    overflow-y: auto;
                    padding: 12px 12px;
                    max-height: 200px;
                    min-height: 120px;
                    background-color: $color-white;
                    border-radius: 6px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    border: 1px solid #ccc;
                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                    .react-dropdown-select-item {
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-gray;
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                      column-gap: 8px;
                      &:hover {
                        background: $color-light-ghost-white;
                      }
                    }
                    .react-dropdown-select-item-selected {
                      background: transparent;
                    }
                  }
                }
              }

              .flex-col2 {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: baseline;
                margin: 0;
                line-height: 100%;
                padding: 0;
                width: 100%;
                .add-field {
                  background-color: $color-white;
                  border: none;
                  color: $color-oldSilver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 0;
                  line-height: 100%;
                  min-width: 110px;
                  &:disabled {
                    opacity: 0.5;
                  }
                }
              }

              .regionDropDownList {
                width: 362px;
                height: 42px;
                @media only screen and (max-width: 1280px) {
                  & {
                    width: 340px;
                  }
                }
                .react-dropdown-select-content {
                  font-size: 15px;
                  color: $color-oldSilver;
                }
              }
              .packageList {
                width: 362px;
                height: 42px;
                .react-dropdown-select-content {
                  font-size: 15px;
                  color: $color-oldSilver;
                }
              }
              .label,
              label,
              .input-type {
                width: 100%;
                &.package {
                  user-select: none;
                  pointer-events: none;
                }
              }
              .search-icon {
                position: absolute;
                right: 12px;
                top: 35px;
              }
              label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                border: none;
                &.disabelEdit {
                  pointer-events: none;
                }
                &.clicked {
                  border: 1px solid $color-light-light-gray;
                }
                sup {
                  color: $color-light-red;
                  vertical-align: middle;
                }
              }
              .error-msg {
                margin: 4px 0 8px 0;
                padding: 0;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                color: $color-light-red;
                height: 12px;
                display: block;
              }
              .label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                border: none;
                &.disabelEdit {
                  pointer-events: none;
                }
                &.clicked {
                  border: 1px solid $color-light-light-gray;
                }
                sup {
                  color: $color-light-red;
                  vertical-align: middle;
                }
              }

              .input-type {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                height: 42px;
                color: $color-gray;
                padding: 9px 27px 9px 14px;
                text-overflow: ellipsis;
                display: block;
                &.market-data {
                  color: $color-oldSilver;
                }
              }

              .search-loading {
                position: absolute;
                right: 12px;
                top: 45px;
                border-radius: 100%;
                width: 0px;
                height: 0px;
                margin-top: -2px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left-color: $color-gray;
                border-top-color: $color-gray;
                animation: spin 0.6s infinite linear,
                  grow 0.3s forwards ease-out;
              }

              .suggestion-list {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                width: 100%;
                background-color: $color-white;
                border: 1px solid $color-light-light-gray;
                border-radius: 4px;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                color: var(--color-oldSilver);
                max-height: 200px;
                overflow: auto;
                overflow-x: hidden;
                li {
                  padding: 8px;
                  cursor: pointer;
                  border-bottom: 1px solid $color-light-light-gray;
                  &.company-not-found {
                    color: $color-light-red;
                  }
                }
                li:hover {
                  background-color: #f0f0f0;
                }

                &::-webkit-scrollbar {
                  width: 8px;
                  height: 4px;
                  background-color: $color-light-light-gray;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                  border-radius: 10px;
                  background-color: $color-light-light-gray;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                  background-color: $color-oldSilver;
                }
              }
              .color-sample {
                width: 22px;
                height: 22px;
                position: absolute;
                right: 12px;
                top: 35px;
                cursor: pointer;
                border: 1px solid $color-light-light-gray;
              }
              .color-picker {
                position: absolute;
                top: 66px;
                background: #fff;
                box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
                z-index: 99;
                padding: 10px;
                right: 0;
              }
            }
            .notes-field,
            .address-field {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
              row-gap: 6px;
              justify-content: flex-start;
              align-items: flex-start;
              min-width: 365px;
              margin: 0 0 18px;
              width: 48%;
              label,
              textarea {
                width: 100%;
              }
              label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
              }
              textarea {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                resize: none;
                min-height: 105px;
                padding: 12px;
                color: $color-oldSilver;
                &::placeholder {
                  font-style: italic;
                }
              }
            }
          }
          .flex-toollist-head {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-gray;
            margin: 0 0 12px;
          }
          .flex-toollist {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 0 24px 0;
            .flex-inner-col1 {
              min-width: 83px;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-darkLiver;
              padding: 8px;
              padding-right: 0;
            }
            .flex-inner-col2 {
              min-width: 10px;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-darkLiver;
              padding: 8px 16px 8px 0px;
            }
            .flex-inner-col3 {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 8px;
              align-items: flex-start;
              justify-content: flex-start;
              .tool-name {
                border-radius: 4px;
                background: $color-silver;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
                padding: 8px;
                display: block;
                margin: 0 0 8px 0;
              }
            }
          }
          .flex-formData-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            column-gap: 20px;
            position: relative;
            .cancel-btn {
              display: block;
              width: 100px;
              height: 38px;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              border: none;
              line-height: normal;
              color: $color-white;
              border-radius: 35.202px;
              border: 1px solid $color-light-light-gray;
              color: $color-darkLiver;
              padding: 8px;
              text-align: center;
              cursor: pointer;
            }
            .submit-btn {
              display: block;
              min-width: 85px;
              text-align: center;
              height: 38px;
              font-size: 15px;
              font-style: normal;
              padding: 2px 16px;
              font-weight: 400;
              border: none;
              line-height: normal;
              color: $color-white;
              border-radius: 35.202px;
              background: $color-primary-dark;
              position: relative;
              box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
              &.loading {
                background: $color-primary-dark;
                padding-right: 35px;
              }
              &.loading::after {
                content: "";
                position: absolute;
                border-radius: 100%;
                right: 6px;
                top: 50%;
                width: 0px;
                height: 0px;
                margin-top: -2px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left-color: #fff;
                border-top-color: #fff;
                animation: spin 0.6s infinite linear,
                  grow 0.3s forwards ease-out;
              }
            }
            .order-id-btn {
              display: block;
              min-width: 85px;
              text-align: center;
              height: 38px;
              font-size: 15px;
              font-style: normal;
              padding: 2px 16px;
              font-weight: 400;
              border: none;
              line-height: 32px;
              color: $color-white;
              border-radius: 35.202px;
              background: $color-primary-dark;
              box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
              position: relative;
              cursor: pointer;
              &.disabled {
                opacity: 0.5;
              }
            }
            .order-id-btn.loading {
              background: $color-primary-dark;
              padding-right: 35px;
            }
            .order-id-btn.loading:after {
              content: " ";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }

            .popup-content.commonData-tip {
              top: -16px;
              right: 50px;
              .tip-entry {
                color: $color-light-red;
                font-size: 12px;
              }
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
        }
        .tool-info {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          flex-direction: row;
          &-container {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            // display: flex;
            // flex-wrap: wrap;
            // flex-direction: row;
            // column-gap: 24px;
            // justify-content: space-between;
            // padding: 0 30px;
            // margin: 12px 0 17px;
            // position: relative;
            // width: 77%;
            .flex-col {
              padding: 0 30px;
              margin: 12px 0 17px;
              // display: flex;
              // flex-wrap: nowrap;
              // flex-direction: column;
              // row-gap: 8px;
              // position: relative;
              .tool-title {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
                margin: 0;
              }
              .react-dropdown-select {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-light-ghost-white;
                height: 42px;
                margin: 0 0 20px 0;
                padding: 13px 15px 13px 12px;
                &.departmentList {
                  .react-dropdown-select-input {
                    &::placeholder {
                      color: $color-light-red;
                    }
                  }
                }
                .react-dropdown-select-content {
                  color: $color-darkLiver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  flex-wrap: nowrap;
                  span {
                    display: block;
                    width: 100%;
                    text-align: left;
                    white-space: nowrap;
                    float: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .react-dropdown-select-input {
                    display: inline;
                  }
                  .react-dropdown-select-option {
                    background: transparent;
                    color: $color-darkLiver;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    margin: 0;
                    &:first-child {
                      padding-left: 0px;
                    }
                  }
                }
                &.custom-dropdownRenderer {
                  width: 224px;
                  .react-dropdown-select-option {
                    position: relative;
                    display: inline;
                    &::before {
                      position: absolute;
                      content: ",";
                      right: 0px;
                      bottom: 0;
                    }
                    &:nth-last-child(2)::before {
                      content: "";
                    }
                    .react-dropdown-select-option-remove {
                      display: none;
                    }
                  }
                  .react-dropdown-select-dropdown {
                    overflow: visible;
                    border-radius: 6px;
                    max-height: 100%;
                    .dropdwonList-main {
                      padding: 12px;
                      .dropdwonList-head {
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-gray;
                        margin: 0 0 5px 0;
                        padding-bottom: 12px;
                        border-bottom: 1px solid $color-light-gray;
                      }
                      .dropdwonLists {
                        overflow: hidden;
                        overflow-y: auto;
                        max-height: 60vh;
                        padding: 0px 12px 12px 12px;
                        margin: 0 22px 0 10px;
                        &.single {
                          margin: 0;
                          padding: 0;
                          background-color: #ffffff;
                        }
                        &::-webkit-scrollbar {
                          width: 4px;
                          height: 2px;
                          background-color: $color-light-light-gray;
                          border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                          -webkit-box-shadow: inset 0 0 4px
                            $color-light-light-gray;
                          border-radius: 10px;
                          background-color: $color-light-light-gray;
                        }
                        &::-webkit-scrollbar-thumb {
                          border-radius: 10px;
                          -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                          background-color: $color-oldSilver;
                        }
                        .dropdwonList-subhead,
                        .dropdwonLists-label {
                          margin: 0 0 16px 0;
                          font-size: 15px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          color: $color-gray;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          column-gap: 8px;
                          &.disabled,
                          &:disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                            opacity: 0.5;
                          }
                          &:last-child {
                            margin: 0 0 0 0;
                          }
                          &.sub-list {
                            margin-left: 8px;
                          }
                          label {
                            cursor: pointer;
                          }
                          .checkbox-img {
                            width: 16px;
                            height: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &-list {
            display: inline-flex;
          }
        }
        .ticker-tabs {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          column-gap: 18px;
          margin: 0 0 28px;
          .ticker-tabs-btns {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background-color: $color-white;
            border: none;
            border-bottom: 2px solid $color-white;
            padding: 10px 6px;
            &.active {
              border-bottom: 2px solid $color-gray;
            }
          }
        }
        .toolDataDeatils-box {
          overflow-y: auto;
          // height: 80%;
          margin: 0 0 10px;
          padding: 0 30px;
          width: 100%;
          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          &.tool-lists {
            // width: 77%;
          }
          .toolDataDeatils {
            &.disabled {
              user-select: none;
              pointer-events: none;
              opacity: 0.65;
            }
            .datafield-head {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              color: $color-darkLiver;
              line-height: normal;
            }
            .flex-inputs {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              column-gap: 24px;
              justify-content: flex-start;
              width: 100%;
              &.disabled,
              &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                .react-dropdown-select {
                  pointer-events: none;
                  cursor: not-allowed;
                }
              }
              .input-field {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                row-gap: 6px;
                justify-content: flex-start;
                align-items: flex-start;
                min-width: 362px;
                margin: 0 0 18px;
                @media only screen and (max-width: 1280px) {
                  & {
                    min-width: 340px;
                  }
                }
                .fs-opf-eye-dropper:before {
                  content: "\e925";
                  color: #014a87;
                }
                &.description {
                  width: 100% !important;
                }
                textarea {
                  border-radius: 6px;
                  border: 1px solid $color-light-light-gray;
                  background: $color-white;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  resize: none;
                  min-height: 110px;
                  padding: 12px;
                  color: $color-oldSilver;
                  width: 100%;
                  &::placeholder {
                    font-style: italic;
                  }
                }
                position: relative;
                .flex-col {
                  display: inline-flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  align-items: baseline;
                  margin: 0;
                  width: 100%;
                  line-height: 100%;
                  padding: 0;
                  .remove-field {
                    background-color: $color-white;
                    border: none;
                    padding: 0;
                    margin: 0;
                    line-height: 100%;
                  }
                }
                .flex-col2 {
                  display: inline-flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-end;
                  align-items: baseline;
                  margin: 0;
                  line-height: 100%;
                  padding: 0;
                  width: 100%;
                  .add-field {
                    background-color: $color-white;
                    border: none;
                    color: $color-oldSilver;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 0;
                    line-height: 100%;
                    min-width: 110px;
                    &:disabled {
                      opacity: 0.5;
                    }
                  }
                }

                .regionDropDownList {
                  width: 362px;
                  height: 42px;
                  @media only screen and (max-width: 1280px) {
                    & {
                      width: 340px;
                    }
                  }
                  .react-dropdown-select-content {
                    font-size: 15px;
                    color: $color-oldSilver;
                  }
                }
                .packageList {
                  width: 362px;
                  height: 42px;
                  .react-dropdown-select-content {
                    font-size: 15px;
                    color: $color-oldSilver;
                  }
                }
                .label,
                label,
                .input-type {
                  width: 100%;
                  &.package {
                    user-select: none;
                    pointer-events: none;
                  }
                }
                .search-icon {
                  position: absolute;
                  right: 12px;
                  top: 35px;
                }
                label {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-darkLiver;
                  border: none;
                  &.disabelEdit {
                    pointer-events: none;
                  }
                  &.clicked {
                    border: 1px solid $color-light-light-gray;
                  }
                }
                .label {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-darkLiver;
                  border: none;
                  &.disabelEdit {
                    pointer-events: none;
                  }
                  &.clicked {
                    border: 1px solid $color-light-light-gray;
                  }
                }

                .input-type {
                  border-radius: 6px;
                  border: 1px solid $color-light-light-gray;
                  background: $color-white;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  height: 42px;
                  color: $color-gray;
                  padding: 9px 27px 9px 14px;
                  text-overflow: ellipsis;
                  display: block;
                  &.market-data {
                    color: $color-oldSilver;
                  }
                }

                .suggestion-list {
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  position: absolute;
                  top: 100%;
                  left: 0;
                  z-index: 1;
                  width: 100%;
                  background-color: $color-white;
                  border: 1px solid $color-light-light-gray;
                  border-radius: 4px;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  color: var(--color-oldSilver);
                  max-height: 200px;
                  overflow: auto;
                  overflow-x: hidden;
                  li {
                    padding: 8px;
                    cursor: pointer;
                    border-bottom: 1px solid $color-light-light-gray;
                  }
                  li:hover {
                    background-color: #f0f0f0;
                  }

                  &::-webkit-scrollbar {
                    width: 8px;
                    height: 4px;
                    background-color: $color-light-light-gray;
                    border-radius: 10px;
                  }
                  &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                    border-radius: 10px;
                    background-color: $color-light-light-gray;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                    background-color: $color-oldSilver;
                  }
                }
              }
              .notes-field,
              .address-field {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                row-gap: 6px;
                justify-content: flex-start;
                align-items: flex-start;
                min-width: 365px;
                margin: 0 0 18px;
                width: 48%;
                label,
                textarea {
                  width: 100%;
                }
                label {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-darkLiver;
                }
                textarea {
                  border-radius: 6px;
                  border: 1px solid $color-light-light-gray;
                  background: $color-white;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  resize: none;
                  min-height: 105px;
                  padding: 12px;
                  color: $color-oldSilver;
                  &::placeholder {
                    font-style: italic;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1280px) {
      .popupModal__root {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.inner-popupModal {
  --height: 100%;
  width: 100%;
  height: var(--height);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  .inner-popup {
    border-radius: 6px;
    border: 1px solid $color-light-light-gray;
    background: $color-white;
    box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
    padding: 16px;
    margin: auto auto;
    margin-top: calc(var(--height) - 92%);
    width: 364px;
    &__head {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 12px;
      text-align: left;
      position: relative;
      color: $color-primary-dark;
      .title-name {
        display: inline-block;
        width: 95%;
        b {
          color: $color-light-red;
        }
      }
      .icon-close {
        display: inline-block;
        position: absolute;
        right: 0;
      }
    }
    .packages-change-des {
      border-radius: 2px;
      border: 1px solid $color-light-light-gray;
      width: 100%;
      min-height: 80px;
      margin: 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      resize: none;
      padding: 12px;
      color: $color-oldSilver;
    }
    .flex-formData-btn .submit-btn,
    .flex-formData-btn .cancel-btn {
      height: 34px !important;
      padding: 8px !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }
    .packageschange-error-msg {
      color: $color-light-red;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      padding: 0;
      height: 18px;
      text-align: right;
      display: block;
    }
  }
}

.popupModal.show.fullBleed {
  /** TICKET - TOOLS DATA POPUP **/
  .popupModal__container.showToolsData {
    align-items: flex-start;
    justify-content: flex-start;

    > .popupModal__root {
      width: 70%;
      margin: auto;
      padding: 25px 25px 25px;
      border-radius: 5px;
      max-width: 900px;
      margin: 70px auto 0;
      @media only screen and (max-width: 1280px) {
        & {
          padding: 18px 34px 18px;
          max-height: 100%;
          height: 92vh;
        }
      }
      > .popupModal__heading {
        h2 {
          text-align: justify;
          margin: 0 0 25px;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $color-black;
          nav {
            .breadcrumb {
              font-size: 13px;
            }
          }
        }
        .icon-close {
          right: 27px;
          height: 15px;
          top: 24px;
        }
      }
      > .popupModal__body {
        overflow-y: hidden;
        // padding: 0px 30px 0 0 !important;
        padding: 0 24px !important;
        margin: auto auto 0px;
        width: 100%;
        .remarks {
          margin: auto;
          .popupModal__heading {
            h2 {
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: $color-primary-dark;
              margin: 0;
              width: 90%;
            }
            .icon-close {
              right: 15px;
              height: 15px;
              top: 15px;
              font-size: 13px;
            }
          }
          .popupModal__root {
            width: 400px;
            min-height: 200px;
            height: fit-content;
            padding: 16px;
            margin: auto;
            .popupModal__body {
              padding: 0 !important;
            }
          }
        }
        .tools-data-main {
          .tool-body-content {
            overflow-y: auto;
            margin: 0 0 0px;
            padding: 0 10px 0 0;
            &::-webkit-scrollbar {
              width: 6px;
              height: 8px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
          }
          .tool-container {
            padding-bottom: 15px;
            display: flex;
            flex-flow: column;
            gap: 8px;
            .heading {
              text-align: justify;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-black;
            }
            .d-flex {
              justify-content: space-between;
              align-items: center;
              margin: 7px 0 0;
              .task-status {
                display: inline-flex;
                align-items: center;
                gap: 15px;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                .show-task-status {
                  display: inline-flex;
                  align-items: center;
                  border-radius: 6px;
                  justify-content: space-between;
                  border: 1px solid $color-light-light-gray;
                  background: $color-white;
                  width: 150px;
                  .status {
                    color: $color-darkLiver;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    &-pause {
                      color: $color-orange;
                    }
                    &-completed {
                      color: $color-light-green;
                    }
                  }
                  .showToolTip {
                    width: 100%;
                    padding: 6px 12px;
                    height: 35px;
                    .icon-chevron-thin-down {
                      display: inline-block;
                      background: $color-white;
                      border: none;
                      &::before {
                        font-size: 12px;
                        vertical-align: -2px;
                        font-weight: 700;
                      }
                      &.active {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        &::before {
                          vertical-align: 4px;
                        }
                      }
                    }
                    .popup-content {
                      top: 42px;
                      right: 2px;
                    }
                  }
                }
                .edit-status {
                  background-color: unset;
                  border: unset;
                }
              }
            }
            .suggestedMembers-teamNeme {
              display: block;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: $color-darkLiver;
              margin: auto;
              width: fit-content;
              white-space: nowrap;
              text-transform: capitalize;
              text-align: center;
              border-bottom: 1px solid $color-gray;
            }
            .warning-message {
              background: unset;
              border: unset;
              font-size: 11px;
              font-weight: 400;
              font-style: normal;
              line-height: normal;
              color: $color-light-red;
              margin: 0 0 0 0;
            }
            .task-assignee {
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              padding: 8px 0 8px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              column-gap: 20px;
              &-head {
                display: block;
                margin: 0;
                width: fit-content;
                white-space: nowrap;
                text-transform: capitalize;
                background-color: $color-primary-dark-120;
                color: $color-gray;
                border-radius: 4px;
                padding: 3px 8px 5px;
                font-weight: 500;
              }
              &-colon {
                display: block;
                margin: 0;
                width: fit-content;
                white-space: nowrap;
                text-transform: capitalize;
              }
              &-add {
                background: unset;
                border: unset;
                font-size: 13px;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
                color: $color-oldSilver;
                img {
                  width: 18px;
                  height: 18px;
                }
              }

              .showToolTip {
                width: fit-content;
                align-items: center;
                .update-status {
                  width: fit-content;
                  font-size: 13px;
                  font-weight: 400;
                  font-style: normal;
                  line-height: normal;
                  color: $color-oldSilver;
                  padding: 0 0 0;
                }
                .showPopup {
                  padding: 8px 4px 8px 8px;
                  .tip-entry-main {
                    min-height: 50px;
                    max-height: 150px;
                    overflow-x: auto;
                    width: 180px;
                    padding: 2px 8px 0 2px;
                    &::-webkit-scrollbar {
                      width: 4px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                  }
                }
              }
              .flex-data {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                column-gap: 12px;
                .task-assignee-name {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-black;
                }
              }
              sup {
                background: unset;
                border: unset;
                font-size: 13px;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
                color: var(--color-light-red);
                display: block;
                margin-left: -18px;
                vertical-align: baseline;
              }
              .display-icon {
                display: inline-flex;
                align-items: center;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                fill: var(--color-white);
                border: 1px solid var(--color-primary);
                vertical-align: middle;
                .user-icon {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 100%;
                  text-align: center;
                  border-radius: 50%;
                  color: var(--color-white);
                }
              }
            }
            .collection-of-taskAssignee {
              display: flex;
              flex-direction: row;
              column-gap: 10px;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
              margin: 10px 0 0;
              .task-assignee {
                column-gap: 8px;
                &-head {
                  width: 160px;
                  font-weight: 500;
                }
              }
            }
          }
          .task {
            margin: 0 0 24px;
            &-header {
              display: flex;
              justify-content: space-between;
              h3 {
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0 0 10px;
              }
            }
            &-card {
              display: flex;
              align-items: normal;
              gap: 20px;
              flex-wrap: wrap;
              border: 1px solid #e0e0e0;
              padding: 12px;
              border-radius: 5px;
              // justify-content: space-between;
              .defaultFieldList {
                display: inline-flex;
                gap: 20px;
                flex-wrap: wrap;
              }
              .dynamicFieldList {
                h4 {
                  color: $color-darkLiver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
              .list {
                min-width: 200px;
                h4 {
                  color: $color-darkLiver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                ul {
                  margin: 0 0 10px 0;
                  padding-left: 24px;
                  li {
                    color: $color-oldSilver;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    word-wrap: break-word;
                    line-height: normal;
                  }
                }
              }
            }
            .collapseCard {
              border-radius: 4px;
              padding: 0px 0px 0px;
              margin: 24px 0px 0px;
              overflow: visible;
              header {
                position: relative;
                z-index: 50;
              }
              &:last-child {
                /*border-bottom: 1px solid $color-platinum;*/
              }
              &__heading {
                margin: 0;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-darkLiver;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $color-light-gray;
                z-index: 100;
                padding: 0;
                border-radius: 6px;
                cursor: pointer;
                &-text {
                  display: inline-block;
                  width: 100%;
                  padding: 10px 16px 10px;
                }
                .flex-content {
                  display: inline-flex;
                  flex-direction: row;
                  column-gap: 8px;
                  padding: 10px 16px 10px 0;
                  .create-addTask {
                    background: $color-primary-dark;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $color-white;
                    border-radius: 4px;
                    border: none;
                    box-shadow: 1px 2px 5px $color-primary-dark;
                    padding: 4px 6px;
                  }
                  .icon-chevron-thin-down {
                    display: inline-block;
                    background: $color-white;
                    border: none;
                    &::before {
                      font-size: 12px;
                      vertical-align: -2px;
                      font-weight: 700;
                    }
                  }
                }
                &.active {
                  background-color: transparent;
                  color: initial;
                  .icon-chevron-thin-down {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    &::before {
                      vertical-align: 4px;
                    }
                  }
                }
              }
              .collapseCard__body {
                display: block;
                overflow: visible;
                background: $color-light-ghost-white;
                /* border-top: 1px solid $color-platinum;*/
                border-radius: 0px 0px 5px 5px;
                margin: 0 0;
                position: relative;
                font-size: 15px;
                color: $color-darkLiver;
                .no_data_found {
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 100%;
                  color: $color-darkLiver;
                  margin: 0 0;
                  text-align: center;
                  padding: 10px 8px;
                  background-color: $color-light-gray;
                }
                .add-task-box {
                  margin: 0;
                  padding: 11px;
                  border-radius: 4px;
                  background: $color-light-light-gray;
                  .add-task {
                    border-radius: 4px;
                    margin: 0;
                    background: $color-white;
                    padding: 16px;
                    .add-heading {
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      color: $color-darkLiver;
                    }
                    .data-dependency-input {
                      width: 100%;
                      border-radius: 4px;
                      border: 1px solid $color-light-light-gray;
                      padding: 7px 12px;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-darkLiver;
                    }
                    .flex-row-data {
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      column-gap: 20px;
                      align-items: center;
                      margin: 10px 0 0px;
                      .assignee-head {
                        margin: 0;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-darkLiver;
                      }
                      .assignee-div {
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-darkLiver;
                      }
                      .add-assignee-name {
                        background: $color-white;
                        padding: 0;
                        border: none;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-oldSilver;
                      }
                      .add-assignee {
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        background: $color-primary-dark;
                        color: $color-white;
                        padding: 6px 10px;
                        text-align: center;
                        display: block;
                        border: none;
                        border-radius: 4px;
                      }
                      .cancel-assignee {
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        display: block;
                        padding: 6px 10px;
                        text-align: center;
                        border: none;
                        border-radius: 4px;
                        color: $color-black;
                        background: $color-white;
                        border: 1px solid $color-light-light-gray;
                      }
                    }
                    .task-assignee {
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-darkLiver;
                      padding: 8px 0 15px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      column-gap: 20px;
                      &-head {
                        display: block;
                        margin: 0;
                        width: fit-content;
                        white-space: nowrap;
                      }
                      &-add {
                        background: unset;
                        border: unset;
                        font-size: 13px;
                        font-weight: 400;
                        font-style: normal;
                        line-height: normal;
                        color: $color-oldSilver;
                        img {
                          width: 18px;
                          height: 18px;
                        }
                      }
                      .showToolTip {
                        width: fit-content;
                        align-items: center;
                        .update-status {
                          width: fit-content;
                          font-size: 13px;
                          font-weight: 400;
                          font-style: normal;
                          line-height: normal;
                          color: $color-oldSilver;
                          padding: 4px 0 0;
                        }
                        .showPopup {
                          padding: 8px 4px 8px 8px;
                          .tip-entry-main {
                            min-height: 40px;
                            max-height: 150px;
                            overflow-x: auto;
                            width: 180px;
                            padding: 2px 8px 0 2px;
                            &::-webkit-scrollbar {
                              width: 4px;
                              height: 4px;
                              background-color: $color-light-light-gray;
                              border-radius: 10px;
                            }
                            &::-webkit-scrollbar-track {
                              -webkit-box-shadow: inset 0 0 6px
                                $color-light-light-gray;
                              border-radius: 10px;
                              background-color: $color-light-light-gray;
                            }
                            &::-webkit-scrollbar-thumb {
                              border-radius: 10px;
                              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                              background-color: $color-oldSilver;
                            }
                          }
                        }
                      }
                      .flex-data {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        column-gap: 12px;
                        .task-assignee-name {
                          font-size: 13px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          color: $color-black;
                        }
                      }

                      .display-icon {
                        display: inline-flex;
                        align-items: center;
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        fill: var(--color-white);
                        border: 1px solid var(--color-primary);
                        vertical-align: middle;
                        .user-icon {
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                          width: 100%;
                          height: 100%;
                          font-weight: 500;
                          font-size: 10px;
                          line-height: 100%;
                          text-align: center;
                          border-radius: 50%;
                          color: var(--color-white);
                        }
                      }
                    }
                  }
                }
                .task-owner {
                  display: inline-flex;
                  align-items: center;
                  border-radius: 50%;
                  width: 18px;
                  height: 18px;
                  fill: $color-white;
                  border: 1px solid $color-primary;
                  vertical-align: middle;
                  .user-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 100%;
                    text-align: center;
                    border-radius: 50%;
                    color: var(--color-white);
                  }
                }
                .collapseCard {
                  overflow: visible;
                  margin: 0;
                  padding: 0;
                  border: none;
                  border-bottom: 1px solid $color-light-gray;
                  &:last-child {
                    .collapseCard__heading {
                      border: none;
                      &.active {
                        border-bottom: 1px solid $color-light-gray;
                      }
                    }
                  }
                  .collapseCard__heading {
                    padding: 5px 20px 5px 20px;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    border-bottom: 1px solid $color-light-gray;
                    color: $color-darkLiver;
                    /*background-color: $color-light-ghost-white;*/
                    background: transparent;
                    .flex-row-data {
                      display: flex;
                      flex-direction: row;
                      column-gap: 10px;
                      align-items: center;
                      justify-content: flex-end;
                    }
                    .icon-chevron-thin-down {
                      font-size: 13px;
                    }
                    &.active {
                      background-color: $color-light-ghost-white;
                      color: initial;
                      .icon-chevron-thin-down {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        &::before {
                          vertical-align: 2px;
                        }
                      }
                    }
                    .showSubTask-heading {
                      display: inline-flex;
                      flex-direction: row;
                      column-gap: 10px;
                      width: 100%;
                      button {
                        background-color: transparent;
                        border: none;
                      }
                    }
                    .update-task-status {
                      background-color: transparent;
                      border: none;
                    }
                  }
                  .collapseCard__body {
                    background: $color-white;
                    padding: 11px 12px;
                    .flex-row-data {
                      display: flex;
                      flex-direction: row;
                      column-gap: 20px;
                      align-items: center;
                      justify-content: space-between;
                      .cols {
                        display: flex;
                        flex-direction: row;
                        column-gap: 20px;
                        align-items: center;
                        justify-content: flex-start;
                        .assignee-div,
                        .assignee-head {
                          font-size: 15px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          color: $color-darkLiver;
                          margin: 0;
                        }
                        .add-assignee-name {
                          font-size: 13px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          color: $color-black;
                          padding-right: 12px;
                        }
                        .assignee {
                          &-open {
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: $color-darkLiver;
                          }
                          &-in-progress {
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: $color-orange-lighter;
                          }
                          &-pause,
                          &-paused {
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: $color-orange;
                          }
                          &-completed {
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: $color-light-green;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .links {
            .accordion .card {
              border: 1px solid #e0e0e0;
              &-header {
                background: inherit;
                padding: 0;
                border: unset;
                button {
                  color: #4f4f4f;
                  font-family: Inter;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  width: 100%;
                  text-align: left;
                  height: 42px;
                  padding-left: 20px;
                  border: unset;
                  background: unset;
                }

                .icon-chevron-thin-down {
                  display: inline-block;
                  color: #082b45;

                  &::before {
                    font-size: 12px;
                    vertical-align: -2px;
                    font-weight: 700;
                  }
                }

                .active .icon-chevron-thin-down::before,
                .active .icon-chevron-thin-down {
                  transform: rotate(180deg);
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                }
                .child {
                  padding-left: 10px;
                }
              }
              &-body {
                padding: unset;
                table {
                  width: 100%;
                  tr {
                    border-top: 1px solid #e0e0e0;
                  }
                  tr:not(:last-child) {
                    border-bottom: 1px solid #e0e0e0;
                  }
                  td {
                    color: #4f4f4f;
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    height: 42px;
                  }
                  td:first-child {
                    width: 22%;
                    padding-left: 12px;
                  }
                  td:nth-child(2) {
                    width: 40%;
                  }
                  td:last-child {
                    a {
                      text-decoration: unset;
                      color: #828282;
                    }
                  }
                }
              }
            }
          }

          .documents {
            /*padding-top: 25px;*/
            h4 {
              color: #4f4f4f;
              font-family: Inter;
              font-size: 17px;
              font-weight: 500;
            }
            &-card {
              border-radius: 4px;
              border: 1px solid #e0e0e0;
              height: 130px;
              width: 100%;
              .doc-body {
                height: 85px;
                width: 100%;
              }
              button {
                border-radius: 4px;
                background: #f2f2f2;
                height: 28px;
                margin: 8px;
                width: 98.2%;
                border: unset;
                color: #828282;
                font-family: Inter;
                font-size: 13px;
                font-weight: 500;
                img {
                  padding-right: 10px;
                }
              }
            }
          }

          .ticket_info_container_activity {
            margin: 0 0 50px;
          }
        }
      }
    }
  }

  /** TICKET - SALES DATA POPUP **/
  .popupModal__container {
    // align-items: flex-start;
    // justify-content: flex-start;
    &.showUpdatedSalesData {
      .popupModal__root {
        width: fit-content;
        height: 90vh;
        margin: auto;
        padding: 25px;
        border-radius: 5px;
        margin: 70px auto 0;
        @media only screen and (max-width: 1280px) {
          & {
            padding: 18px 34px 18px;
          }
        }
        .popupModal__heading {
          h2 {
            text-align: justify;
            margin: 0 0 25px;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            span{
              background: $color-lighter-green4;
              font-size: 12px;
              padding: 2px 10px;
            }
          }
          .icon-close {
            right: 27px;
            height: 15px;
            top: 24px;
            @media only screen and (max-width: 1280px) {
              & {
                right: 10px;
              }
            }
          }
        }
        .popupModal__body {
          overflow-y: auto;
          padding: 0px 30px !important;
          margin: auto auto 10px;
          width: 100%;
          max-width: 850px;
          .datafield-head {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
          }
          .flex-inputs {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 24px;
            justify-content: flex-start;
            width: 100%;
            &.disabled,
            &:disabled {
              pointer-events: none;
              cursor: not-allowed;
              .react-dropdown-select {
                pointer-events: none;
                cursor: not-allowed;
              }
            }
            .search-icon {
              display: none;
            }
            .input-field {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
              row-gap: 6px;
              justify-content: flex-start;
              align-items: flex-start;
              min-width: 362px;
              width: 48%;
              margin: 0 0 18px;

              .toggle-switch {
                width: fit-content;
                padding: 8px 0;
                label {
                  margin: 0;
                }
                .toggle-switch-label {
                  width: 44px;
                  height: 24px;
                  .switch::before {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .toggle-switch-label
                input[type="checkbox"]:checked
                + .switch::before {
                top: 2px;
                transform: translateX(22px);
              }
              @media only screen and (max-width: 1280px) {
                & {
                  min-width: 340px;
                }
              }
              div {
                width: 100%;
              }
              .react-dropdown-select-content {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-gray;
                text-overflow: ellipsis;
                .react-dropdown-select-option {
                  background: transparent;
                  color: $color-gray;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  white-space: nowrap;
                  margin: 0;
                  position: relative;
                  &::before {
                    position: absolute;
                    content: ",";
                    right: 0px;
                    bottom: 0;
                  }
                  &:nth-last-child(2)::before {
                    content: "";
                  }
                  .react-dropdown-select-option-remove {
                    display: none;
                  }
                }
              }
              .currency-dropdownRenderer {
                background: $color-light-ghost-white;
                padding: 13px 15px 13px 12px;
                height: 45px;
                border-radius: 6px;
              }
              .react-dropdown-select-dropdown-handle {
                width: fit-content;
                display: none;
              }
              .fs-opf-eye-dropper:before {
                content: "\e925";
                color: #014a87;
              }

              position: relative;
              .flex-col {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: baseline;
                margin: 0;
                width: 100%;
                line-height: 100%;
                padding: 0;
                .remove-field {
                  display: none;
                }
                .head-field {
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-darkLiver;
                  border: none;
                }
              }
              .flex-col2 {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: baseline;
                margin: 0;
                line-height: 100%;
                padding: 0;
                width: 100%;
                .add-field {
                  display: none;
                }
              }

              .regionDropDownList {
                min-width: 100%;
                width: 100%;
                height: 42px;
                @media only screen and (max-width: 1280px) {
                  & {
                    min-width: 100%;
                    width: 100%;
                  }
                }
                .react-dropdown-select-content {
                  font-size: 15px;
                  color: $color-oldSilver;
                }
              }
              .packageList {
                min-width: 100%;
                width: 100%;
                height: 42px;
                .react-dropdown-select-content {
                  font-size: 15px;
                  color: $color-oldSilver;
                }
              }
              .label,
              label,
              .input-type {
                width: 100%;
                &.package {
                  user-select: none;
                  pointer-events: none;
                }
              }
              .search-icon {
                position: absolute;
                right: 12px;
                top: 35px;
              }
              label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                border: none;
                &.disabelEdit {
                  pointer-events: none;
                }
                &.clicked {
                  border: 1px solid $color-light-light-gray;
                }
              }
              .label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                border: none;
                &.disabelEdit {
                  pointer-events: none;
                }
                &.clicked {
                  border: 1px solid $color-light-light-gray;
                }
              }

              .input-type {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-light-ghost-white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                height: 42px;
                color: $color-gray;
                padding: 9px 14px;
                text-overflow: ellipsis;
                &.market-data {
                  color: $color-oldSilver;
                }
                &::placeholder {
                  color: $color-silver;
                }
              }

              .suggestion-list {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                width: 100%;
                background-color: $color-white;
                border: 1px solid $color-light-light-gray;
                border-radius: 4px;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                color: var(--color-oldSilver);
                max-height: 200px;
                overflow: auto;
                overflow-x: hidden;
                li {
                  padding: 8px;
                  cursor: pointer;
                  border-bottom: 1px solid $color-light-light-gray;
                }
                li:hover {
                  background-color: #f0f0f0;
                }

                &::-webkit-scrollbar {
                  width: 8px;
                  height: 4px;
                  background-color: $color-light-light-gray;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                  border-radius: 10px;
                  background-color: $color-light-light-gray;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                  background-color: $color-oldSilver;
                }
              }

              .color-sample {
                width: 22px;
                height: 22px;
                position: absolute;
                right: 12px;
                top: 35px;
                cursor: pointer;
                border: 1px solid $color-light-light-gray;
              }
              .color-picker {
                position: absolute;
                top: 66px;
                background: #fff;
                box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
                z-index: 99;
                padding: 10px;
                right: 0;
              }
            }
            .notes-field,
            .address-field {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
              row-gap: 6px;
              justify-content: flex-start;
              align-items: flex-start;
              min-width: 365px;
              margin: 0 0 18px;
              width: 100%;
              label,
              textarea {
                width: 100%;
              }
              label {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
              }
              .notes-value,
              .address-value,
              textarea {
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-light-ghost-white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                resize: none;
                min-height: 105px;
                padding: 12px;
                color: $color-oldSilver;
                width: 100%;
                word-wrap: break-word;
                &::placeholder {
                  font-style: italic;
                }
              }
              .notes-value,
              .address-value {
                span {
                  pointer-events: none;
                  cursor: not-allowed;
                  display: block;
                }
              }
            }
          }
          .flex-toollist-head {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-gray;
            margin: 0 0 12px;
          }
          .flex-toollist {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 0 24px 0;
            &.disabled,
            &:disabled {
              pointer-events: none;
              cursor: not-allowed;
              user-select: none;
            }
            .flex-inner-col1 {
              min-width: 83px;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-darkLiver;
              padding: 8px;
              padding-right: 0;
            }
            .flex-inner-col2 {
              min-width: 10px;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-darkLiver;
              padding: 8px 16px 8px 0px;
            }
            .flex-inner-col3 {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 8px;
              align-items: flex-start;
              justify-content: flex-start;
              .tool-name {
                border-radius: 4px;
                background: $color-silver;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
                padding: 8px;
                display: block;
                margin: 0 0 8px 0;
              }
            }
          }
          .flex-formData-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            column-gap: 20px;
            .cancel-btn {
              display: block;
              width: 100px;
              height: 38px;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              border: none;
              line-height: normal;
              color: $color-white;
              border-radius: 35.202px;
              border: 1px solid $color-light-light-gray;
              color: $color-darkLiver;
              padding: 8px;
              text-align: center;
              cursor: pointer;
            }
            .submit-btn {
              display: block;
              min-width: 85px;
              text-align: center;
              height: 38px;
              font-size: 15px;
              font-style: normal;
              padding: 2px 16px;
              font-weight: 400;
              border: none;
              line-height: normal;
              color: $color-white;
              border-radius: 35.202px;
              background: $color-primary-dark;
              position: relative;
              &.loading {
                background: $color-primary-dark;
                padding-right: 35px;
              }
              &.loading:after {
                content: "";
                position: absolute;
                border-radius: 100%;
                right: 6px;
                top: 50%;
                width: 0px;
                height: 0px;
                margin-top: -2px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left-color: #fff;
                border-top-color: #fff;
                animation: spin 0.6s infinite linear,
                  grow 0.3s forwards ease-out;
              }
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
        }
      }
    }
  }
}
