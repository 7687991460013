.team__page {
  margin: 0;
  transition: padding 0.3s;
  &__header {
    padding: 50px 25px 80px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    &.right-align {
      justify-content: flex-end;
    }
    @media (max-width: 1366px) {
      height: 60px;
    }
    .meteam-heading {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-gray;
      margin: 0;
    }
    &-tabs {
      column-gap: 10px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid var(--color-light-light-gray);
      border-radius: 8px;
      padding: 6px;
      & li {
        color: $color-gray;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        position: relative;
        user-select: none;
        padding: 6px 12px;
        border-radius: 6px;
        width: 120px;
        background-color: $color-light-ghost-white;
        &.active {
          background-color: $color-primary-dark;
          color: $color-white;
        }
      }
    }
    & .right-list {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
      .search-ticket {
        min-width: 304px;
        margin: 0;
      }
      .add-member-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        background: $color-primary-dark;
        color: $color-white;
        min-width: 100px;
        min-height: 45px;
        border-radius: 8px;
        padding: 8px 12px;
        border: none;
        display: inline-flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
        border: 1px solid $color-primary-dark;
        // &.active {
        //   background: $color-primary-dark;
        //   color: $color-white;
        // }
        .add-member-plus {
          font-size: 23px;
          font-weight: 300;
        }
      }
      .show_request {
        .approve-member-btn {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          background: $color-white;
          color: $color-oldSilver;
          line-height: 17px;
          min-width: 100px;
          min-height: 45px;
          border-radius: 8px;
          padding: 8px 12px;
          border: 1px solid $color-blue-4;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 8px;
          // &.active {
          //   background: $color-primary-dark;
          //   color: $color-white;
          //   .icon-Bell_Notification {
          //     font-size: 20px;
          //     .path3:before,
          //     .path4:before {
          //       color: $color-light-red;
          //     }
          //   }
          //   .icon-bell-img {
          //   width: 20px;
          //   }
          // }
          .icon-Bell_Notification {
            font-size: 20px;
            .path1:before,
            .path2:before {
              color: $color-blue-4;
            }
            .path3:before,
            .path4:before {
              color: $color-light-red;
            }
          }
          .icon-bell-img {
            filter: invert(1) sepia(1) saturate(10000%) hue-rotate(0deg);
            width: 20px;
          }
        }
      }
    }
  }
  .request-approval-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    padding: 0px 40px;
    button {
      background: none;
      border: none;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: $color-oldSilver;
      border-bottom: solid 2px $color-white;
      padding: 4px 8px 10px;
      &.active {
        font-weight: 600;
        border-bottom: solid 2px $color-primary-dark;
        color: $color-primary-dark;
      }
    }
  }
  &__body {
    background: $color-light-gray;
    border-top: 1px solid $color-light-light-gray;
    padding: 12px 0px;
    .roles__table {
      /*height: calc(100vh - 264px);*/
      .table-container {
        overflow: hidden;
        overflow-x: auto;
        overflow-y: auto;
        border-collapse: collapse;
        border-radius: 12px;
        border-bottom: 1px solid $color-light-light-gray !important;
        max-height: calc(100vh - 320px);
        thead {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          .is_active {
            text-align: left;
          }
        }
        tbody {
          border: 1px solid $color-light-light-gray;
          background-color: $color-white;
          border-bottom: none;
          border-radius: 0px;
          cursor: pointer;
          tr {
            td {
              font-size: 13px;
              font-weight: 400;
              line-height: 15px;
              &.is_active {
                text-align: left;
                padding-left: 20px;
              }
              .status {
                width: 110px;
              }
              button {
                border: none;
                font-size: 13px;
                font-weight: 400;
                line-height: 15px;
                text-align: center;
              }
              .approve_user {
                background: $color-primary-dark;
                padding: 6px 16px;
                border-radius: 24px;
                color: $color-white;
                margin: 0 15px 0 0;
                position: relative;
                &:disabled {
                  pointer-events: none;
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }
              .reject_user {
                background: $color-white;
                padding: 6px 16px;
                border-radius: 24px;
                color: $color-light-red;
                border: 1px solid $color-light-red;
                position: relative;
                &:disabled {
                  pointer-events: none;
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }

              .reason-for-rejected {
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                margin: 0;
                max-width: 250px;
                overflow: hidden;
                height: 18px;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            &:last-child {
              border-radius: 0 12px;
              td {
                border-radius: 0px;
                border-bottom: none;
              }
            }
          }
        }
      }
      .user-profile {
        display: flex;
        column-gap: 10px;
        &__image {
          display: inline-flex;
          position: relative;
          width: 42px;
          fill: var(--color-white);
          border-radius: 50%;
          height: 42px;
          align-items: center;
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .userNull-image {
            display: inline-flex;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            color: var(--color-white);
          }
          &__status {
            position: absolute;
            top: 32px;
            left: 32px;
            font-size: 10px;
          }
        }
        &__details {
          &__name {
            margin: 0;
            color: #333333;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          &__at {
            margin: 0;
            color: #4f4f4f;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            padding-top: 3px;
          }
        }
      }
      &.agencyNewRequests, &.agencyRejected {
        .table-container {
          thead {
            .is_active {
              text-align: right;
              width: 210px;
            }
          }
          tbody {
            cursor: unset;
            tr {
              td {
                &.is_active {
                  width: 210px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      &.agencyNewRequests {
        .table-container {
          tbody {
            tr {
              td {
                &.is_active {
                  width: 240px;
                  text-align: right;
                }
                .approve_user.loading,
                .reject_user.loading {
                  padding-right: 33px;
                }
                .approve_user.loading::before {
                  content: "";
                  position: absolute;
                  border-radius: 100%;
                  right: 6px;
                  top: 50%;
                  width: 0px;
                  height: 0px;
                  margin-top: -2px;
                  border: 2px solid rgba(255, 255, 255, 0.5);
                  border-left-color: #fff;
                  border-top-color: #fff;
                  animation: spin 0.6s infinite linear,
                    grow 0.3s forwards ease-out;
                }
                .reject_user.loading::before {
                  content: "";
                  position: absolute;
                  border-radius: 100%;
                  right: 6px;
                  top: 50%;
                  width: 0px;
                  height: 0px;
                  margin-top: -2px;
                  border: 2px solid $color-primary-dark-120;
                  border-left-color: $color-primary-dark;
                  border-top-color: $color-primary-dark;
                  animation: spin 0.6s infinite linear,
                    grow 0.3s forwards ease-out;
                }
              }
            }
          }
        }
      }
      &.agencyMembersList {
        .table-container {
          thead {
            .is_active {
              text-align: center;
              max-width: 250px;
            }
          }
          tbody {
            cursor: unset;
            tr {
              td {
                &.is_active {
                  max-width: 250px;
                  text-align: center;
                  .img-active,
                  .img-inactive{
                    cursor: pointer;
                    font-size: 45px;
                  }
                  .img-active.disabled,
                  .img-inactive.disabled {
                    pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.5;
                  }
                  .img-inactive {
                    padding-right: 10px;
                  }
                }
                &.status {
                  .active {
                    color: $color-light-green;
                  }
                  .inactive {
                    color: $color-light-yellow-2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .not-found {
      height: calc(100vh - 295px);
    }
  }
}
.flex-box-header-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}
.popupModal__container.add-team-member {
  .popupModal__root {
    padding: 30px 50px;
    .popupModal__heading .icon-close {
      right: 50px;
      top: 25px;
    }
  }
  .add-memeber-popup {
    padding: 26px 0 0;
    &__heading {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-gray;
      margin: 0 0 20px;
    }
    & .flex-box {
      display: flex;
      align-items: flex-start;
      column-gap: 50px;
      margin: 0px 0 30px 0;
      .flex-col1 {
        width: 365px;
      }
      .label-name {
        display: block;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        margin: 0 0 6px;
        sup {
          color: $color-light-red;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          vertical-align: -3px;
        }
      }
      .error-msg {
        margin: 4px 0 8px 0;
        padding: 0;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        color: $color-light-red;
        height: 12px;
        display: block;
      }
      .react-dropdown-select {
        border-radius: 6px;
        border: 1px solid $color-light-light-gray;
        background: $color-light-ghost-white;
        height: 42px;
        margin: 0 0 0px 0;
        padding: 13px 15px 13px 12px;
        .react-dropdown-select-content {
          color: $color-darkLiver;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          flex-direction: row;
          max-width: 315px;
          overflow: hidden;
          flex-wrap: nowrap;
          text-overflow: ellipsis;
          justify-content: flex-start;
        }
        .react-dropdown-select-dropdown-handle svg {
          vertical-align: -4px;
          width: 21px;
          height: 21px;
        }
        .react-dropdown-select-option {
          background: transparent;
          color: $color-darkLiver;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          margin: 0;
        }
      }
      .custom-dropdownRenderer {
        .react-dropdown-select-option {
          position: relative;
          &::before {
            position: absolute;
            content: ",";
            right: 0px;
            bottom: 0;
          }
          &:nth-last-child(2)::before {
            content: "";
          }
          .react-dropdown-select-option-remove {
            display: none;
          }
        }
        .react-dropdown-select-dropdown {
          overflow: visible;
          border-radius: 6px;
          .dropdwonList-main {
            padding: 12px 12px;
            .dropdwonList-head {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $color-gray;
              margin: 0 0 12px 0;
              padding-bottom: 12px;
              border-bottom: 1px solid $color-light-gray;
            }
            .dropdwonLists {
              overflow: auto;
              max-height: 200px;
              padding: 0px 12px 0px 12px;
              // margin: 0 8px 10px 0px;
              position: relative;
              &.single {
                margin: 0;
                padding: 0;
              }
              &::-webkit-scrollbar {
                width: 8px;
                height: 4px;
                background-color: $color-light-light-gray;
                border-radius: 10px;
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                border-radius: 10px;
                background-color: $color-light-light-gray;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                background-color: $color-oldSilver;
              }
              .error-show {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                padding: 0;
                color: $color-light-red;
                text-align: center;
              }
              .dropdwonList-subhead,
              .dropdwonLists-label {
                margin: 0 0 16px 0;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-gray;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                column-gap: 8px;
                &.disabled,
                &:disabled {
                  pointer-events: none;
                  cursor: not-allowed;
                  opacity: 0.5;
                }
                &:last-child {
                  margin: 0 0 0 0;
                }
                &.sub-list {
                  margin-left: 8px;
                }
                .lable-field {
                  display: inline-flex;
                  align-items: flex-start;
                  flex-direction: column;
                  row-gap: 0px;
                  padding: 0;
                  margin: 0 0 0 0px;
                  label {
                    cursor: pointer;
                  }
                  .mail-field {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-darkLiver;
                    overflow: hidden;
                    max-width: 250px;
                    text-overflow: ellipsis;
                  }
                }
                .checkbox-img {
                  width: 16px;
                  height: 100%;
                }
              }
            }
          }
        }
        &.member-list {
          .dropdwonList-main {
            padding: 20px 20px 20px 12px;
            .dropdwonLists-label {
              /*display: inline-block !important;*/
              &.disabled,
              &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
              .lable-field {
                margin: 0 0 0 8px !important;
              }
            }
            .flex-btn {
              float: right;
              display: flex;
              flex-direction: row;
              column-gap: 16px;
              margin: 12px 0 0;
              .reset-btn {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 35.202px;
                color: $color-primary-dark;
                border: 1px solid $color-primary-dark;
                background: $color-white;
                height: 31px;
                width: 71px;
              }
              .includemember-btn {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 35.202px;
                color: $color-white;
                background: $color-primary-dark;
                height: 31px;
                width: 71px;
              }
            }
          }
        }
      }
      .flex-col2 {
        border: 1px solid $color-light-light-gray;
        border-radius: 10px;
        margin: 0px 0px;
        .seleected-member-box {
          padding: 20px 0;
          margin: 0px;
          &__heading {
            color: $color-gray;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0 20px 20px 30px;
            border-bottom: 1px solid $color-light-light-gray;
          }
          .memeber-lists {
            width: 467px;
            min-height: 230px;
            max-height: 300px;
            overflow: auto;
            margin: 30px 10px 0 30px;
            &::-webkit-scrollbar {
              width: 8px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
            .flex-list {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 16px;
              .memeber-name {
                border-radius: 30px;
                background: $color-light-ghost-white;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-gray;
                padding: 7px 12px;
                margin: 0px 0 17px;
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                column-gap: 14px;
                align-items: center;
                .icon-close {
                  background-color: $color-light-red;
                  font-size: 12px;
                  cursor: pointer;
                  color: $color-white;
                  border-radius: 50px;
                  padding: 4px;
                }
              }
            }
          }
        }
      }
    }
    .flex-btn {
      float: right;
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      .cancel-btn {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 35.202px;
        color: $color-oldSilver;
        border: 1px solid $color-oldSilver;
        background: $color-white;
        height: 31px;
        width: 71px;
      }
      .submit-btn,
      .add-btn {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 35.202px;
        color: $color-white;
        background: $color-primary-dark;
        height: 31px;
        min-width: 71px;
        position: relative;
        padding: 0px 16px;
      }
      .submit-btn.loading,
      .add-btn.loading {
        background: $color-primary-dark;
        padding-right: 40px;
      }
      .submit-btn.loading::before,
      .add-btn.loading::before {
        content: "";
        position: absolute;
        border-radius: 100%;
        right: 6px;
        top: 50%;
        width: 0px;
        height: 0px;
        margin-top: -2px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-left-color: #fff;
        border-top-color: #fff;
        animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
      }
    }
  }
}

.roles__table .table-container {
  overflow: hidden;
  overflow-y: auto;
  margin: 0px 25px;
  border-radius: 12px;
  border-bottom: 1px solid $color-light-gray;
  @media (max-width: 1366px) {
    overflow: auto;
  }
  .spinner-table {
    width: 100%;
    border-radius: 12px;
    thead {
      border-radius: 12px;
    }
    th {
      padding: 10px;
      height: 100px;
      background-color: var(--color-primary-dark);
    }
    tr {
      border-bottom: 1px solid var(--color-light-gray);
      border-radius: 0 12px;
    }
    td {
      padding: 10px;
      height: 100px;
    }
    .animated-bg {
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 10%,
        #f6f7f8 20%,
        #f6f7f8 100%
      );
      background-size: 200% 100%;
      animation: bgPos 1s linear infinite;
    }
  }
}
@keyframes bgPos {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.popupModal__container.reject-user {
  .popupModal__root {
    padding: 16px;
    width: 397px;
    min-height: 177px;
    border-radius: 6px;
    .popupModal__heading {
      h2 {
        font-size: 15px;
        font-weight: 600;
        line-height: 18.15px;
        margin: 0 0 10px;
        text-align: left;
        color: $color-primary-dark;
      }
      .icon-close {
        color: $color-primary-dark;
        right: 20px;
        top: 17px;
      }
    }
    .remarks__container {
      display: flex;
      flex-direction: column;
      .error-msg {
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        color: $color-light-red;
        height: 12px;
        display: block;
        margin: 2px 0 0px 0;
        text-align: right;
      }
    }
    .input-field {
      width: 100%;
      height: 86px;
      resize: none;
      border-radius: 2px;
      padding: 8px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
    }
    .flex-disp {
      display: flex;
      flex-direction: row;
      margin: 10px 0 0;
      align-items: center;
      column-gap: 20px;
      justify-content: flex-end;
      .submit-btn {
        border-radius: 24px;
        background: $color-primary-dark;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        border: 1px solid transparent;
        cursor: pointer;
      }
      .cancel-btn {
        border-radius: 24px;
        border: 1px solid $color-oldSilver;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        color: $color-light-red;
        cursor: pointer;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(359deg);
  }
}
@keyframes grow {
  to {
    width: 14px;
    height: 14px;
    margin-top: -8px;
    right: 13px;
  }
}
