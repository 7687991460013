.notification__page {
  padding: 0;
  background-color: $color-white;
  &__header {
    padding: 25px 25px 15px;
    .breadcrumb {
      padding-bottom: 7px;
      li:not(:first-child) {
        color: $color-gray;
      }
    }
    .title {
      padding-top: 7px;
      color: $color-primary-dark;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 25px;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
      border-bottom: 1px solid $color-light-light-gray;
      padding: 0 25px;
      button {
        background-color: transparent;
        border: none;
        padding: 10px;
        color: $color-darkLiver;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-bottom: 2px solid transparent;
        &.active {
          font-weight: 500;
          line-height: normal;
          color: $color-gray;
          border-bottom: 2px solid $color-primary-dark;
        }
        .unread-count {
          display: inline-block;
          color: $color-white;
          background: $color-primary;
          max-width: 160px;
          min-width: 27px;
          padding: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          border-radius: 50px;
          margin: 0 0 0 10px;
        }
      }
    }
  }
  &__container {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    background-color: $color-light-ghost-white;
    gap: 15px;
    min-height: calc(100vh - 230px);
    height: calc(100vh - 240px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgb(167, 165, 165);
    }
    &.page-loading {
      -webkit-animation: loading 2s ease-in-out infinite;
      animation: loading 2s ease-in-out infinite;
      background: linear-gradient(
        90deg,
        $color-light-ghost-white 25%,
        $color-light-light-gray-1 50%,
        $color-light-light-gray-4 75%
      );
      background-size: 200% 100%;
      .loader {
        margin: auto;
        width: 200px;
        left: 80px;
        right: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span {
          font-family: inherit;
          font-weight: 500;
          letter-spacing: 2px;
        }
      }
    }
    @keyframes loading {
      0% {
        background-position: 200% 0;
      }
      100% {
        background-position: -200% 0;
      }
    }
    &.vheaight {
      height: calc(100vh - 230px);
    }
    .nodata-found {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      img {
        margin: auto;
      }
      .nodata-found-head {
        font-size: 24px;
        font-weight: 500;
        line-height: 29.05px;
        text-align: center;
        margin: auto;
        width: 100%;
      }
    }
    .divider-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      text-align: left;
      color: $color-primary-dark;
      margin: 0 0 15px;
    }
    &-card {
      padding: 16px;
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      gap: 16px;
      margin: 0 0 15px;
      color: $color-primary-dark;
      // border: 1px solid $color-light-light-gray;
      &_image {
        position: relative;
        width: 32px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        span{
          font-size: 32px;
        }
      }
      &_details {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: baseline;
        &-top--row {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        &-header {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.6px;
          text-align: left;
          color: $color-primary-dark;
          margin: 0;
          padding: 0;
          width: 75%;
          .board-name {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            text-decoration-skip-ink: none;
            border-radius: 20px;
            color: $color-white;
            display: inline-block;
            width: fit-content;
            padding: 2px 8px;
            margin: 0 0 0 10px;
            background-color: $color-primary-light-5;
            text-transform: capitalize;
            &.periodic-updates {
              background-color: $color-light-green;
            }
          }
        }
        &--row {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          .edit-icon {
            margin: 0;
            padding: 0;
            width: 30px;
            .popup-content {
              width: 174px;
              top: 30px;
              border-radius: 6px !important;
              .tip-entry {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: left;
                color: $color-primary-dark;
                padding: 10px !important;
              }
            }
            .update-status {
              justify-content: center;
              align-items: center;
              column-gap: 0px;
              img {
                width: 25px;
              }
            }
          }
        }
        &_html {
          margin: 0;
          color: $color-oldSilver;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          &_msg {
            margin: 0;
            color: #1e1919;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            max-height: 80px;
            min-height: 25px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            p {
              margin: 0;
              font-size: 12px;
              span {
                font-size: 12px;
              }
            }
          }
        }
        &_msg {
          margin: 0;
          color: $color-oldSilver;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        &_updatedOn {
          margin: 0;
          padding: 0px;
          color: rgba(82, 74, 62, 0.82);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
      &-image {
        span {
          font-size: 24px;
        }
      }
      &-content {
        display: inline-flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &-title {
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: $color-primary-dark;
          margin: 0;
          display: inline-flex;
          gap: 8px;
          align-items: center;
          span:last-child {
            font-size: 13px;
            font-weight: 400;
            color: $color-oldSilver;
          }
          .warning {
            color: $color-light-red;
          }
        }
        &-body {
          color: $color-darkLiver;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: inline-flex;
          gap: 4px;
          align-items: center;
          span {
            font-weight: 500;
            color: $color-gray;
            text-transform: capitalize;
          }
          .updated_on {
            font-size: 13px;
            font-weight: 400;
            color: $color-oldSilver;
          }
        }
        &-btn {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          min-height: 40px;
          align-items: center;
          gap: 8px;
          padding-top: 8px;
          &-view {
            padding: 8px 16px;
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 8px;
            background-color: $color-white;
          }
          &-markread {
            padding: 8px 16px;
            color: $color-primary-dark;
            border: none;
            background-color: $color-white;
          }
        }
      }
    }
    .unread {
      background: $color-primary-light-4;
      position: relative;
      cursor: pointer;
      border: 1px solid transparent;
      &::before {
        contain: content;
        content: " ";
        position: absolute;
        left: 8px;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 25px;
        background-color: $color-blue-2;
      }
      &:hover {
        border: 1px solid $color-primary;
      }
    }
    .read {
      cursor: pointer;
      border: 1px solid transparent;
      background-color: $color-white;
      &:hover {
        border: 1px solid $color-primary;
      }
    }
  }
}
