$icomoon-font-family: "Orion" !default;
$icomoon-font-path: "fonts" !default;

$icon-copy: "\eaaa";
$icon-redirect: "\eaac";
$icon-trash: "\eaad";
$icon-plus-circle: "\eaae";
$icon-info: "\eaaf";
$icon-three-dots: "\eab0";
$icon-drag-drop: "\eab1";
$icon-comment: "\eab2";
$icon-star-active: "\eab3";
$icon-time_empty-path1: "\eab4";
$icon-time_empty-path2: "\eab5";
$icon-time_empty-path3: "\eab6";
$icon-edit-horizontal-icons1: "\eab7";
$icon-upload_file: "\eab8";
$icon-description: "\eab9";
$icon-certificate-check-path1: "\eaba";
$icon-certificate-check-path2: "\eabb";
$icon-certificate-check-path3: "\eabc";
$icon-certificate-check-path4: "\eabd";
$icon-certificate-check-path5: "\eabe";
$icon-certificate-check-path6: "\eabf";
$icon-time-path1: "\eac0";
$icon-time-path2: "\eac1";
$icon-time-path3: "\eac2";
$icon-timer-path1: "\eac3";
$icon-timer-path2: "\eac4";
$icon-timer-path3: "\eac5";
$icon-timer-path4: "\eac6";
$icon-list-box-path1: "\eac7";
$icon-list-box-path2: "\eac8";
$icon-list-box-path3: "\eac9";
$icon-list-box-path4: "\eaca";
$icon-list-box-path5: "\eacb";
$icon-download_file: "\eacc";
$icon-check-list: "\eacd";
$icon-ordered-list: "\eace";
$icon-bullet-list: "\eacf";
$icon-underline: "\ead0";
$icon-strike: "\ead1";
$icon-italic: "\ead2";
$icon-bold: "\ead3";
$icon-status-in-progress-path1: "\ead4";
$icon-status-in-progress-path2: "\ead5";
$icon-status-in-progress-path3: "\ead6";
$icon-status-in-progress-path4: "\ead7";
$icon-status-pause-path1: "\ead8";
$icon-status-pause-path2: "\ead9";
$icon-status-pause-path3: "\eada";
$icon-users: "\eadb";
$icon-access-pending: "\eadc";
$icon-access-request: "\eadd";
$icon-warning: "\eade";
$icon-remainder: "\eadf";
$icon-kanban: "\eae0";
$icon-ss-calendar: "\eae1";
$icon-unsupported-file: "\eae2";
$icon-archive-file: "\eae3";
$icon-doc-file: "\eae4";
$icon-image-file: "\eae5";
$icon-pdf-file: "\eae6";
$icon-xls-file: "\eae7";
$icon-remove-cross-path1: "\eae8";
$icon-remove-cross-path2: "\eae9";
$icon-yet-to-launch: "\eaea";
$icon-ir-tools-only: "\eaeb";
$icon-failure-cross1-path1: "\eaec";
$icon-failure-cross1-path2: "\eaed";
$icon-ir-medium: "\eaee";
$icon-ir-plus: "\eaef";
$icon-ir-premium: "\eaf0";
$icon-ir-basic: "\eaf1";
$icon-success-tick1-path1: "\eaf2";
$icon-success-tick1-path2: "\eaf3";
$icon-unchecked: "\eaf4";
$icon-checked: "\eaf5";
$icon-search: "\eaf6";
$icon-away: "\eaf7";
$icon-busy: "\eaf8";
$icon-active: "\eaf9";
$icon-wave-path1: "\eafa";
$icon-wave-path2: "\eafb";
$icon-wave-path3: "\eafc";
$icon-wave-path4: "\eafd";
$icon-wave-path5: "\eafe";
$icon-wave-path6: "\eaff";
$icon-wave-path7: "\eb00";
$icon-euroland-icon: "\eb01";
$icon-euroland-icon-primary-path1: "\eb02";
$icon-euroland-icon-primary-path2: "\eb03";
$icon-euroland-icon-primary-path3: "\eb04";
$icon-euroland-icon-primary-path4: "\eb05";
$icon-Chat_Circle: "\eb06";
$icon-Mail: "\eb07";
$icon-Phone: "\eb08";
$icon-Bell_Notification-path1: "\eb09";
$icon-Bell_Notification-path2: "\eb0a";
$icon-Bell_Notification-path3: "\eb0b";
$icon-Bell_Notification-path4: "\eb0c";
$icon-attachment: "\eb0d";
$icon-about-us: "\e900";
$icon-about-us-inactive: "\e901";
$icon-agent-board-icon: "\e904";
$icon-arrow-up: "\e907";
$icon-assigned-user-path1: "\e909";
$icon-assigned-user-path2: "\e90a";
$icon-attachment-icon: "\e90b";
$icon-blue-bell: "\e90c";
$icon-calendar-date-picker: "\e90d";
$icon-calendar-blue: "\e90e";
$icon-calendar-blue-slash: "\e90f";
$icon-calendar: "\e910";
$icon-card-assign: "\e911";
$icon-card-icon: "\e912";
$icon-caret-circle-left: "\e913";
$icon-caret-circle-right: "\e914";
$icon-clock-counter-clock-wise: "\e916";
$icon-clock-icon: "\e917";
$icon-close-icon-white: "\e919";
$icon-close-icon: "\e91b";
$icon-comment-icon: "\e91d";
$icon-contact-us-banner-path1: "\e920";
$icon-contact-us-banner-path2: "\e921";
$icon-contact-us-banner-path3: "\e922";
$icon-contact-us-banner-path4: "\e923";
$icon-contact-us-banner-path5: "\e924";
$icon-contact-us-banner-path6: "\e925";
$icon-contact-us-banner-path7: "\e926";
$icon-contact-us-banner-path8: "\e927";
$icon-contact-us-banner-path9: "\e928";
$icon-contact-us-banner-path10: "\e929";
$icon-contact-us-banner-path11: "\e92a";
$icon-contact-us-banner-path12: "\e92b";
$icon-contact-us-banner-path13: "\e92c";
$icon-contact-us-banner-path14: "\e92d";
$icon-contact-us-banner-path15: "\e92e";
$icon-contact-us-banner-path16: "\e92f";
$icon-contact-us-banner-path17: "\e930";
$icon-contact-us-banner-path18: "\e931";
$icon-contact-us-banner-path19: "\e932";
$icon-contact-us-banner-path20: "\e933";
$icon-contact-us-banner-path21: "\e934";
$icon-contact-us-banner-path22: "\e935";
$icon-contact-us-banner-path23: "\e936";
$icon-contact-us-banner-path24: "\e937";
$icon-contact-us-banner-path25: "\e938";
$icon-contact-us-banner-path26: "\e939";
$icon-contact-us-banner-path27: "\e93a";
$icon-contact-us-banner-path28: "\e93b";
$icon-contact-us-banner-path29: "\e93c";
$icon-contact-us-banner-path30: "\e93d";
$icon-contact-us-banner-path31: "\e93e";
$icon-contact-us-banner-path32: "\e93f";
$icon-contact-us-banner-path33: "\e940";
$icon-contact-us-banner-path34: "\e941";
$icon-contact-us-banner-path35: "\e942";
$icon-contact-us-banner-path36: "\e943";
$icon-contact-us-banner-path37: "\e944";
$icon-contact-us-banner-path38: "\e945";
$icon-contact-us-banner-path39: "\e946";
$icon-contact-us-banner-path40: "\e947";
$icon-contact-us-banner-path41: "\e948";
$icon-contact-us-banner-path42: "\e949";
$icon-contact-us-banner-path43: "\e94a";
$icon-contact-us-banner-path44: "\e94b";
$icon-contact-us-banner-path45: "\e94c";
$icon-contact-us-banner-path46: "\e94d";
$icon-contact-us-banner-path47: "\e94e";
$icon-contact-us-banner-path48: "\e94f";
$icon-contact-us-banner-path49: "\e950";
$icon-contact-us-banner-path50: "\e951";
$icon-contact-us-banner-path51: "\e952";
$icon-contact-us-banner-path52: "\e953";
$icon-contact-us-banner-path53: "\e954";
$icon-contact-us-banner-path54: "\e955";
$icon-contact-us-banner-path55: "\e956";
$icon-contact-us-banner-path56: "\e957";
$icon-contact-us-banner-path57: "\e958";
$icon-contact-us-banner-path58: "\e959";
$icon-contact-us-banner-path59: "\e95a";
$icon-contact-us-banner-path60: "\e95b";
$icon-contact-us-banner-path61: "\e95c";
$icon-contact-us-banner-path62: "\e95d";
$icon-contact-us-banner-path63: "\e95e";
$icon-contact-us-banner-path64: "\e95f";
$icon-contact-us-banner-path65: "\e960";
$icon-contact-us-banner-path66: "\e961";
$icon-contact-us-banner-path67: "\e962";
$icon-contact-us-banner-path68: "\e963";
$icon-contact-us-banner-path69: "\e964";
$icon-contact-us-banner-path70: "\e965";
$icon-contact-us-banner-path71: "\e966";
$icon-contact-us-banner-path72: "\e967";
$icon-contact-us-banner-path73: "\e968";
$icon-contact-us-banner-path74: "\e969";
$icon-contact-us-banner-path75: "\e96a";
$icon-contact-us-banner-path76: "\e96b";
$icon-contact-us-banner-path77: "\e96c";
$icon-contact-us-banner-path78: "\e96d";
$icon-contact-us-banner-path79: "\e96e";
$icon-contact-us-banner-path80: "\e96f";
$icon-contact-us-banner-path81: "\e970";
$icon-contact-us-banner-path82: "\e971";
$icon-contact-us-banner-path83: "\e972";
$icon-contact-us-banner-path84: "\e973";
$icon-contact-us-banner-path85: "\e974";
$icon-contact-us-banner-path86: "\e975";
$icon-contact-us-banner-path87: "\e976";
$icon-contact-us-banner-path88: "\e977";
$icon-contact-us-banner-path89: "\e978";
$icon-contact-us-banner-path90: "\e979";
$icon-contact-us-banner-path91: "\e97a";
$icon-contact-us-banner-path92: "\e97b";
$icon-contact-us-banner-path93: "\e97c";
$icon-contact-us-banner-path94: "\e97d";
$icon-contact-us-banner-path95: "\e97e";
$icon-contact-us-banner-path96: "\e97f";
$icon-cross-mark-not-fill: "\e980";
$icon-cross-mark: "\e981";
$icon-description-icon: "\e983";
$icon-due-date-calendar: "\e985";
$icon-edit-box-icon: "\e987";
$icon-email-icon: "\e98e";
$icon-failure-cross-path1: "\e98f";
$icon-failure-cross-path2: "\e990";
$icon-filter-icon: "\e991";
$icon-filter-rounded-path1: "\e992";
$icon-filter-rounded-path2: "\e993";
$icon-filter-funnel: "\e994";
$icon-financial-release-path1: "\e995";
$icon-financial-release-path2: "\e996";
$icon-financial-release-path3: "\e997";
$icon-green-bell: "\e998";
$icon-grid-view: "\e999";
$icon-info-icon: "\e99a";
$icon-location-icon: "\e99c";
$icon-login-background-path1: "\e99d";
$icon-login-background-path2: "\e99e";
$icon-login-banner-path1: "\e99f";
$icon-login-banner-path2: "\e9a0";
$icon-login-banner-path3: "\e9a1";
$icon-login-banner-path4: "\e9a2";
$icon-login-banner-path5: "\e9a3";
$icon-login-banner-path6: "\e9a4";
$icon-login-banner-path7: "\e9a5";
$icon-login-banner-path8: "\e9a6";
$icon-login-banner-path9: "\e9a7";
$icon-login-banner-path10: "\e9a8";
$icon-login-banner-path11: "\e9a9";
$icon-login-banner-path12: "\e9aa";
$icon-login-banner-path13: "\e9ab";
$icon-login-banner-path14: "\e9ac";
$icon-login-banner-path15: "\e9ad";
$icon-login-banner-path16: "\e9ae";
$icon-login-banner-path17: "\e9af";
$icon-login-banner-path18: "\e9b0";
$icon-login-banner-path19: "\e9b1";
$icon-login-banner-path20: "\e9b2";
$icon-login-banner-path21: "\e9b3";
$icon-login-banner-path22: "\e9b4";
$icon-login-banner-path23: "\e9b5";
$icon-login-banner-path24: "\e9b6";
$icon-login-banner-path25: "\e9b7";
$icon-login-banner-path26: "\e9b8";
$icon-login-banner-path27: "\e9b9";
$icon-login-banner-path28: "\e9ba";
$icon-login-banner-path29: "\e9bb";
$icon-login-banner-path30: "\e9bc";
$icon-login-banner-path31: "\e9bd";
$icon-login-banner-path32: "\e9be";
$icon-login-banner-path33: "\e9bf";
$icon-login-banner-path34: "\e9c0";
$icon-login-banner-path35: "\e9c1";
$icon-login-banner-path36: "\e9c2";
$icon-login-banner-path37: "\e9c3";
$icon-login-banner-path38: "\e9c4";
$icon-login-banner-path39: "\e9c5";
$icon-login-banner-path40: "\e9c6";
$icon-login-banner-path41: "\e9c7";
$icon-login-banner-path42: "\e9c8";
$icon-login-banner-path43: "\e9c9";
$icon-login-banner-path44: "\e9ca";
$icon-login-banner-path45: "\e9cb";
$icon-login-banner-path46: "\e9cc";
$icon-login-banner-path47: "\e9cd";
$icon-login-banner-path48: "\e9ce";
$icon-login-banner-path49: "\e9cf";
$icon-login-banner-path50: "\e9d0";
$icon-login-banner-path51: "\e9d1";
$icon-login-banner-path52: "\e9d2";
$icon-login-banner-path53: "\e9d3";
$icon-login-banner-path54: "\e9d4";
$icon-login-banner-path55: "\e9d5";
$icon-login-banner-path56: "\e9d6";
$icon-login-banner-path57: "\e9d7";
$icon-login-banner-path58: "\e9d8";
$icon-login-banner-path59: "\e9d9";
$icon-login-banner-path60: "\e9da";
$icon-login-banner-path61: "\e9db";
$icon-login-banner-path62: "\e9dc";
$icon-login-banner-path63: "\e9dd";
$icon-login-banner-path64: "\e9de";
$icon-login-banner-path65: "\e9df";
$icon-login-banner-path66: "\e9e0";
$icon-login-banner-path67: "\e9e1";
$icon-login-banner-path68: "\e9e2";
$icon-login-banner-path69: "\e9e3";
$icon-login-banner-path70: "\e9e4";
$icon-login-banner-path71: "\e9e5";
$icon-login-banner-path72: "\e9e6";
$icon-login-banner-path73: "\e9e7";
$icon-login-banner-path74: "\e9e8";
$icon-login-banner-path75: "\e9e9";
$icon-login-banner-path76: "\e9ea";
$icon-login-banner-path77: "\e9eb";
$icon-login-banner-path78: "\e9ec";
$icon-login-banner-path79: "\e9ed";
$icon-login-banner-path80: "\e9ee";
$icon-login-banner-path81: "\e9ef";
$icon-login-banner-path82: "\e9f0";
$icon-login-banner-path83: "\e9f1";
$icon-login-banner-path84: "\e9f2";
$icon-login-banner-path85: "\e9f3";
$icon-login-banner-path86: "\e9f4";
$icon-login-banner-path87: "\e9f5";
$icon-login-banner-path88: "\e9f6";
$icon-login-banner-path89: "\e9f7";
$icon-login-banner-path90: "\e9f8";
$icon-login-banner-path91: "\e9f9";
$icon-login-banner-path92: "\e9fa";
$icon-login-banner-path93: "\e9fb";
$icon-login-banner-path94: "\e9fc";
$icon-login-banner-path95: "\e9fd";
$icon-login-banner-path96: "\e9fe";
$icon-login-banner-path97: "\e9ff";
$icon-login-banner-path98: "\ea00";
$icon-login-banner-path99: "\ea01";
$icon-login-banner-path100: "\ea02";
$icon-login-banner-path101: "\ea03";
$icon-login-banner-path102: "\ea04";
$icon-login-banner-path103: "\ea05";
$icon-login-banner-path104: "\ea06";
$icon-login-banner-path105: "\ea07";
$icon-login-banner-path106: "\ea08";
$icon-login-banner-path107: "\ea09";
$icon-login-banner-path108: "\ea0a";
$icon-login-banner-path109: "\ea0b";
$icon-login-banner-path110: "\ea0c";
$icon-login-banner-path111: "\ea0d";
$icon-login-banner-path112: "\ea0e";
$icon-login-banner-path113: "\ea0f";
$icon-login-banner-path114: "\ea10";
$icon-login-banner-path115: "\ea11";
$icon-login-banner-path116: "\ea12";
$icon-login-banner-path117: "\ea13";
$icon-login-banner-path118: "\ea14";
$icon-login-banner-path119: "\ea15";
$icon-login-banner-path120: "\ea16";
$icon-login-banner-path121: "\ea17";
$icon-login-banner-path122: "\ea18";
$icon-login-banner-path123: "\ea19";
$icon-login-banner-path124: "\ea1a";
$icon-login-banner-path125: "\ea1b";
$icon-login-banner-path126: "\ea1c";
$icon-no-notification-data-path1: "\ea1d";
$icon-no-notification-data-path2: "\ea1e";
$icon-no-notification-data-path3: "\ea1f";
$icon-no-notification-data-path4: "\ea20";
$icon-no-notification-data-path5: "\ea21";
$icon-no-notification-data-path6: "\ea22";
$icon-no-notification-data-path7: "\ea23";
$icon-no-notification-data-path8: "\ea24";
$icon-no-notification-data-path9: "\ea25";
$icon-no-notification-data-path10: "\ea26";
$icon-no-notification-data-path11: "\ea27";
$icon-no-notification-data-path12: "\ea28";
$icon-no-notification-data-path13: "\ea29";
$icon-no-notification-data-path14: "\ea2a";
$icon-no-notification-data-path15: "\ea2b";
$icon-no-notification-data-path16: "\ea2c";
$icon-no-notification-data-path17: "\ea2d";
$icon-no-notification-data-path18: "\ea2e";
$icon-no-notification-data-path19: "\ea2f";
$icon-no-notification-data-path20: "\ea30";
$icon-no-notification-data-path21: "\ea31";
$icon-no-notification-data-path22: "\ea32";
$icon-no-notification-data-path23: "\ea33";
$icon-no-notification-data-path24: "\ea34";
$icon-no-notification-data-path25: "\ea35";
$icon-no-notification-data-path26: "\ea36";
$icon-no-notification-data-path27: "\ea37";
$icon-no-notification-data-path28: "\ea38";
$icon-no-notification-data-path29: "\ea39";
$icon-no-notification-data-path30: "\ea3a";
$icon-no-notification-data-path31: "\ea3b";
$icon-no-notification-data-path32: "\ea3c";
$icon-no-notification-data-path33: "\ea3d";
$icon-no-notification-data-path34: "\ea3e";
$icon-no-notification-data-path35: "\ea3f";
$icon-no-notification-data-path36: "\ea40";
$icon-no-notification-data-path37: "\ea41";
$icon-no-notification-data-path38: "\ea42";
$icon-no-notification-data-path39: "\ea43";
$icon-no-notification-data-path40: "\ea44";
$icon-no-notification-data-path41: "\ea45";
$icon-no-notification-data-path42: "\ea46";
$icon-no-notification-data-path43: "\ea47";
$icon-no-notification-data-path44: "\ea48";
$icon-no-notification-data-path45: "\ea49";
$icon-no-notification-data-path46: "\ea4a";
$icon-no-notification-data-path47: "\ea4b";
$icon-no-notification-data-path48: "\ea4c";
$icon-no-notification-data-path49: "\ea4d";
$icon-no-notification-data-path50: "\ea4e";
$icon-no-notification-data-path51: "\ea4f";
$icon-no-notification-data-path52: "\ea50";
$icon-no-notification-data-path53: "\ea51";
$icon-no-notification-data-path54: "\ea52";
$icon-no-notification-data-path55: "\ea53";
$icon-no-notification-data-path56: "\ea54";
$icon-no-notification-data-path57: "\ea55";
$icon-no-notification-data-path58: "\ea56";
$icon-no-notification-data-path59: "\ea57";
$icon-no-notification-data-path60: "\ea58";
$icon-no-notification-data-path61: "\ea59";
$icon-no-notification-data-path62: "\ea5a";
$icon-no-notification-data-path63: "\ea5b";
$icon-no-notification-data-path64: "\ea5c";
$icon-no-notification-data-path65: "\ea5d";
$icon-no-notification-data-path66: "\ea5e";
$icon-no-notification-data-path67: "\ea5f";
$icon-no-notification-data-path68: "\ea60";
$icon-no-notification-data-path69: "\ea61";
$icon-no-notification-data-path70: "\ea62";
$icon-no-notification-data-path71: "\ea63";
$icon-no-notification-data-path72: "\ea64";
$icon-no-notification-data-path73: "\ea65";
$icon-no-notification-data-path74: "\ea66";
$icon-no-notification-data-path75: "\ea67";
$icon-no-notification-data-path76: "\ea68";
$icon-no-notification-data-path77: "\ea69";
$icon-no-notification-data-path78: "\ea6a";
$icon-no-notification-data-path79: "\ea6b";
$icon-no-notification-data-path80: "\ea6c";
$icon-no-notification-data-path81: "\ea6d";
$icon-no-notification-data-path82: "\ea6e";
$icon-no-notification-data-path83: "\ea6f";
$icon-no-notification-data-path84: "\ea70";
$icon-no-notification-data-path85: "\ea71";
$icon-no-notification-data-path86: "\ea72";
$icon-no-notification-data-path87: "\ea73";
$icon-no-notification-data-path88: "\ea74";
$icon-no-notification-data-path89: "\ea75";
$icon-no-notification-data-path90: "\ea76";
$icon-no-notification-data-path91: "\ea77";
$icon-no-notification-data-path92: "\ea78";
$icon-no-notification-data-path93: "\ea79";
$icon-no-notification-data-path94: "\ea7a";
$icon-no-notification-data-path95: "\ea7b";
$icon-no-notification-data-path96: "\ea7c";
$icon-no-notification-data-path97: "\ea7d";
$icon-no-notification-data-path98: "\ea7e";
$icon-no-notification-data-path99: "\ea7f";
$icon-no-notification-data-path100: "\ea80";
$icon-no-notification-data-path101: "\ea81";
$icon-no-notification-data-path102: "\ea82";
$icon-no-notification-data-path103: "\ea83";
$icon-no-notification-data-path104: "\ea84";
$icon-no-notification-data-path105: "\ea85";
$icon-no-notification-data-path106: "\ea86";
$icon-no-notification-data-path107: "\ea87";
$icon-no-notification-data-path108: "\ea88";
$icon-no-notification-data-path109: "\ea89";
$icon-no-notification-data-path110: "\ea8a";
$icon-no-notification-data-path111: "\ea8b";
$icon-no-notification-data-path112: "\ea8c";
$icon-no-notification-data-path113: "\ea8d";
$icon-no-notification-data-path114: "\ea8e";
$icon-no-notification-data-path115: "\ea8f";
$icon-no-notification-data-path116: "\ea90";
$icon-no-notification-data-path117: "\ea91";
$icon-no-notification-data-path118: "\ea92";
$icon-no-notification-data-path119: "\ea93";
$icon-no-notification-data-path120: "\ea94";
$icon-no-notification-data-path121: "\ea95";
$icon-no-notification-data-path122: "\ea96";
$icon-no-notification-data-path123: "\ea97";
$icon-no-notification-data-path124: "\ea98";
$icon-no-notification-data-path125: "\ea99";
$icon-no-notification-data-path126: "\ea9a";
$icon-no-notification-data-path127: "\ea9b";
$icon-no-notification-data-path128: "\ea9c";
$icon-no-notification-data-path129: "\ea9d";
$icon-no-notification-data-path130: "\ea9e";
$icon-no-notification-data-path131: "\ea9f";
$icon-no-notification-data-path132: "\eaa0";
$icon-no-notification-data-path133: "\eaa1";
$icon-no-notification-data-path134: "\eaa2";
$icon-no-notification-data-path135: "\eaa3";
$icon-open-eye: "\eaa5";
$icon-open-eye-slash-path1: "\eaa6";
$icon-open-eye-slash-path2: "\eaa7";
$icon-open-eye-slash-path3: "\eaa8";
$icon-orange-bell: "\eaa9";
$icon-orion-logo-path1: "\ed8e";
$icon-orion-logo-path2: "\ed8f";
$icon-orion-logo-path3: "\ed90";
$icon-orion-logo-path4: "\ed91";
$icon-orion-logo-path5: "\ed92";
$icon-orion-logo-path6: "\ed93";
$icon-orion-logo-path7: "\ed94";
$icon-orion-logo-path8: "\ed95";
$icon-orion-logo-path9: "\ed96";
$icon-orion-logo-path10: "\ed97";
$icon-orion-logo-path11: "\ed98";
$icon-orion-logo-path12: "\ed99";
$icon-orion-logo-path13: "\ed9a";
$icon-orion-logo-path14: "\ed9b";
$icon-orion-logo-path15: "\ed9c";
$icon-orion-logo-path16: "\ed9d";
$icon-orion-logo-path17: "\ed9e";
$icon-orion-logo-path18: "\ed9f";
$icon-orion-logo-path19: "\eda0";
$icon-orion-logo-path20: "\eda1";
$icon-orion-logo-path21: "\eda2";
$icon-orion-logo-path22: "\eda3";
$icon-orion-logo-path23: "\eda4";
$icon-orion-logo-path24: "\eda5";
$icon-orion-logo-path25: "\eda6";
$icon-orion-logo-path26: "\eda7";
$icon-orion-logo-path27: "\eda8";
$icon-orion-logo-path28: "\eda9";
$icon-orion-logo-path29: "\edaa";
$icon-orion-logo-path30: "\edab";
$icon-orion-logo-path31: "\edac";
$icon-orion-logo-path32: "\edad";
$icon-orion-logo-path33: "\edae";
$icon-orion-logo-path34: "\edaf";
$icon-orion-logo-path35: "\edb0";
$icon-orion-logo-path36: "\edb1";
$icon-orion-logo-path37: "\edb2";
$icon-orion-logo-path38: "\edb3";
$icon-orion-logo-path39: "\edb4";
$icon-orion-logo-path40: "\edb5";
$icon-orion-logo-path41: "\edb6";
$icon-orion-logo-path42: "\edb7";
$icon-orion-logo-path43: "\edb8";
$icon-orion-logo-path44: "\edb9";
$icon-orion-logo-path45: "\edba";
$icon-orion-logo-path46: "\edbb";
$icon-orion-logo-path47: "\edbc";
$icon-orion-logo-path48: "\edbd";
$icon-orion-logo-path49: "\edbe";
$icon-orion-logo-path50: "\edbf";
$icon-orion-logo-path51: "\edc0";
$icon-orion-logo-path52: "\edc1";
$icon-orion-logo-path53: "\edc2";
$icon-orion-logo-path54: "\edc3";
$icon-orion-logo-path55: "\edc4";
$icon-orion-logo-path56: "\edc5";
$icon-orion-logo-path57: "\edc6";
$icon-orion-logo-path58: "\edc7";
$icon-orion-logo-path59: "\edc8";
$icon-orion-logo-path60: "\edc9";
$icon-orion-logo-path61: "\edca";
$icon-orion-logo-path62: "\edcb";
$icon-orion-logo-path63: "\edcc";
$icon-orion-logo-path64: "\edcd";
$icon-orion-logo-path65: "\edce";
$icon-orion-logo-path66: "\edcf";
$icon-orion-logo-path67: "\edd0";
$icon-orion-logo-path68: "\edd1";
$icon-orion-logo-path69: "\edd2";
$icon-orion-logo-path70: "\edd3";
$icon-orion-logo-path71: "\edd4";
$icon-orion-logo-path72: "\edd5";
$icon-orion-logo-path73: "\edd6";
$icon-orion-logo-path74: "\edd7";
$icon-orion-logo-path75: "\edd8";
$icon-orion-logo-path76: "\edd9";
$icon-orion-logo-path77: "\edda";
$icon-orion-logo-path78: "\eddb";
$icon-orion-logo-path79: "\eddc";
$icon-orion-logo-path80: "\eddd";
$icon-orion-logo-path81: "\edde";
$icon-orion-logo-path82: "\eddf";
$icon-orion-logo-path83: "\ede0";
$icon-orion-logo-path84: "\ede1";
$icon-orion-logo-path85: "\ede2";
$icon-orion-logo-path86: "\ede3";
$icon-orion-logo-path87: "\ede4";
$icon-orion-logo-path88: "\ede5";
$icon-orion-logo-path89: "\ede6";
$icon-orion-logo-path90: "\ede7";
$icon-orion-logo-path91: "\ede8";
$icon-orion-logo-path92: "\ede9";
$icon-orion-logo-path93: "\edea";
$icon-orion-logo-path94: "\edeb";
$icon-orion-logo-path95: "\edec";
$icon-orion-logo-path96: "\eded";
$icon-orion-logo-path97: "\edee";
$icon-orion-logo-path98: "\edef";
$icon-orion-logo-path99: "\edf0";
$icon-orion-logo-path100: "\edf1";
$icon-orion-logo-path101: "\edf2";
$icon-orion-logo-path102: "\edf3";
$icon-orion-logo-path103: "\edf4";
$icon-orion-logo-path104: "\edf5";
$icon-orion-logo-path105: "\edf6";
$icon-orion-logo-path106: "\edf7";
$icon-orion-logo-path107: "\edf8";
$icon-orion-logo-path108: "\edf9";
$icon-orion-logo-path109: "\edfa";
$icon-orion-logo-path110: "\edfb";
$icon-orion-logo-path111: "\edfc";
$icon-orion-logo-path112: "\edfd";
$icon-orion-logo-path113: "\edfe";
$icon-orion-logo-path114: "\edff";
$icon-orion-logo-path115: "\ee00";
$icon-orion-logo-path116: "\ee01";
$icon-orion-logo-path117: "\ee02";
$icon-orion-logo-path118: "\ee03";
$icon-orion-logo-path119: "\ee04";
$icon-orion-logo-path120: "\ee05";
$icon-orion-logo-path121: "\ee06";
$icon-orion-logo-path122: "\ee07";
$icon-orion-logo-path123: "\ee08";
$icon-orion-logo-path124: "\ee09";
$icon-orion-logo-path125: "\ee0a";
$icon-orion-logo-path126: "\ee0b";
$icon-orion-logo-path127: "\ee0c";
$icon-orion-logo-path128: "\ee0d";
$icon-orion-logo-path129: "\ee0e";
$icon-orion-logo-path130: "\ee0f";
$icon-orion-logo-path131: "\ee10";
$icon-orion-logo-path132: "\ee11";
$icon-orion-logo-path133: "\ee12";
$icon-orion-logo-path134: "\ee13";
$icon-orion-logo-path135: "\ee14";
$icon-orion-logo-path136: "\ee15";
$icon-orion-logo-path137: "\ee16";
$icon-orion-logo-path138: "\ee17";
$icon-orion-logo-path139: "\ee18";
$icon-orion-logo-path140: "\ee19";
$icon-orion-logo-path141: "\ee1a";
$icon-orion-logo-path142: "\ee1b";
$icon-orion-logo-path143: "\ee1c";
$icon-orion-logo-path144: "\ee1d";
$icon-orion-logo-path145: "\ee1e";
$icon-orion-logo-path146: "\ee1f";
$icon-orion-logo-path147: "\ee20";
$icon-orion-logo-path148: "\ee21";
$icon-orion-logo-path149: "\ee22";
$icon-orion-logo-path150: "\ee23";
$icon-orion-logo-path151: "\ee24";
$icon-orion-logo-path152: "\ee25";
$icon-orion-logo-path153: "\ee26";
$icon-orion-logo-path154: "\ee27";
$icon-orion-logo-path155: "\ee28";
$icon-orion-logo-path156: "\ee29";
$icon-orion-logo-path157: "\ee2a";
$icon-orion-logo-path158: "\ee2b";
$icon-orion-logo-path159: "\ee2c";
$icon-orion-logo-path160: "\ee2d";
$icon-orion-logo-path161: "\ee2e";
$icon-orion-logo-path162: "\ee2f";
$icon-orion-logo-path163: "\ee30";
$icon-orion-logo-path164: "\ee31";
$icon-orion-logo-path165: "\ee32";
$icon-orion-logo-path166: "\ee33";
$icon-orion-logo-path167: "\ee34";
$icon-orion-logo-path168: "\ee35";
$icon-orion-logo-path169: "\ee36";
$icon-orion-logo-path170: "\ee37";
$icon-orion-logo-path171: "\ee38";
$icon-orion-logo-path172: "\ee39";
$icon-orion-logo-path173: "\ee3a";
$icon-orion-logo-path174: "\ee3b";
$icon-orion-logo-path175: "\ee3c";
$icon-orion-logo-path176: "\ee3d";
$icon-orion-logo-path177: "\ee3e";
$icon-orion-logo-path178: "\ee3f";
$icon-orion-logo-path179: "\ee40";
$icon-orion-logo-path180: "\ee41";
$icon-orion-logo-path181: "\ee42";
$icon-orion-logo-path182: "\ee43";
$icon-orion-logo-path183: "\ee44";
$icon-orion-logo-path184: "\ee45";
$icon-orion-logo-path185: "\ee46";
$icon-orion-logo-path186: "\ee47";
$icon-orion-logo-path187: "\ee48";
$icon-orion-logo-path188: "\ee49";
$icon-orion-logo-path189: "\ee4a";
$icon-orion-logo-path190: "\ee4b";
$icon-orion-logo-path191: "\ee4c";
$icon-orion-logo-path192: "\ee4d";
$icon-orion-logo-path193: "\ee4e";
$icon-orion-logo-path194: "\ee4f";
$icon-orion-logo-path195: "\ee50";
$icon-orion-logo-path196: "\ee51";
$icon-orion-logo-path197: "\ee52";
$icon-orion-logo-path198: "\ee53";
$icon-orion-logo-path199: "\ee54";
$icon-orion-logo-path200: "\ee55";
$icon-orion-logo-path201: "\ee56";
$icon-orion-logo-path202: "\ee57";
$icon-orion-logo-path203: "\ee58";
$icon-orion-logo-path204: "\ee59";
$icon-orion-logo-path205: "\ee5a";
$icon-orion-logo-path206: "\ee5b";
$icon-orion-logo-path207: "\ee5c";
$icon-orion-logo-path208: "\ee5d";
$icon-orion-logo-path209: "\ee5e";
$icon-orion-logo-path210: "\ee5f";
$icon-orion-logo-path211: "\ee60";
$icon-orion-logo-path212: "\ee61";
$icon-orion-logo-path213: "\ee62";
$icon-orion-logo-path214: "\ee63";
$icon-orion-logo-path215: "\ee64";
$icon-orion-logo-path216: "\ee65";
$icon-orion-logo-path217: "\ee66";
$icon-orion-logo-path218: "\ee67";
$icon-orion-logo-path219: "\ee68";
$icon-orion-logo-path220: "\ee69";
$icon-orion-logo-path221: "\ee6a";
$icon-orion-logo-path222: "\ee6b";
$icon-orion-logo-path223: "\ee6c";
$icon-orion-logo-path224: "\ee6d";
$icon-orion-logo-path225: "\ee6e";
$icon-orion-logo-path226: "\ee6f";
$icon-orion-logo-path227: "\ee70";
$icon-orion-logo-path228: "\ee71";
$icon-orion-logo-path229: "\ee72";
$icon-orion-logo-path230: "\ee73";
$icon-orion-logo-path231: "\ee74";
$icon-orion-logo-path232: "\ee75";
$icon-orion-logo-path233: "\ee76";
$icon-orion-logo-path234: "\ee77";
$icon-orion-logo-path235: "\ee78";
$icon-orion-logo-path236: "\ee79";
$icon-orion-logo-path237: "\ee7a";
$icon-orion-logo-path238: "\ee7b";
$icon-orion-logo-path239: "\ee7c";
$icon-orion-logo-path240: "\ee7d";
$icon-orion-logo-path241: "\ee7e";
$icon-orion-logo-path242: "\ee7f";
$icon-orion-logo-path243: "\ee80";
$icon-orion-logo-path244: "\ee81";
$icon-orion-logo-path245: "\ee82";
$icon-orion-logo-path246: "\ee83";
$icon-orion-logo-path247: "\ee84";
$icon-orion-logo-path248: "\ee85";
$icon-orion-logo-path249: "\ee86";
$icon-orion-logo-path250: "\ee87";
$icon-orion-logo-path251: "\ee88";
$icon-orion-logo-path252: "\ee89";
$icon-orion-logo-path253: "\ee8a";
$icon-orion-logo-path254: "\ee8b";
$icon-orion-logo-path255: "\ee8c";
$icon-orion-logo-path256: "\ee8d";
$icon-orion-logo-path257: "\ee8e";
$icon-orion-logo-path258: "\ee8f";
$icon-orion-logo-path259: "\ee90";
$icon-orion-logo-path260: "\ee91";
$icon-orion-logo-path261: "\ee92";
$icon-orion-logo-path262: "\ee93";
$icon-orion-logo-path263: "\ee94";
$icon-orion-logo-path264: "\ee95";
$icon-orion-logo-path265: "\ee96";
$icon-orion-logo-path266: "\ee97";
$icon-orion-logo-path267: "\ee98";
$icon-orion-logo-path268: "\ee99";
$icon-orion-logo-path269: "\ee9a";
$icon-orion-logo-path270: "\ee9b";
$icon-orion-logo-path271: "\ee9c";
$icon-orion-logo-path272: "\ee9d";
$icon-orion-logo-path273: "\ee9e";
$icon-orion-logo-path274: "\ee9f";
$icon-orion-logo-path275: "\eea0";
$icon-orion-logo-path276: "\eea1";
$icon-orion-logo-path277: "\eea2";
$icon-orion-logo-path278: "\eea3";
$icon-orion-logo-path279: "\eea4";
$icon-orion-logo-path280: "\eea5";
$icon-orion-logo-path281: "\eea6";
$icon-orion-logo-path282: "\eea7";
$icon-orion-logo-path283: "\eea8";
$icon-orion-logo-path284: "\eea9";
$icon-orion-logo-path285: "\eeaa";
$icon-orion-logo-path286: "\eeab";
$icon-orion-logo-path287: "\eeac";
$icon-orion-logo-path288: "\eead";
$icon-orion-logo-path289: "\eeae";
$icon-orion-logo-path290: "\eeaf";
$icon-orion-logo-path291: "\eeb0";
$icon-orion-logo-path292: "\eeb1";
$icon-orion-logo-path293: "\eeb2";
$icon-orion-logo-path294: "\eeb3";
$icon-orion-logo-path295: "\eeb4";
$icon-orion-logo-path296: "\eeb5";
$icon-orion-logo-path297: "\eeb6";
$icon-orion-logo-path298: "\eeb7";
$icon-orion-logo-path299: "\eeb8";
$icon-orion-logo-path300: "\eeb9";
$icon-orion-logo-path301: "\eeba";
$icon-orion-logo-path302: "\eebb";
$icon-orion-logo-path303: "\eebc";
$icon-orion-logo-path304: "\eebd";
$icon-orion-logo-path305: "\eebe";
$icon-orion-logo-path306: "\eebf";
$icon-orion-logo-path307: "\eec0";
$icon-orion-logo-path308: "\eec1";
$icon-orion-logo-path309: "\eec2";
$icon-orion-logo-path310: "\eec3";
$icon-orion-logo-path311: "\eec4";
$icon-orion-logo-path312: "\eec5";
$icon-orion-logo-path313: "\eec6";
$icon-orion-logo-path314: "\eec7";
$icon-orion-logo-path315: "\eec8";
$icon-orion-logo-path316: "\eec9";
$icon-orion-logo-path317: "\eeca";
$icon-orion-logo-path318: "\eecb";
$icon-orion-logo-path319: "\eecc";
$icon-orion-logo-path320: "\eecd";
$icon-orion-logo-path321: "\eece";
$icon-orion-logo-path322: "\eecf";
$icon-orion-logo-path323: "\eed0";
$icon-orion-logo-path324: "\eed1";
$icon-orion-logo-path325: "\eed2";
$icon-orion-logo-path326: "\eed3";
$icon-orion-logo-path327: "\eed4";
$icon-orion-logo-path328: "\eed5";
$icon-orion-logo-path329: "\eed6";
$icon-orion-logo-path330: "\eed7";
$icon-orion-logo-path331: "\eed8";
$icon-orion-logo-path332: "\eed9";
$icon-orion-logo-path333: "\eeda";
$icon-orion-logo-path334: "\eedb";
$icon-orion-logo-path335: "\eedc";
$icon-orion-logo-path336: "\eedd";
$icon-orion-logo-path337: "\eede";
$icon-orion-logo-path338: "\eedf";
$icon-orion-logo-path339: "\eee0";
$icon-orion-logo-path340: "\eee1";
$icon-orion-logo-path341: "\eee2";
$icon-orion-logo-path342: "\eee3";
$icon-orion-logo-path343: "\eee4";
$icon-orion-logo-path344: "\eee5";
$icon-orion-logo-path345: "\eee6";
$icon-orion-logo-path346: "\eee7";
$icon-orion-logo-path347: "\eee8";
$icon-orion-logo-path348: "\eee9";
$icon-orion-logo-path349: "\eeea";
$icon-orion-logo-path350: "\eeeb";
$icon-orion-logo-path351: "\eeec";
$icon-orion-logo-path352: "\eeed";
$icon-orion-logo-path353: "\eeee";
$icon-orion-logo-path354: "\eeef";
$icon-orion-logo-path355: "\eef0";
$icon-orion-logo-path356: "\eef1";
$icon-orion-logo-path357: "\eef2";
$icon-orion-logo-path358: "\eef3";
$icon-orion-logo-path359: "\eef4";
$icon-orion-logo-path360: "\eef5";
$icon-orion-logo-path361: "\eef6";
$icon-orion-logo-path362: "\eef7";
$icon-orion-logo-path363: "\eef8";
$icon-orion-logo-path364: "\eef9";
$icon-orion-logo-path365: "\eefa";
$icon-orion-logo-path366: "\eefb";
$icon-orion-logo-path367: "\eefc";
$icon-orion-logo-path368: "\eefd";
$icon-orion-logo-path369: "\eefe";
$icon-orion-logo-path370: "\eeff";
$icon-orion-logo-path371: "\ef00";
$icon-orion-logo-path372: "\ef01";
$icon-orion-logo-path373: "\ef02";
$icon-orion-logo-path374: "\ef03";
$icon-orion-logo-path375: "\ef04";
$icon-orion-logo-path376: "\ef05";
$icon-orion-logo-path377: "\ef06";
$icon-orion-logo-path378: "\ef07";
$icon-orion-logo-path379: "\ef08";
$icon-orion-logo-path380: "\ef09";
$icon-orion-logo-path381: "\ef0a";
$icon-orion-logo-path382: "\ef0b";
$icon-orion-logo-path383: "\ef0c";
$icon-orion-logo-path384: "\ef0d";
$icon-orion-logo-path385: "\ef0e";
$icon-orion-logo-path386: "\ef0f";
$icon-orion-logo-path387: "\ef10";
$icon-orion-logo-path388: "\ef11";
$icon-orion-logo-path389: "\ef12";
$icon-orion-logo-path390: "\ef13";
$icon-orion-logo-path391: "\ef14";
$icon-orion-logo-path392: "\ef15";
$icon-orion-logo-path393: "\ef16";
$icon-orion-logo-path394: "\ef17";
$icon-orion-logo-path395: "\ef18";
$icon-orion-logo-path396: "\ef19";
$icon-orion-logo-path397: "\ef1a";
$icon-orion-logo-path398: "\ef1b";
$icon-orion-logo-path399: "\ef1c";
$icon-orion-logo-path400: "\ef1d";
$icon-orion-logo-path401: "\ef1e";
$icon-orion-logo-path402: "\ef1f";
$icon-orion-logo-path403: "\ef20";
$icon-orion-logo-path404: "\ef21";
$icon-orion-logo-path405: "\ef22";
$icon-orion-logo-path406: "\ef23";
$icon-orion-logo-path407: "\ef24";
$icon-orion-logo-path408: "\ef25";
$icon-orion-logo-path409: "\ef26";
$icon-orion-logo-path410: "\ef27";
$icon-orion-logo-path411: "\ef28";
$icon-orion-logo-path412: "\ef29";
$icon-orion-logo-path413: "\ef2a";
$icon-orion-logo-path414: "\ef2b";
$icon-orion-logo-path415: "\ef2c";
$icon-orion-logo-path416: "\ef2d";
$icon-orion-logo-path417: "\ef2e";
$icon-orion-logo-path418: "\ef2f";
$icon-orion-logo-path419: "\ef30";
$icon-orion-logo-path420: "\ef31";
$icon-orion-logo-path421: "\ef32";
$icon-orion-logo-path422: "\ef33";
$icon-orion-logo-path423: "\ef34";
$icon-orion-logo-path424: "\ef35";
$icon-orion-logo-path425: "\ef36";
$icon-orion-logo-path426: "\ef37";
$icon-orion-logo-path427: "\ef38";
$icon-orion-logo-path428: "\ef39";
$icon-orion-logo-path429: "\ef3a";
$icon-orion-logo-path430: "\ef3b";
$icon-orion-logo-path431: "\ef3c";
$icon-orion-logo-path432: "\ef3d";
$icon-orion-logo-path433: "\ef3e";
$icon-orion-logo-path434: "\ef3f";
$icon-orion-logo-path435: "\ef40";
$icon-orion-logo-path436: "\ef41";
$icon-orion-logo-path437: "\ef42";
$icon-orion-logo-path438: "\ef43";
$icon-orion-logo-path439: "\ef44";
$icon-orion-logo-path440: "\ef45";
$icon-orion-logo-path441: "\ef46";
$icon-orion-logo-path442: "\ef47";
$icon-orion-logo-path443: "\ef48";
$icon-orion-logo-path444: "\ef49";
$icon-orion-logo-path445: "\ef4a";
$icon-orion-logo-path446: "\ef4b";
$icon-orion-logo-path447: "\ef4c";
$icon-orion-logo-path448: "\ef4d";
$icon-orion-logo-path449: "\ef4e";
$icon-orion-logo-path450: "\ef4f";
$icon-orion-logo-path451: "\ef50";
$icon-orion-logo-path452: "\ef51";
$icon-orion-logo-path453: "\ef52";
$icon-orion-logo-path454: "\ef53";
$icon-orion-logo-path455: "\ef54";
$icon-orion-logo-path456: "\ef55";
$icon-orion-logo-path457: "\ef56";
$icon-orion-logo-path458: "\ef57";
$icon-orion-logo-path459: "\ef58";
$icon-orion-logo-path460: "\ef59";
$icon-orion-logo-path461: "\ef5a";
$icon-orion-logo-path462: "\ef5b";
$icon-orion-logo-path463: "\ef5c";
$icon-orion-logo-path464: "\ef5d";
$icon-orion-logo-path465: "\ef5e";
$icon-orion-logo-path466: "\ef5f";
$icon-orion-logo-path467: "\ef60";
$icon-orion-logo-path468: "\ef61";
$icon-orion-logo-path469: "\ef62";
$icon-orion-logo-path470: "\ef63";
$icon-orion-logo-path471: "\ef64";
$icon-orion-logo-path472: "\ef65";
$icon-orion-logo-path473: "\ef66";
$icon-orion-logo-path474: "\ef67";
$icon-orion-logo-path475: "\ef68";
$icon-orion-logo-path476: "\ef69";
$icon-orion-logo-path477: "\ef6a";
$icon-orion-logo-path478: "\ef6b";
$icon-orion-logo-path479: "\ef6c";
$icon-orion-logo-path480: "\ef6d";
$icon-orion-logo-path481: "\ef6e";
$icon-orion-logo-path482: "\ef6f";
$icon-orion-logo-path483: "\ef70";
$icon-orion-logo-path484: "\ef71";
$icon-orion-logo-path485: "\ef72";
$icon-orion-logo-path486: "\ef73";
$icon-orion-logo-path487: "\ef74";
$icon-orion-logo-path488: "\ef75";
$icon-orion-logo-path489: "\ef76";
$icon-orion-logo-path490: "\ef77";
$icon-orion-logo-path491: "\ef78";
$icon-orion-logo-path492: "\ef79";
$icon-orion-logo-path493: "\ef7a";
$icon-orion-logo-path494: "\ef7b";
$icon-orion-logo-path495: "\ef7c";
$icon-orion-logo-path496: "\ef7d";
$icon-orion-logo-path497: "\ef7e";
$icon-orion-logo-path498: "\ef7f";
$icon-orion-logo-path499: "\ef80";
$icon-orion-logo-path500: "\ef81";
$icon-orion-logo-path501: "\ef82";
$icon-orion-logo-path502: "\ef83";
$icon-orion-logo-path503: "\ef84";
$icon-orion-logo-path504: "\ef85";
$icon-orion-logo-path505: "\ef86";
$icon-orion-logo-path506: "\ef87";
$icon-orion-logo-path507: "\ef88";
$icon-orion-logo-path508: "\ef89";
$icon-orion-logo-path509: "\ef8a";
$icon-orion-logo-path510: "\ef8b";
$icon-orion-logo-path511: "\ef8c";
$icon-orion-logo-path512: "\ef8d";
$icon-orion-logo-path513: "\ef8e";
$icon-orion-logo-path514: "\ef8f";
$icon-orion-logo-path515: "\ef90";
$icon-orion-logo-path516: "\ef91";
$icon-orion-logo-path517: "\ef92";
$icon-orion-logo-path518: "\ef93";
$icon-orion-logo-path519: "\ef94";
$icon-orion-logo-path520: "\ef95";
$icon-orion-logo-path521: "\ef96";
$icon-orion-logo-path522: "\ef97";
$icon-orion-logo-path523: "\ef98";
$icon-orion-logo-path524: "\ef99";
$icon-orion-logo-path525: "\ef9a";
$icon-orion-logo-path526: "\ef9b";
$icon-orion-logo-path527: "\ef9c";
$icon-orion-logo-path528: "\ef9d";
$icon-orion-logo-path529: "\ef9e";
$icon-orion-logo-path530: "\ef9f";
$icon-orion-logo-path531: "\efa0";
$icon-orion-logo-path532: "\efa1";
$icon-orion-logo-path533: "\efa2";
$icon-orion-logo-path534: "\efa3";
$icon-orion-logo-path535: "\efa4";
$icon-orion-logo-path536: "\efa5";
$icon-orion-logo-path537: "\efa6";
$icon-orion-logo-path538: "\efa7";
$icon-orion-logo-path539: "\efa8";
$icon-orion-logo-path540: "\efa9";
$icon-orion-logo-path541: "\efaa";
$icon-orion-logo-path542: "\efab";
$icon-orion-logo-path543: "\efac";
$icon-orion-logo-path544: "\efad";
$icon-orion-logo-path545: "\efae";
$icon-orion-logo-path546: "\efaf";
$icon-orion-logo-path547: "\efb0";
$icon-orion-logo-path548: "\efb1";
$icon-orion-logo-path549: "\efb2";
$icon-orion-logo-path550: "\efb3";
$icon-orion-logo-path551: "\efb4";
$icon-orion-logo-path552: "\efb5";
$icon-orion-logo-path553: "\efb6";
$icon-orion-logo-path554: "\efb7";
$icon-orion-logo-path555: "\efb8";
$icon-orion-logo-path556: "\efb9";
$icon-orion-logo-path557: "\efba";
$icon-orion-logo-path558: "\efbb";
$icon-orion-logo-path559: "\efbc";
$icon-orion-logo-path560: "\efbd";
$icon-orion-logo-path561: "\efbe";
$icon-orion-logo-path562: "\efbf";
$icon-orion-logo-path563: "\efc0";
$icon-orion-logo-path564: "\efc1";
$icon-orion-logo-path565: "\efc2";
$icon-orion-logo-path566: "\efc3";
$icon-orion-logo-path567: "\efc4";
$icon-orion-logo-path568: "\efc5";
$icon-orion-logo-path569: "\efc6";
$icon-orion-logo-path570: "\efc7";
$icon-orion-logo-path571: "\efc8";
$icon-orion-logo-path572: "\efc9";
$icon-orion-logo-path573: "\efca";
$icon-orion-logo-path574: "\efcb";
$icon-orion-logo-path575: "\efcc";
$icon-orion-logo-path576: "\efcd";
$icon-orion-logo-path577: "\efce";
$icon-orion-logo-path578: "\efcf";
$icon-orion-logo-path579: "\efd0";
$icon-orion-logo-path580: "\efd1";
$icon-orion-logo-path581: "\efd2";
$icon-orion-logo-path582: "\efd3";
$icon-orion-logo-path583: "\efd4";
$icon-orion-logo-path584: "\efd5";
$icon-orion-logo-path585: "\efd6";
$icon-orion-logo-path586: "\efd7";
$icon-orion-logo-path587: "\efd8";
$icon-orion-logo-path588: "\efd9";
$icon-orion-logo-path589: "\efda";
$icon-orion-logo-path590: "\efdb";
$icon-orion-logo-path591: "\efdc";
$icon-orion-logo-path592: "\efdd";
$icon-orion-logo-path593: "\efde";
$icon-orion-logo-path594: "\efdf";
$icon-orion-logo-path595: "\efe0";
$icon-orion-logo-path596: "\efe1";
$icon-orion-logo-path597: "\efe2";
$icon-orion-logo-path598: "\efe3";
$icon-orion-logo-path599: "\efe4";
$icon-orion-logo-path600: "\efe5";
$icon-orion-logo-path601: "\efe6";
$icon-orion-logo-path602: "\efe7";
$icon-orion-logo-path603: "\efe8";
$icon-orion-logo-path604: "\efe9";
$icon-orion-logo-path605: "\efea";
$icon-orion-logo-path606: "\efeb";
$icon-orion-logo-path607: "\efec";
$icon-orion-logo-path608: "\efed";
$icon-orion-logo-path609: "\efee";
$icon-orion-logo-path610: "\efef";
$icon-orion-logo-path611: "\eff0";
$icon-orion-logo-path612: "\eff1";
$icon-orion-logo-path613: "\eff2";
$icon-orion-logo-path614: "\eff3";
$icon-orion-logo-path615: "\eff4";
$icon-orion-logo-path616: "\eff5";
$icon-orion-logo-path617: "\eff6";
$icon-orion-logo-path618: "\eff7";
$icon-orion-logo-path619: "\eff8";
$icon-orion-logo-path620: "\eff9";
$icon-orion-logo-path621: "\effa";
$icon-orion-logo-path622: "\effb";
$icon-orion-logo-path623: "\effc";
$icon-orion-logo-path624: "\effd";
$icon-orion-logo-path625: "\effe";
$icon-orion-logo-path626: "\efff";
$icon-orion-logo-path627: "\f000";
$icon-orion-logo-path628: "\f001";
$icon-orion-logo-path629: "\f002";
$icon-orion-logo-path630: "\f003";
$icon-orion-logo-path631: "\f004";
$icon-orion-logo-path632: "\f005";
$icon-orion-logo-path633: "\f006";
$icon-orion-logo-path634: "\f007";
$icon-orion-logo-path635: "\f008";
$icon-orion-logo-path636: "\f009";
$icon-orion-logo-path637: "\f00a";
$icon-orion-logo-path638: "\f00b";
$icon-orion-logo-path639: "\f00c";
$icon-orion-logo-path640: "\f00d";
$icon-orion-logo-path641: "\f00e";
$icon-orion-logo-path642: "\f00f";
$icon-orion-logo-path643: "\f010";
$icon-orion-logo-path644: "\f011";
$icon-orion-logo-path645: "\f012";
$icon-orion-logo-path646: "\f013";
$icon-orion-logo-path647: "\f014";
$icon-orion-logo-path648: "\f015";
$icon-orion-logo-path649: "\f016";
$icon-orion-logo-path650: "\f017";
$icon-orion-logo-path651: "\f018";
$icon-orion-logo-path652: "\f019";
$icon-orion-logo-path653: "\f01a";
$icon-orion-logo-path654: "\f01b";
$icon-orion-logo-path655: "\f01c";
$icon-orion-logo-path656: "\f01d";
$icon-orion-logo-path657: "\f01e";
$icon-orion-logo-path658: "\f01f";
$icon-orion-logo-path659: "\f020";
$icon-orion-logo-path660: "\f021";
$icon-orion-logo-path661: "\f022";
$icon-orion-logo-path662: "\f023";
$icon-orion-logo-path663: "\f024";
$icon-orion-logo-path664: "\f025";
$icon-orion-logo-path665: "\f026";
$icon-orion-logo-path666: "\f027";
$icon-orion-logo-path667: "\f028";
$icon-orion-logo-path668: "\f029";
$icon-orion-logo-path669: "\f02a";
$icon-orion-logo-path670: "\f02b";
$icon-orion-logo-path671: "\f02c";
$icon-orion-logo-path672: "\f02d";
$icon-orion-logo-path673: "\f02e";
$icon-orion-logo-path674: "\f02f";
$icon-orion-logo-path675: "\f030";
$icon-orion-logo-path676: "\f031";
$icon-orion-logo-path677: "\f032";
$icon-orion-logo-path678: "\f033";
$icon-orion-logo-path679: "\f034";
$icon-orion-logo-path680: "\f035";
$icon-orion-logo-path681: "\f036";
$icon-orion-logo-path682: "\f037";
$icon-orion-logo-path683: "\f038";
$icon-orion-logo-path684: "\f039";
$icon-orion-logo-path685: "\f03a";
$icon-orion-logo-path686: "\f03b";
$icon-orion-logo-path687: "\f03c";
$icon-orion-logo-path688: "\f03d";
$icon-orion-logo-path689: "\f03e";
$icon-orion-logo-path690: "\f03f";
$icon-orion-logo-path691: "\f040";
$icon-orion-logo-path692: "\f041";
$icon-orion-logo-path693: "\f042";
$icon-orion-logo-path694: "\f043";
$icon-orion-logo-path695: "\f044";
$icon-orion-logo-path696: "\f045";
$icon-orion-logo-path697: "\f046";
$icon-orion-logo-path698: "\f047";
$icon-orion-logo-path699: "\f048";
$icon-orion-logo-path700: "\f049";
$icon-orion-logo-path701: "\f04a";
$icon-orion-logo-path702: "\f04b";
$icon-orion-logo-path703: "\f04c";
$icon-orion-logo-path704: "\f04d";
$icon-orion-logo-path705: "\f04e";
$icon-orion-logo-path706: "\f04f";
$icon-orion-logo-path707: "\f050";
$icon-orion-logo-path708: "\f051";
$icon-orion-logo-path709: "\f052";
$icon-orion-logo-path710: "\f053";
$icon-orion-logo-path711: "\f054";
$icon-orion-logo-path712: "\f055";
$icon-orion-logo-path713: "\f056";
$icon-orion-logo-path714: "\f057";
$icon-orion-logo-path715: "\f058";
$icon-orion-logo-path716: "\f059";
$icon-orion-logo-path717: "\f05a";
$icon-orion-logo-path718: "\f05b";
$icon-orion-logo-path719: "\f05c";
$icon-orion-logo-path720: "\f05d";
$icon-orion-logo-path721: "\f05e";
$icon-orion-logo-path722: "\f05f";
$icon-orion-logo-path723: "\f060";
$icon-orion-logo-path724: "\f061";
$icon-orion-logo-path725: "\f062";
$icon-orion-logo-path726: "\f063";
$icon-orion-logo-path727: "\f064";
$icon-orion-logo-path728: "\f065";
$icon-orion-logo-path729: "\f066";
$icon-orion-logo-path730: "\f067";
$icon-orion-logo-path731: "\f068";
$icon-orion-logo-path732: "\f069";
$icon-orion-logo-path733: "\f06a";
$icon-orion-logo-path734: "\f06b";
$icon-orion-logo-path735: "\f06c";
$icon-orion-logo-path736: "\f06d";
$icon-orion-logo-path737: "\f06e";
$icon-orion-logo-path738: "\f06f";
$icon-orion-logo-path739: "\f070";
$icon-orion-logo-path740: "\f071";
$icon-orion-logo-path741: "\f072";
$icon-orion-logo-path742: "\f073";
$icon-orion-logo-path743: "\f074";
$icon-pencil-simple-line: "\f075";
$icon-phone-icon: "\f076";
$icon-printer-icon: "\f077";
$icon-radio-checked: "\f079";
$icon-radio-uncheck: "\f07a";
$icon-search-white: "\f07b";
$icon-source-link: "\f07d";
$icon-success-tick-path1: "\f07e";
$icon-success-tick-path2: "\f07f";
$icon-success-tick-large: "\f080";
$icon-success-tick-white: "\f081";
$icon-switch-active-path1: "\f082";
$icon-switch-active-path2: "\f083";
$icon-switch-active-path3: "\f084";
$icon-switch-inactive-path1: "\f085";
$icon-switch-inactive-path2: "\f086";
$icon-switch-inactive-path3: "\f087";
$icon-total-orders-path1: "\f08c";
$icon-total-orders-path2: "\f08d";
$icon-total-tools-path1: "\f08e";
$icon-total-tools-path2: "\f08f";
$icon-total-tools-path3: "\f090";
$icon-total-tools-path4: "\f091";
$icon-upload-simple: "\f094";
$icon-upsell: "\f096";
$icon-upsell-icon: "\f097";
$icon-user-switch: "\f098";
$icon-user-request: "\f099";
$icon-user-request-approval-pending: "\f09a";
$icon-users1: "\f09b";
$icon-workspace-switch: "\f09d";
$icon-x-circle: "\f09e";
$icon-close: "\eb0e";
$icon-dots-horizontal-triple: "\eb0f";
$icon-chevron-thin-down: "\eb10";
$icon-chevron-thin-right: "\eb11";
$icon-calendar1: "\eb12";
$icon-switch: "\eb13";
$icon-profile: "\eb14";
$icon-up-arrow: "\eb15";
$icon-down-arrow: "\eb16";

