.manage {
  padding: 0 25px;
  // ROLES
  &__roles {
    &__header {
      padding: 25px 0;
      display: flex;
      justify-content: space-between;
    }
    &__details {
      padding-top: 17px;
      padding-bottom: 24px;
      color: $color-primary-dark;
      font-weight: 600;
      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__title {
          p {
            margin: 0;
            color: $color-black;
            font-size: 13px;
            font-weight: 400;
          }
        }
        &__dropdown {
          display: flex;
          gap: 10px;
          label {
            font-size: 13px;
            font-weight: 500;
            color: $color-gray;
          }
          .react-dropdown-select {
            border-radius: 8px;
            border: 1px solid $color-light-light-gray;
            width: 200px;
            height: 42px;
            padding: 12px;
            .react-dropdown-select-content {
              color: $color-darkLiver;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              flex-wrap: nowrap;
              span {
                display: block;
                width: 100%;
                text-align: left;
                white-space: nowrap;
                float: none;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .react-dropdown-select-input {
                display: inline;
              }
              .react-dropdown-select-option {
                background: transparent;
                color: $color-darkLiver;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                white-space: nowrap;
                margin: 0;
                &:first-child {
                  padding-left: 0px;
                }
              }
            }
            &.department_list,
            &.role_list {
              .react-dropdown-select-option {
                position: relative;
                display: inline;
                &::before {
                  position: absolute;
                  content: ",";
                  right: 0px;
                  bottom: 0;
                }
                &:nth-last-child(2)::before {
                  content: "";
                }
                .react-dropdown-select-option-remove {
                  display: none;
                }
              }
              .react-dropdown-select-dropdown {
                overflow: visible;
                border-radius: 6px;
                .dropdwonList-main {
                  padding: 12px;
                  .dropdwonList-head {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-gray;
                    margin: 0 0 12px 0;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $color-light-gray;
                  }
                  .dropdwonLists {
                    overflow: auto;
                    max-height: 200px;
                    padding: 0px 12px 12px 12px;
                    margin: 0 22px 0 10px;
                    &.single {
                      margin: 0;
                      padding: 0;
                    }
                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                    .dropdwonList-subhead,
                    .dropdwonLists-label {
                      margin: 0 0 16px 0;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-gray;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      column-gap: 8px;
                      &.disabled,
                      &:disabled {
                        pointer-events: none;
                        cursor: not-allowed;
                        opacity: 0.5;
                      }
                      &:last-child {
                        margin: 0 0 0 0;
                      }
                      &.sub-list {
                        margin-left: 8px;
                      }
                      label {
                        cursor: pointer;
                      }
                      .checkbox-img {
                        width: 16px;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__container {
        display: flex;
        gap: 10px;
        margin-top: 25px;
        &-sidemenu {
          display: inline-flex;
          // align-items: center;
          ul {
            display: inline-flex;
            flex-direction: column;
            row-gap: 10px;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              width: 250px;
              border-radius: 6px;
              background-color: $color-light-ghost-white;
              color: $color-oldSilver;
              margin: 0;
              height: 53px;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              border-bottom: 1px solid $color-light-light-gray;
              cursor: pointer;
              font-size: 17px;
              font-weight: 500;
            }
            li.active {
              background-color: $color-primary-dark;
              color: $color-white;
            }
          }
        }
        &-form {
          width: 100%;
          &-table {
            width: 100%;
            .table-container {
              overflow: hidden;
              overflow-x: auto;
              overflow-y: auto;
              border-collapse: collapse;
              border-radius: 6px;
              tbody {
                border: 1px solid $color-light-light-gray !important;
                border-bottom: none !important;
              }
            }
            tr {
              &:last-child {
                border-radius: 0 6px;
                td {
                  border-radius: 0 6px;
                }
              }
            }

            thead {
              height: 53px;
              background-color: $color-primary-dark;
              color: $color-white;
              th {
                font-size: 17px;
                font-weight: 500;
                line-height: 20.57px;
                padding: 0 24px;
                border-bottom: 1px solid $color-light-light-gray;
                border-right: 1px solid $color-light-light-gray;
                border-collapse: collapse;
              }
              th:first-child {
                width: 80%;
                border-top-left-radius: 6px;
              }
              th:last-child {
                text-align: center;
                border-top-right-radius: 6px;
                &.sno {
                  text-align: center;
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: 15px;
                  font-weight: 400;
                  color: $color-darkLiver;
                  padding: 10px 24px;
                  border-right: 1px solid $color-light-light-gray;
                  border-collapse: collapse;
                  border-radius: 6px;
                  &.sno {
                    text-align: center;
                  }
                  .switch {
                    justify-content: center;
                    &__icon {
                      .switch__input:checked + .switch__slider {
                        background-color: $color-primary-dark;
                      }
                    }
                  }
                }
                .actions {
                  text-align: center;
                  width: 150px;
                  .actions-buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    column-gap: 8px;
                    button {
                      border: none;
                      background: transparent;
                      padding: 0;
                      width: 22px;
                      img {
                        width: 100%;
                      }
                    }
                    .edit-data {
                      img {
                        width: 19px;
                        filter: contrast(0.25);
                      }
                    }
                  }
                }
              }
              tr:not(:last-child) {
                border-bottom: 1px solid $color-light-light-gray;
              }
            }
          }
        }
      }
      &__content {
        margin-top: 25px;
        border-radius: 24px;
        border: 1px solid $color-light-light-gray;
        label {
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          color: $color-gray;
          text-align: center;
          padding: 20px 0px;
          width: 100%;
        }

        /** CUSTOMIZED ACCORDION **/
        .accordion {
          .accordion-body,
          .accordion-button {
            padding: 0 25px;
          }
          .accordion-button:not(.collapsed) {
            background-color: unset;
            color: unset;
          }
          .accordion-item:last-of-type .accordion-button.collapsed {
            border-bottom: unset;
            background-color: unset;
          }
          .accordion-item:first-of-type {
            border-top: 1px solid $color-light-light-gray;
          }
          .accordion-item {
            border: unset;
            border-bottom: 1px solid $color-light-light-gray;
            border-radius: 0;
            background-color: unset;
          }
          .accordion-item:last-of-type {
            border-bottom: unset;
          }
        }

        &-header,
        &-description {
          width: 90%;
          table {
            width: 100%;
          }
          th,
          td {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            padding: 20px 0;
            width: 10%;
            color: $color-gray;
          }
          th {
            font-weight: 500;
          }
          input[type="checkbox"] {
            width: 16px;
            height: 16px;
            accent-color: $color-primary-dark;
          }
        }
      }
      &-footer {
        padding-top: 15px;
        float: right;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        gap: 15px;
        button {
          background: none;
          border: none;
          border-radius: 35.202px;
          padding: 8.8px 23.468px;
        }
        .cancel {
          border: 1px solid $color-light-light-gray;
          color: $color-darkLiver;
        }
        .submit {
          background: $color-primary-dark;
          color: $color-white;
        }
      }
    }
  }

  &__settings {
    &__header {
      padding: 25px 0;
      display: flex;
      justify-content: space-between;
      .search-input {
        width: 304px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--color-light-light-gray);
        background: var(--color-white);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--color-gray);
      }
    }
    &__feature {
      display: flex;
      flex-flow: wrap;
      row-gap: 24px;
      column-gap: 16px;
      padding-top: 25px;
      &__details {
        text-decoration: none;
        display: inline-flex;
        gap: 12px;
        align-items: center;
        color: unset;
        width: 304px;
        padding: 12px;
        &-icon {
          font-size: 40px;
        }
        &-info {
          p {
            margin: 0;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            color: #333;
          }
          label {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            color: #333;
          }
        }
      }
    }
  }
}

.popupModal__container.update-master-datapopup {
  text-align: left;
  max-width: 448px;
  min-width: 351px;
  height: 100vh;
  margin: auto;
  .popupModal__root {
    width: 100%;
    min-height: 100px;
    padding: 20px;
    margin: auto;
    border-radius: 5px 5px 5px 5px;
    margin-left: calc(100% - 448px);
    overflow: visible;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
    .popupModal__heading {
      margin-bottom: 18px;
      padding-bottom: 12px;
      h2 {
        text-align: left;
        margin-left: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-primary-dark;
        border: none;
      }
      .icon-close {
        right: 22px;
        top: 26px;
        z-index: 1;
      }
    }
    .popupModal__body {
      overflow: visible;
      min-height: 100%;
      .box-flex--row {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: baseline;
        &-col {
          width: 100%;
          position: relative;
          label {
            display: block;
            font-size: 15px;
            font-weight: 500;
            line-height: 18.15px;
            color: $color-black;
            margin: 0 0 4px;
            width: 100%;
          }
          input {
            display: block;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.15px;
            height: 42px;
            color: $color-gray;
            border-radius: 6px;
            width: 100%;
            border: 1px solid $color-light-light-gray;
            padding: 2px 10px;
            &::placeholder {
              color: $color-light-light-gray;
            }
          }
        }
      }
      .flex-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 20px 0 0px;
        column-gap: 20px;
       
        .cencel-btn {
          border: 1px solid $color-light-red;
          color: $color-light-red;
          background: none;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.73px;
          width: 75px;
          height: 28px;
          border-radius: 25px;
          padding: 6px, 16px, 6px, 16px;
        }
        .save-btn {
          border: none;
          color: $color-white;
          background: $color-primary-dark;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.73px;
          width: 61px;
          height: 28px;
          border-radius: 25px;
          padding: 6px, 16px, 6px, 16px;
        }
        &.archive {
          margin: 0;
          .save-btn {
            width: 80px;
          }
        }
      }
      .color-sample {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 12px;
        top: 35px;
        cursor: pointer;
        border: 1px solid var(--color-light-light-gray);
      }
      .color-picker {
        position: absolute;
        bottom: 50px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
        z-index: 99;
        padding: 10px;
        right: 0;
        width: 270px;
        border-radius: 5px;
        .color-picker__footer {
          label {
            font-size: 13px;
            font-weight: 500;
            line-height: 18.15px;
            color: inherit;
            margin: inherit;
          }
          input {
            font-size: 12px;
            font-weight: 400;
            line-height: 18.15px;
            color: inherit;
            margin: inherit;
          }
        }
      }
    }
  }
  &.archive {
    .popupModal__heading {
      h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
      .icon-close {
        top: 23px;
      }
    }
  }
}
