.popupModal__container.remarks {
  .popupModal__root {
    max-width: 420px;
    max-height: 220px;
    padding: 16px;
    overflow: visible;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }

    .popupModal__heading {
      padding-bottom: 12px;
      h2 {
        text-align: left;
        margin-left: 0;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $color-primary-dark;
        margin-right: 45px;
        & .heading b {
          color: $color-primary;
        }
      }
      .icon-close {
        right: 20px;
        top: 16px;
      }
    }
    .popupModal__body {
      .remarks__container {
        display: flex;
        flex-direction: column;
        .error-msg {
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          color: $color-light-red;
          height: 12px;
          display: block;
          margin: 2px 0 8px 0;
          text-align: right;
        }
        .input-field {
          border-radius: 2px;
          border: 1px solid $color-light-light-gray;
          width: 100%;
          min-height: 86px;
          margin: 0 0 10px;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          resize: none;
          padding: 4px;
          color: $color-oldSilver;
        }
        .flex-disp {
          display: flex;
          flex-direction: row;
          margin: 0px 0 0;
          align-items: center;
          column-gap: 20px;
          justify-content: flex-end;
          .submit-btn {
            border-radius: 24px;
            background: $color-primary-dark;
            padding: 6px 16px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-white;
            border: 1px solid transparent;
            cursor: pointer;

            &:hover {
              color: $color-white;
              background: #01101bd4;
              border: 1px solid $color-primary-dark;
            }
          }
          .cancel-btn {
            border-radius: 24px;
            border: 1px solid $color-oldSilver;
            padding: 6px 16px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            color: $color-oldSilver;
            cursor: pointer;
            &:hover {
              background: #dadcdd;
            }
          }
        }
      }
    }
  }
  &.ToolFiledRemarks {
    .popupModal__root {
      max-height: 150px;
      border-radius: 5px;
      .popupModal__heading h2 {
        text-align: center;
        .heading b {
          color: $color-light-red;
        }
      }
      .popupModal__body {
        display: none;
      }
    }
  }
}
