.subscription {
  padding: 0;

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 25px 15px;

    .add-btn {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      color: $color-white;
      background-color: $color-primary-dark;
      border: none;
      width: 128px;
      padding: 12px;
      border-radius: 26px;
    }
    .breadcrumb {
      li:not(:first-child) {
        color: $color-gray;
      }
    }
  }

  &-detail {
    padding: 28px 25px;

    &-title {
      color: $color-gray;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }

    &-packages {
      display: flex;
      gap: 30px;
      padding-top: 22px;
      overflow: auto;
      overflow-y: hidden;    
      background: $color-light-gray;
      padding: 14px;

      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: $color-light-light-gray;
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
        border-radius: 10px;
        background-color: $color-light-light-gray;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
        background-color: $color-oldSilver;
      }

      .package-container {
        width: 100%;
        min-width: 300px;
        padding: 6px;
        border-radius: 6px;
        border: 1px solid $color-light-light-gray;
        background: $color-white;
        cursor: pointer;        
        max-height: 272px;
        position: relative;

        &-name {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          color: $color-white;
          border-radius: 6px;
          background-color: $color-primary-dark;
          display: flex;
          width: 100%;
          padding: 15px 30px;
          justify-content: center;
          align-items: center;
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &-price {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .annual-fee {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            color: $color-darkLiver;
            margin: 0;

            span {
              font-size: 20px;
              color: $color-oldSilver;
            }
          }

          .start-up-fee {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            color: $color-oldSilver;
            margin: 0;
          }
        }

        &-tools {
          padding-left: 1.2rem;
          color: $color-oldSilver;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          margin: 0;
          max-height: 150px;
          overflow-y: auto;
          li {
            padding: 4px 0;
          }

          &::-webkit-scrollbar {
            width: 8px;
            height: 4px;
            // background-color: $color-light-light-gray;
            border-radius: 10px;
          }
      
          &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 10px;
            // background-color: $color-light-light-gray;
          }
      
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            // background-color: $color-oldSilver;
          }
        }

        &-compare {
          padding-top: 20px;
          padding-bottom: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            background: unset;
            border: unset;
            color: $color-primary;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
          }
        }

        
        &-action{
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          .edit-btn{
            border-radius: 24px;
            padding: 6px 16px;
            background-color: $color-primary-dark;
            color: $color-white;
            border: unset;
          }
          .archive-btn{
            border-radius: 24px;
            padding: 6px 16px;
            background-color: $color-light-red;
            color: $color-white;
            border: unset;
          }
        }
        
        .icon-remove-cross{
          position: absolute;
          top: 0;
          right: 0;
          font-size: 24px;
        }
      }
      .hovered{
        background-color: $color-light-ghost-white;
        opacity: 0.4;
        position: relative;
      }
    }
    .no-record {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 100px;
    }
  }

  &-comparison {
    padding: 0 25px;

    &-title {
      color: $color-gray;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
  }
}


/** ADD/EDIT PACKAGE - POPUP DESIGN **/
.popupModal__container.add-package {
  .popupModal__root {
    padding: 24px;
    width: 803px;
    border-radius: 8px;
    min-height: 500px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }

    .popupModal__heading {
      .icon-close {
        top: 24px;
        right: 24px;
      }

      h2 {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-black;
        text-align: justify;
        margin: 0px 0px 15px;
        padding-bottom: 15px;
      }
    }

    .popupModal__body {
      min-height: auto;

      .addpackage-popup {
        &--flex {
          display: flex;
          flex-direction: row;
          column-gap: 25px;
          margin: 0px 0px 0px;
          

          &-col {
            display: inline-flex;
            flex-direction: column;
            width: 50%;

            label {
              display: block;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              margin: 0 0 6px;

              sup {
                color: $color-light-red;
                vertical-align: middle;
              }
            }

            .error-msg {
              margin: 4px 0 8px 0;
              padding: 0;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              color: $color-light-red;
              height: 12px;
              display: block;
            }

            .inputField {
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              background: $color-white;
              height: 42px;
              padding: 13px 12px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;

              &:disabled {
                background: $color-light-ghost-white;
              }
            }

            .react-dropdown-select {
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              height: 42px;
              margin: 0 0 0px 0;
              padding: 13px 15px 13px 12px;
              width: 100%;

              .react-dropdown-select-content {
                color: $color-darkLiver;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                justify-content: flex-start;

                .react-dropdown-select-option {
                  background: transparent;
                  color: $color-darkLiver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  white-space: nowrap;
                  margin: 0;
                  position: relative;
                  display: inline;

                  &::before {
                    position: absolute;
                    content: ",";
                    right: 0px;
                    bottom: 0;
                  }

                  &:nth-last-child(2)::before {
                    content: "";
                  }

                  .react-dropdown-select-option-remove {
                    display: none;
                  }

                  &:first-child {
                    padding-left: 0px;
                  }
                }
              }

              .react-dropdown-select-dropdown-handle svg {
                vertical-align: -4px;
                width: 21px;
                height: 21px;
              }

              .react-dropdown-select-dropdown {
                overflow: visible;
                border-radius: 6px;
                width: 100%;

                .dropdwonList-main {
                  padding: 20px 12px 12px;

                  .dropdwonLists {
                    overflow: auto;
                    max-height: 200px;
                    padding: 0px 12px 0px 12px;
                    margin: 0 8px 10px 0px;

                    &.single {
                      margin: 0;
                      padding: 0;
                    }

                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }

                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }

                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }

                    .dropdwonList-subhead,
                    .dropdwonLists-label {
                      margin: 0 0 16px 0;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-gray;
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                      column-gap: 8px;

                      label {
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-gray;
                        margin: 0;
                      }

                      &:last-child {
                        margin: 0 0 0 0;
                      }

                      .checkbox-img {
                        width: 16px;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }

            .d-flex {
              justify-content: space-between;
              gap: 4px;

              .inputField {
                width: 80%;
              }

              >div {
                width: 20%;
              }

              .react-dropdown-select {
                padding: 13px 5px 13px 10px;
                .react-dropdown-select-dropdown {
                  .dropdwonList-main {
                    padding: 0;

                    .dropdwonLists {

                      .dropdwonList-subhead,
                      .dropdwonLists-label {
                        color: $color-oldSilver;
                        border-bottom: 1px solid $color-light-light-gray;
                        margin: 0;
                        padding: 12px 14px;

                        label {
                          margin: 0;
                          color: $color-oldSilver;
                        }

                        &:last-child {
                          margin: 0 0 0 0;
                        }
                      }
                    }
                  }
                }
                .react-dropdown-select-input{
                  display: none;
                }
              }
            }
          }          
        }
        .mandatory-head {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-darkLiver;
          text-align: right;
          margin: 8px 0 0px;
          sup {
            color: $color-light-red;
            vertical-align: middle;
          }
        }

        .selected-tools-container {          
          margin: 30px 0px;
          &-heading {
            color: $color-darkLiver;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;  
            // border-bottom: 1px solid $color-light-light-gray;
          }

          &-list {
            min-height: 230px;
            max-height: 300px;
            overflow: auto;
            margin: 6px 0px;
            border-radius: 6px;
            border: 1px solid $color-light-light-gray;

            .flex-list {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 16px;
              padding: 10px;
              row-gap: 16px;

              .tool-info {
                border-radius: 28px;
                background: $color-light-gray;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
                padding: 6px 10px;
                margin: 0;
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;

                .icon-remove-cross {
                  font-size: 16px;
                  cursor: pointer;
                  color: $color-white;
                  border-radius: 50px;
                  position: relative;
                  top: -10px;
                  left: 20px;
                  padding: 4px;
                }
              }
            }
          }
        }
        .flex-btn {
          float: right;
          display: flex;
          flex-direction: row;
          column-gap: 16px;
          .cancel-btn {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 35.202px;
            color: $color-oldSilver;
            border: 1px solid $color-oldSilver;
            background: $color-white;
            height: 31px;
            width: 75px;
          }
          .submit-btn,
          .add-btn {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 35.202px;
            color: $color-white;
            background: $color-primary-dark;
            height: 31px;
            min-width: 75px;
            position: relative;
            padding: 0px 16px;
            border: unset;
          }
          .submit-btn.loading,
          .add-btn.loading {
            background: $color-primary-dark;
            padding-right: 40px;
          }
          .submit-btn.loading::after,
          .add-btn.loading:after {
            content: "";
            position: absolute;
            border-radius: 100%;
            right: 6px;
            top: 50%;
            width: 0px;
            height: 0px;
            margin-top: -2px;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-left-color: #fff;
            border-top-color: #fff;
            animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
          }
          @keyframes spin {
            to {
              transform: rotate(359deg);
            }
          }
          @keyframes grow {
            to {
              width: 14px;
              height: 14px;
              margin-top: -8px;
              right: 13px;
            }
          }
        }
      }
    }
  }
}

/** DELETE PACKAGE **/
.popupModal__container.delete-package {
  .popupModal__root {
    width: 360px;
    border-radius: 6px;
    padding: 16px;
    .popupModal__heading h2 {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
      min-width: 100%;
      margin: 0;
      color: $color-primary-dark;
      & .heading b {
        color: $color-primary;
      }
    }
    .icon-close {
      font-size: 12px;
    }
    .archive-message {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 12px 0 18px;
      color: $color-darkLiver;
    }
    .flex-disp {
      display: flex;
      flex-direction: row;
      margin: 0px 0 0;
      align-items: center;
      column-gap: 20px;
      justify-content: flex-end;
      .package-delete {
        border-radius: 24px;
        background: $color-light-red;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        border: 1px solid transparent;
        max-width: 122px;
        height: 30px;
        &:hover {
          color: $color-white;
          background: #01101bd4;
          border: 1px solid $color-primary-dark;
        }
      }
      .archive-cancel {
        border-radius: 24px;
        border: 1px solid $color-oldSilver;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        color: $color-oldSilver;
        width: 75px;
        height: 30px;
        &:hover {
          background: #dadcdd;
        }
      }
    }
  }
}