.pushNotifications-container {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 340px;
  min-height: 180px;
  border-radius: 8px;
  background-color: $color-white;
  z-index: 1500;
  margin: 0;
  padding: 0;
  box-shadow: 0px 1px 14px 2px $color-light-gray-4;
  &--header {
    width: 100%;
    height: 40px;
    border-radius: 8px 8px 0 0;
    background-color: $color-primary-dark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.01px;
    text-align: left;
    color: $color-light-ghost-white;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    .orion-logo-icon {
      width: 25px;
    }
    .closed-round-icon {
      width: 18px;
      cursor: pointer;
    }
    .time-update {
      font-size: 10px;
      font-weight: 400;
      line-height: 20.01px;
      text-align: left;
      text-transform: capitalize;
    }
  }
  .notification_container {
    display: flex;
    column-gap: 8px;
    padding: 15px 13px 13px;
    margin: 0 0 0px;
    min-height: 140px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $color-light-light-gray;
    background-color: $color-white;
    border-top: none;
    // align-items: center;
    .no-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $color-light-light-gray;
    }
    &_image {
      position: relative;
      width: 32px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      span{
        font-size: 32px;
      }
    }
    &_details {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: baseline;
      &-top--row {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &-header {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.6px;
        text-align: left;
        color: $color-primary-dark;
        margin: 0;
        padding: 0;
      }
      &_html {
        margin: 0;
        color: $color-oldSilver;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        &_msg {
          margin: 0;
          color: #1e1919;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
          max-height: 80px;
          min-height: 25px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          p {
            margin: 0;
            font-size: 12px;
            span {
              font-size: 12px;
            }
          }
        }
        a,
        span,
        p {
          color: $color-oldSilver;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          margin: 0;
          padding: 0;
        }
        span {
          display: inline;
        }
      }
      &_msg {
        margin: 0;
        color: $color-oldSilver;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      &_updatedOn {
        margin: 0;
        padding: 0px;
        color: rgba(82, 74, 62, 0.82);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

/* CSS Animations */
.notification-enter {
  opacity: 0;
  transform: translateX(100%);
  animation: fadeIn 0.5s forwards;
}

.notification-exit {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}

/* Keyframes for animations */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}
